import axios from 'axios';
import { BASE_URL, DOCUMENT_SERVICE, CAPABILITIES, VERSION_1 } from './configuration';
import { DOCUMENT_SERVICE_ENDPOINT } from './configuration/document-service';

export const DOCUMENT_SERVICE_URL = `${BASE_URL}/${DOCUMENT_SERVICE}/${CAPABILITIES}/${VERSION_1}`;

export const getDocumentDetails = (dispatch, productIds, documentTypeId) => {
  const requestBody = {
    itemIds: productIds,
    documentTypeId: documentTypeId
  };

  return axios({
    method: 'POST',
    url: DOCUMENT_SERVICE_URL + DOCUMENT_SERVICE_ENDPOINT.DOCUMENT,
    data: requestBody,
    headers: {
      'Content-Type': 'application/json'
    },
  }).then((response) => {
    return response.data;
  })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};
