// React
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

// Components
import { CompanyGrid } from './company-grid';
import {
  updatePrivatePlanConfigAttributes,
  updatePrivatePlanConfigPayload,
  updateDeletedCompanyId
} from '../../../../../actions/api/pricing-plan-action';
import { getTenantMap } from '../../../../../actions/user-service-action';

// Utils
import { scrollToBottom } from '../utils';

// CSS
import './index.scss';

const PrivatePlanConfig = (props) => {
  const {
    privatePlanConfigAttributes,
    privatePlanConfigPayload,
    tenants,
    deletedCompanyId
  } = useSelector((state) => {
    return {
      privatePlanConfigAttributes:
        state.ProductPricingPlanReducer.privatePlanConfigAttributes,
      privatePlanConfigPayload:
        state.ProductPricingPlanReducer.privatePlanConfigPayload,
      tenants: state.TenantServiceReducer?.tenants,
      deletedCompanyId: state.ProductPricingPlanReducer?.deletedCompanyId
    };
  });
  const dispatch = useDispatch();
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    scrollToBottom('#basic-plan-wrapper');
  }, [privatePlanConfigPayload]);

  useEffect(() => {
    if (!deletedCompanyId) {
      return;
    }
    const payload = _.cloneDeep(privatePlanConfigPayload);
    if (payload.companyId.length === 1) {
      payload.companyId = [];
    } else {
      const companyId = payload.companyId.filter(
        (attr) => attr.companyId !== deletedCompanyId
      );
      payload.companyId = companyId;
    }
    dispatch(updatePrivatePlanConfigPayload(payload));
    dispatch(updateDeletedCompanyId(''));
  }, [deletedCompanyId]);

  const mapTenantOptions = () => {
    const tenantOptions = tenants.map((tenant) => {
      return { label: tenant.label, value: tenant.value };
    });

    const attributes = _.cloneDeep(privatePlanConfigAttributes);
    attributes.forEach((attr) => {
      if (attr.name === 'tenantId') {
        attr.options = tenantOptions;
      }
    });

    dispatch(updatePrivatePlanConfigAttributes(attributes));
  };

  useEffect(() => {
    const tenantAttribute = privatePlanConfigAttributes.find(
      (attr) => attr.name === 'tenantId'
    );
    if (tenantAttribute?.options.length === 0) {
      mapTenantOptions();
    }
  }, [tenants]);

  const updatePlanConfigPayload = (key, value) => {
    if (!privatePlanConfigPayload.hasOwnProperty(key)) {
      return;
    }
    const payload = _.cloneDeep(privatePlanConfigPayload);
    if (key === 'tenantId') {
      payload.companyId = [];
    }
    if (Array.isArray(payload[key])) {
      payload[key].unshift(value);
    } else {
      payload[key] = value;
    }
    dispatch(updatePrivatePlanConfigPayload(payload));
    scrollToBottom('#basic-plan-wrapper');
  };

  const updateCompanyError = (attributes, message) => {
    attributes.forEach((attribute) => {
      if (attribute.attributeType === 'COMBINE') {
        attribute.attributes.forEach((attr) => {
          if (attr.name === 'companyId') {
            attr.error = message;
          }
        });
      }
    });
  };

  const getCompanyDetails = async (attributes) => {
    if (privatePlanConfigPayload.companyId?.length >= 15) {
      updateCompanyError(
        attributes,
        i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.ADDITIONAL_CONFIGURATION.MESSAGE.COMPANY_MAX_COUNT'
        )
      );
      return;
    }
    const companyId = privatePlanConfigAttributes
      .find((attr) => attr.attributeType === 'COMBINE')
      .attributes.find((attr) => attr.name === 'companyId');
    if (companyId.value === '' || companyId.error) {
      return;
    }
    // Check whether company id is already present or not
    if (
      privatePlanConfigPayload.companyId.some(
        (customer) => customer.companyId === companyId.value
      )
    ) {
      updateCompanyError(
        attributes,
        i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.ADDITIONAL_CONFIGURATION.MESSAGE.COMPANY_ALREADY_EXIST'
        )
      );
      return;
    }

    const companyDetails = await dispatch(getTenantMap(companyId.value));

    if (
      companyDetails?.length === 0 ||
      companyDetails[0]?.tenantId !== privatePlanConfigPayload.tenantId
    ) {
      updateCompanyError(
        attributes,
        i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.ADDITIONAL_CONFIGURATION.MESSAGE.COMPANY_NOT_FOUND'
        )
      );
      return;
    }

    updateCompanyError(attributes, '');
    return {
      companyId: companyDetails[0].companyId,
      companyName: companyDetails[0].companyName
    };
  };

  const updatePlanAttributes = (attributes, name, event, isResetCompanyId) => {
    return attributes.map((attribute) => {
      if (attribute.attributeType === 'COMBINE') {
        attribute.attributes.forEach((attr) => {
          if (
            isResetCompanyId &&
            (attr.name === 'tenantId' || attr.name === 'companyId')
          ) {
            attr.value = '';
            attr.error = '';
          }
          if (attr.name === name) {
            attr.value = event.value;
            attr.error = event.error;
          }
        });
      }
      return attribute.name === name
        ? { ...attribute, value: event.value, error: event.error }
        : attribute;
    });
  };

  const handleFieldChange = async (event, attribute) => {
    const attributes = _.cloneDeep(privatePlanConfigAttributes);
    let isResetCompanyId = false;
    if (!event.error) {
      switch (attribute.name) {
        case 'tenantId':
          updatePlanConfigPayload(event.name, event.value.value);
          isResetCompanyId = true;
          break;
        case 'addCompanyId':
          const customerDetails = await getCompanyDetails(attributes);
          if (customerDetails) {
            isResetCompanyId = true;
            updatePlanConfigPayload('companyId', customerDetails);
          }
      }
    }

    const updatedData = updatePlanAttributes(
      attributes,
      attribute.name,
      event,
      isResetCompanyId
    );
    dispatch(updatePrivatePlanConfigAttributes(updatedData));
  };

  return (
    <div className='plan-config'>
      <div className='plan-config__fields'>
        {privatePlanConfigAttributes?.map((attribute) => {
          return props.handleGetFields(attribute, handleFieldChange);
        })}
      </div>
      {privatePlanConfigPayload.companyId?.length > 0 && (
        <div className='plan-config__cust-grid'>
          <CompanyGrid data={privatePlanConfigPayload.companyId} />
        </div>
      )}
    </div>
  );
};

export default PrivatePlanConfig;
