// React
import axios from 'axios';

import { BASE_URL, TENANT_SERVICE, SERVICE_VERSION } from './configuration';
import { TENANT_SERVICE_ENDPOINT } from './configuration/tenant-service';
import { getTenantList } from '../utils/tenant-service-utils';

export const TENANT_SERVICE_URL = `${BASE_URL}/${TENANT_SERVICE}/${SERVICE_VERSION}`;

export const getTenantCategoryMapping = () => {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: TENANT_SERVICE_URL + TENANT_SERVICE_ENDPOINT.TENANT_CATEGORY_MAPPING,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'TENANT_CATEGORY_MAPPING',
          payload: response.data
        });
        const tenantList = getTenantList(response.data);
        dispatch({
          type: 'TENANTS',
          payload: tenantList
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};
