import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Images from '../../../assets/img';
import i18next from 'i18next';
import './index.scss';

const SingleSelectDropdown = ({
  options,
  displayName,
  handleChange,
  fieldValue,
  name,
  placeHolder
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    fieldValue || placeHolder || ''
  );
  const [searchText, setSearchText] = useState('');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    const data = {
      value: option,
      error: '',
      name: name
    };
    handleChange(data);
    setIsOpen(false);
    setSearchText('');
  };

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase()?.includes(searchText.toLowerCase())
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchText('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(fieldValue);
  }, [fieldValue]);

  return (
    <div className='single-select-dropdown' ref={dropdownRef}>
      <div className='single-select-dropdown__header' onClick={toggleDropdown}>
        <Grid container>
          <Typography>{displayName}</Typography>
          <Grid item xs={11}>
            <Typography className='single-select-dropdown__content'>
              {selectedValue}
            </Typography>
          </Grid>
          <Grid item xs={1} textAlignLast='end'>
            <img className='single-select-dropdown__arrow' src={Images.arrowDown} />
          </Grid>
        </Grid>
      </div>
      {isOpen && (
        <div className='single-select-dropdown__options'>
          <input
            type='text'
            className='single-select-dropdown__search'
            placeholder={i18next.t('FILTER_SECTION.SEARCH_TEXT') + '...'}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.id}
                className='single-select-dropdown__option'
                onClick={() => handleOptionClick(option)}
              >
                <Typography>{option.label}</Typography>
              </div>
            ))
          ) : (
            <Typography className='single-select-dropdown__option'>
              {i18next.t('ORDER_MANAGEMENT.MULTI_SELECT_REJECTON.NO_DATA_FOUND')}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleSelectDropdown;
