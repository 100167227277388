// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Libs
import i18next from 'i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//Component
import { ChartCard } from '@mobilitystore/charts-interface';

// Utils
import { calculateDateRange, getProductsDetails } from '../utils';

//Data
import { getPieChartOptions, dateOptions, productHeaderOptions, productFooterOptions, toMyProducts, defaultPlaceholderFields, richTextStyles } from '../data';
import idConstants from '../../../../data/idConstants';


const ProductWidget = React.memo((props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [categoryId, setCategoryId] = useState(idConstants.all);
    const [chartOptions, setChartOptions] = useState({});

    const [dateOption, setDateOption] = useState(dateOptions[3].value);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [total, setTotal] = useState('');

    const { productDetails, categoryList } = useSelector(
        (state) => ({
            productDetails: state?.UnifiedSellerDashboardReducer?.productDetails,
            categoryList: state?.UnifiedSellerDashboardReducer?.categoryList
        }),
        shallowEqual
    );

    useEffect(() => {
        if (categoryList?.length > 0 && startDate?.length > 0) {
            getProductsDetails(categoryId, props.selectedTenant, startDate, endDate, dispatch);
        }
    }, [categoryList, categoryId, props.selectedTenant, dateOption, startDate, endDate]);


    useEffect(() => {
        const initialDateRange = calculateDateRange(dateOption);
        if (initialDateRange) {
            setStartDate(initialDateRange.start);
            setEndDate(initialDateRange.end);
        }
    }, []);

    useEffect(() => {
        if (!productDetails) {
            return;
        }
        const pieChartOptions = getPieChartOptions(isSmallDevice, productDetails);
        const total = productDetails.reduce((acc, curr) => acc + curr.value, 0);
        setTotal(String(total));
        pieChartOptions.legend.formatter = function (name) {
            const item = productDetails.find((d) => d.name === name);
            const value = item?.value || '';

            return isSmallDevice ? `${name}: ${item?.value}` : `{nameStyle|${name}}{valueStyle|${value}}`;
        };
        if (!isSmallDevice) {
            pieChartOptions.legend.textStyle = {
                rich: richTextStyles
            };
        }
        pieChartOptions.series[0].label.formatter = `{b|Total}\n{c|${total}}`;
        pieChartOptions.series[0] = {
            ...pieChartOptions.series[0],
            data: productDetails,
        };

        setChartOptions(pieChartOptions);
    }, [productDetails, isSmallDevice]);

    const productDateHandler = (event) => {
        setDateOption(event.target.value);
        const dateRange = calculateDateRange(event.target.value);
        if (dateRange) {
            setStartDate(dateRange.start);
            setEndDate(dateRange.end);
        }
    };

    const productCategoryChangeHandler = (event) => {
        setCategoryId(event.target.value);
    };

    const productFooterLinkHandler = () => {
        window.location.href = toMyProducts;
    };

    const productHeaderDetails = productHeaderOptions(
        categoryList, categoryId, productCategoryChangeHandler, dateOptions, productDateHandler, dateOption
    );

    const productFooterDetails = productFooterOptions(productFooterLinkHandler);
    return (
        <div
            className='usp-dashboard__chart-card'
        >
            <ChartCard
                header={{
                    label: {
                        displayValue: i18next.t('DASHBOARD.CHART.PRODUCT.TOTAL')
                    },
                    fields: productHeaderDetails,
                    data: total
                }}
                chart={{
                    type: 'chart',
                    config: chartOptions,
                    data: productDetails,

                }}
                footer={{
                    label: '',
                    fields: productFooterDetails,
                }}
                placeholder={defaultPlaceholderFields}
            />
        </div>
    );
});
export default ProductWidget;