import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import {
  Breadcrumb,
  PrimaryButton,
  SecondaryButton
} from '../../../msreact-kit/packages/html-components';
import useHandleLoader from '../../../msreact-kit/hooks/useHandleLoader';
import { TENANT } from '../../../actions/configuration';
import {
  getComments,
  getOrderStatus,
  getOrderSummary,
  resetOrderState,
  getPricingPolices
} from '../../../actions/api/OrderSummaryAction';
import { confirmSellerApproval } from '../../../actions/order-fulfilment-service';
import { getContractInfo } from '../../../actions/api/ConsumptionPostingAction';
import {
  getColourNode,
  orderStatus as orderStatusUtils
} from '../../../shared/shared-function/helperFunctions';
import ProductDetails from './product-detail';
import OrderStatus from './order-status';
import OrderOverview from './order-overview';
import DynamicPopUp from './dynamic-popup';
import DynamicComment from './dynamic-comment';
import ConsumptionPosting from './consumption-posting';
import SidebarContentWrapper from '../../BaseLayout/SidebarContainer';
import Loader from '../../../components/Shared/Utils/Loader';
import Footer from '../../BaseLayout/footer';
import './index.scss';
import idConstants from '../../../data/idConstants';

const OrderSummary = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [handleLoader] = useHandleLoader();
  const { productOrderDetailId } = useParams();
  let [currentOrderStage, setCurrentOrderStage] = useState({});
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const [showRejectOrder, setShowRejectOrder] = useState(false);
  const [comment, setComment] = useState(false);
  const [shippingRemark, setShippingRemark] = useState(false);
  const [transitDetail, setTransitDetail] = useState(false);
  const [podDetails, setPodDetails] = useState(false);
  const [filteredPricingPolicies, setFilteredPricingPolicies] = useState(null);
  const {
    orderStatus,
    statusChange: statusChangeValue,
    postComment,
    orderDescription,
    billingAddress,
    isCancellable,
    errorState,
    pricingPolicies
  } = useSelector((state) => {
    return state.OrderSummaryReducer;
  });
  const { contractData } = useSelector((state) => {
    return state.ConsumptionReducer;
  });
  const { loader } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader
    };
  });
  const isTransformCategory = orderDescription?.productDetailsDto?.category?.id === idConstants.transform.categoryId;
  useEffect(() => {
    if (pricingPolicies?.length > 0) {
      const pricingPoliciesDetails = pricingPolicies?.find(
        (planDetails) =>
          planDetails.planId === orderDescription?.productOrderDto?.policyId
      );
      setFilteredPricingPolicies(pricingPoliciesDetails);
    }
  }, [pricingPolicies, orderDescription]);

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    if (errorState) {
      history.push('/dashboard/order-management');
    }
    return () => {
      dispatch(resetOrderState());
    };
  }, [errorState]);

  useEffect(() => {
    if (productOrderDetailId) {
      dispatch(getOrderSummary(productOrderDetailId));
      dispatch(getContractInfo(props.location?.state?.dcsOrderId));
    }
  }, [productOrderDetailId]);

  useEffect(() => {
    if (orderDescription?.productOrderDto?.productId) {
      dispatch(getPricingPolices(orderDescription?.productOrderDto?.productId));
    }
  }, [orderDescription?.productOrderDto?.productId]);

  useEffect(() => {
    dispatch(getOrderStatus(productOrderDetailId)).then(() => {
      dispatch(getComments(productOrderDetailId));
    });
  }, [statusChangeValue, postComment]);

  const getCTAResult = (orderStatus, getCurrentStatusIndex, tenantId) => {
    const { currentNode } = getCurrentStatusIndex;

    if (currentNode >= orderStatus.length - 1) {
      return orderStatus[currentNode];
    }
    const nextStatus = orderStatus[currentNode + 1].status;
    switch (nextStatus) {
      case orderStatusUtils.ORDER_CONFIRMED:
        orderStatus['labelTwo'] = i18next.t(
          'ORDER_MANAGEMENT.ORDER_STATUS.CONFIRM_ORDER'
        );
        orderStatus['labelKeyTwo'] = 2;
        break;
      case orderStatusUtils.READY_TO_SHIP:
        orderStatus['labelTwo'] = i18next.t(
          'ORDER_MANAGEMENT.ORDER_STATUS.READY_TO_SHIP'
        );
        orderStatus['labelKeyTwo'] = 3;
        break;
      case orderStatusUtils.INTRANSIT:
        orderStatus['labelTwo'] = i18next.t(
          'ORDER_MANAGEMENT.ORDER_STATUS.ADD_TRANSIT_DETAILS'
        );
        orderStatus['labelKeyTwo'] = 4;
        break;
      case orderStatusUtils.DELIVERED:
        orderStatus['labelTwo'] =
          tenantId == TENANT.PSIN
            ? i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.SUBMIT_CLIENT_SECRET_ID')
            : i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.SUBMIT_POD');
        orderStatus['labelKeyTwo'] = 5;
        break;
      case orderStatusUtils.COMPLETED:
        orderStatus['labelTwo'] = i18next.t('DYNAMIC_FORM.SHARED.APPROVE');
        orderStatus['labelKeyTwo'] = 6;
        break;
    }
    orderStatus.status = orderStatus[currentNode].status;
    orderStatus.nextStatus = nextStatus;

    return orderStatus;
  };

  useEffect(() => {
    if (
      orderStatus?.length > 0 &&
      orderDescription?.orderSubscriptionMap?.tenantId
    ) {
      const getCurrentStatusIndex = getColourNode(orderStatus);
      const ctaResult = getCTAResult(
        orderStatus,
        getCurrentStatusIndex,
        orderDescription?.orderSubscriptionMap?.tenantId
      );
      setCurrentOrderStage(ctaResult);
    }
  }, [orderStatus, orderDescription?.orderSubscriptionMap?.tenantId]);

  const handleConfirm = () => {
    setShowConfirmOrder(() => true);
  };

  const handleReject = () => {
    setShowRejectOrder(() => true);
  };

  const handleOpenComment = () => {
    setComment(() => true);
  };

  const handleShippingRemark = () => {
    setShippingRemark(() => true);
  };

  const handleTransitDetails = () => {
    setTransitDetail(() => true);
  };

  const handlePODDetails = () => {
    setPodDetails(() => true);
  };

  const handleSellerApproval = () => {
    dispatch(confirmSellerApproval(orderDescription?.productOrderDto?.productOrderDetailId, orderDescription));
  };

  const handleOrderStatusButton = () => {
    if ( !isTransformCategory && currentOrderStage?.nextStatus === i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.COMPLETED').toUpperCase()) {
      return ;
    }
    else {
      return (
        <PrimaryButton
          label={currentOrderStage?.labelTwo}
          onClick={actionCalCulator(
            currentOrderStage?.labelKeyTwo
          )}
        />
      );
    };
  };

  const handleClose = () => {
    if (showConfirmOrder) setShowConfirmOrder(() => false);
    if (showRejectOrder) setShowRejectOrder(() => false);
    if (comment) setComment(() => false);
    if (shippingRemark) setShippingRemark(() => false);
    if (transitDetail) setTransitDetail(() => false);
    if (podDetails) setPodDetails(() => false);
  };

  const actionCalCulator = (label) => {
    switch (label) {
      case 1:
        return handleReject;
      case 2:
        return handleConfirm;
      case 3:
        return handleShippingRemark;
      case 4:
        return handleTransitDetails;
      case 5:
        return handlePODDetails;
      case 6:
        return handleSellerApproval;
    }
  };

  const getBreadCrumb = () => {
    const isOrderHistory = history?.location?.state?.isHistory;
    const breadcrumbs = [
      {
        title: isOrderHistory
          ? i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.ORDER_HISTORY')
          : i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.MY_ORDERS'),
        to: isOrderHistory
          ? '/dashboard/order-history'
          : '/dashboard/order-management'
      },
      {
        title: i18next.t('ORDER_MANAGEMENT.ORDER_SUMMARY.HEADER.ORDER_SUMMARY'),
        to: '/dashboard/order-management/order-details'
      }
    ];
    return breadcrumbs;
  };

  return (
    <SidebarContentWrapper className='order-summary-sidebar order-summary-details'>
      {loader && <Loader />}
      <div className='order-summary-wrapper'>
        <div
          className={
            showConfirmOrder ||
            showRejectOrder ||
            comment ||
            shippingRemark ||
            transitDetail ||
            podDetails
              ? 'popup-open'
              : 'popup-close'
          }
        >
          <DynamicPopUp
            productOrderId={orderDescription?.productOrderDto?.productOrderId}
            commentId={orderDescription?.productOrderDto?.productOrderId}
            productOrderDetailId={productOrderDetailId}
            transitDetail={transitDetail}
            shippingRemark={shippingRemark}
            comment={comment}
            onClose={handleClose}
            showConfirmOrder={showConfirmOrder}
            showRejectOrder={showRejectOrder}
            showPODDetails={podDetails}
            isCancellable={isCancellable}
            tenantId={orderDescription?.tenantId}
          />
        </div>
        <Breadcrumb items={getBreadCrumb()} className='bread-crumb__arrow' />
        <h5 className='order-summary-page-title'>
          {i18next.t('ORDER_MANAGEMENT.ORDER_SUMMARY.HEADER.ORDER_SUMMARY')}
        </h5>
        {orderDescription &&
          billingAddress &&
          Object.keys(orderDescription)?.length !== 0 &&
          Object.keys(billingAddress)?.length > 0 && (
            <>
              <div className='order-summary-container'>
                <div className='order-summary'>
                  <div className='order-summary__body'>
                    <OrderOverview currentOrderStage={currentOrderStage} />
                  </div>
                </div>
              </div>
              <div className='order-summary-container'>
                <div className='order-summary'>
                  <div className='order-summary__body'>
                    <div className='order-summary__body__flex-divider'>
                      <ProductDetails />
                      <OrderStatus />
                    </div>
                  </div>
                  {orderStatus?.length > 0 ? (
                    <div className='order-summary__footer'>
                      <div className='order-summary__navigation-buttons'>
                        <div className='order-summary__navigation-buttons__right-wrapper'>
                          {currentOrderStage?.nextStatus !==
                            orderStatusUtils.ORDER_CREATED &&
                            currentOrderStage?.nextStatus !==
                              orderStatusUtils.ORDER_CONFIRMED && (
                              <SecondaryButton
                                label={i18next.t(
                                  'ORDER_MANAGEMENT.DYNAMIC_POPUP.ADD_COMMENT'
                                )}
                                onClick={handleOpenComment}
                                className={
                                  currentOrderStage?.orderStatus === 'Reject' &&
                                  'label-reject'
                                }
                              />
                            )}
                          {isCancellable === 'true' &&
                            currentOrderStage?.nextStatus !==
                              orderStatusUtils.DELIVERED && (
                              <SecondaryButton
                                label={i18next.t('DYNAMIC_FORM.LABEL.REJECT')}
                                onClick={handleReject}
                                className={'label-reject'}
                              />
                            )}
                            {currentOrderStage?.labelTwo && (
                              handleOrderStatusButton()
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {orderDescription?.orderSubscriptionMap?.productCategoryId ===
                idConstants.solutions.categoryId &&
                currentOrderStage.status === orderStatusUtils.BUYER_APPROVED &&
                filteredPricingPolicies?.consumption !==
                  idConstants.pricing.notApplicable && (
                  <div className='order-summary-container'>
                    <div className='order-summary'>
                      <div className='order-summary__body'>
                        <ConsumptionPosting
                          contractData={contractData}
                          orderDescription={orderDescription}
                          pricingPolicies={filteredPricingPolicies}
                        />
                      </div>
                    </div>
                  </div>
                )}
              <DynamicComment />
            </>
          )}
        <Footer customClassName='d-lg-none' />
      </div>
    </SidebarContentWrapper>
  );
};

export default OrderSummary;
