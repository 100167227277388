import axios from 'axios';
import qs from 'qs';
import * as Constants from './configuration';
import base64url from 'base64url';
import { isExpired } from 'react-jwt';
import idConstants from '../data/idConstants';
import { GAEventAction } from '../data/ga-constants';
import { getUserDetails } from './api/ApiStatisticsAction';
import { trackEvent } from '../utils/analytics';
import { SELLER_TENANT_ID } from '../actions/configuration';
import { getRegionCode } from '../utils/get-region-details';
import store from '../store';

const crypto = require('crypto');
let fetchDCSTokensPromise = null;

export const getAccessTokenData = (authCode) => {
  return async (dispatch) =>
    await fetch(`${Constants.LOGIN_ACTION.ACCESS_TOKEN_DATA}`, {
      credentials: 'same-origin',
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + authCode
      }
    })
      .then((response) => response?.json())
      .then((data) => {
        localStorage.setItem('userName', data.name);
        localStorage.setItem('userId', data.userId);
        if(authCode)
          dispatch(getUserDetails());
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error
        });
        dispatch(logout());
      });
};

export const regenerateAAATokens = () => {
  const requestObj = {
    code: localStorage.getItem(idConstants.tokens.aaaCode),
    grant_type: 'refresh_token',
    client_id: Constants.AAA_CONFIG.CLIENT_ID,
    code_verfier: localStorage.aaaVerifier,
    refresh_token: localStorage.getItem(idConstants.tokens.aaaRefreshToken),
    redirect_uri: Constants.CALLBACKS.AAA_AUTH_CALLBACK,
    scope: 'openid'
  };

  return async (dispatch) =>
    await fetch(Constants.LOGIN_ACTION.GET_AAA_ACCESS_TOKEN, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: qs.stringify(requestObj)
    })
      .then(async (response) => await response?.json())
      .then(async (data) => {
        const { access_token, refresh_token, id_token } = data;

        if (!access_token || !refresh_token || !id_token) {
          throw new Error('Error in generating tokens');
        }

        // store the token in the localStorage
        localStorage.setItem(idConstants.tokens.aaaJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.aaaRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.aaaIdToken, id_token);
        await dispatch({
          type: 'REFRESH_TOKEN',
          payload: refresh_token
        });

        await dispatch(getAccessTokenData(id_token));
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: 'ERROR_LOGIN',
            error: error.response.status
          });
        }
        dispatch(logout());
      });
};

export const generateCodeVerifier = (verifierType) => {
  let verifier = '';
  const length = 128;
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const bytes = crypto.randomBytes(length);
  for (let index = 0; index < length; index++) {
    verifier += characters.charAt(bytes[index] % charactersLength);
  }
  localStorage.setItem(verifierType, verifier);
  return verifier;
};

export const handleAAASignupLogin = (salesRep) => {
  const params = new URLSearchParams();
  const hash = crypto
    .createHash('sha256')
    .update(generateCodeVerifier(idConstants.tokens.aaaVerifier))
    .digest();
  const code_challenge = base64url.encode(hash);

  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  params.append('redirect_uri', Constants.CALLBACKS.AAA_AUTH_CALLBACK);
  params.append('response_type', 'code');
  params.append('scope', 'openid email profile offline_access');
  params.append('code_challenge', code_challenge);
  params.append('code_challenge_method', 'S256');
  params.append('styleId', process.env.REACT_APP_CIAM_STYLE_ID);
  params.append('style_id', process.env.REACT_APP_SKID_STYLE_ID);
  if (salesRep) {
    params.append('kc_idp_hint', salesRep);
  }
  process.env.REACT_APP_SKID_STYLE_IDP_HINT &&
    params.append('kc_idp_hint', process.env.REACT_APP_SKID_STYLE_IDP_HINT);
  let authCodeUrl = `${Constants.LOGIN_ACTION.GET_AAA_AUTH_CODE}?${params}`;

  window.location.href = authCodeUrl;
};

export const handleDCSLoginSignup = () => {
  const params = new URLSearchParams();
  const hash = crypto
    .createHash('sha256')
    .update(generateCodeVerifier(idConstants.tokens.dcsVerifier))
    .digest();
  const code_challenge = base64url.encode(hash);
  params.append('client_id', Constants.DCS_CONFIG.CLIENT_ID);
  params.append('redirect_uri', Constants.CALLBACKS.DCS_AUTH_CALLBACK);
  params.append('response_type', 'code');
  params.append('scope', 'openid profile email');
  params.append('kc_idp_hint', Constants.DCS_CONSTANTS.DCS_IDP);
  params.append('code_challenge', code_challenge);
  params.append('code_challenge_method', 'S256');
  let authCodeUrl = `${Constants.LOGIN_ACTION.GET_DCS_AUTH_CODE}?${params}`;

  window.location.href = authCodeUrl;
};

const encodeFormBody = (requestObj) => {
  let formBody = [];
  for (let property in requestObj) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(requestObj[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');
  return formBody;
};

export const generateAAAaccessToken = (authCode) => {
  const requestObj = {
    code: authCode,
    grant_type: 'authorization_code',
    client_id: Constants.AAA_CONFIG.CLIENT_ID,
    redirect_uri: Constants.CALLBACKS.AAA_AUTH_CALLBACK,
    code_verifier: localStorage.aaaVerifier,
    scope: 'openid'
  };
  let formBody = encodeFormBody(requestObj);
  return async (dispatch) =>
    await fetch(`${Constants.LOGIN_ACTION.GET_AAA_ACCESS_TOKEN}`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    })
      .then((response) => response?.json())
      .then(async (data) => {
        const { access_token, refresh_token, id_token } = data;

        if (!access_token || !refresh_token || !id_token) {
          throw new Error('Error in generating tokens');
        }

        // store the token in the localStorage
        localStorage.setItem(idConstants.tokens.aaaJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.aaaRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.aaaIdToken, id_token);

        await dispatch({
          type: 'ACCESS_TOKEN',
          payload: id_token
        });

        await dispatch({
          type: 'REFRESH_TOKEN',
          payload: refresh_token
        });
        // decode token on React
        await dispatch(getAccessTokenData(id_token));
        //GA-100
        trackEvent('authentication', GAEventAction.signinSuccess);
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: 'ERROR_LOGIN',
            error: error.response.status
          });
        }
        dispatch(logout());
      });
};

export const generateDCSAccessToken = (authCode) => {
  const requestObj = {
    code: authCode,
    grant_type: 'authorization_code',
    client_id: Constants.DCS_CONFIG.CLIENT_ID,
    redirect_uri: Constants.CALLBACKS.DCS_AUTH_CALLBACK,
    code_verifier: localStorage.getItem(idConstants.tokens.dcsVerifier)
  };

  let formBody = encodeFormBody(requestObj);
  return async (dispatch) =>
    await fetch(Constants.LOGIN_ACTION.GET_DCS_ACCESS_TOKEN, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    })
      .then((response) => response?.json())
      .then(async (data) => {
        const { access_token, refresh_token, id_token } = data;
        if (access_token) {
          localStorage.setItem(idConstants.tokens.dcsJwtToken, access_token);
          localStorage.setItem(idConstants.tokens.dcsRefreshToken, refresh_token);
          localStorage.setItem(idConstants.tokens.dcsIdToken, id_token);
        } else {
          await dispatch(clearLoginStorage());
        }

        localStorage.setItem(idConstants.tokens.dcsJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.dcsRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.dcsIdToken, id_token);
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error
        });
        dispatch(logout());
      });
};

export const clearLoginStorage = () => (dispatch) => {
  localStorage.removeItem('userName');
  localStorage.removeItem(idConstants.tokens.aaaIdToken);
  localStorage.removeItem(idConstants.tokens.aaaCode);
  localStorage.removeItem(idConstants.tokens.aaaVerifier);
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    localStorage.removeItem(idConstants.tokens.dcsVerifier);
    localStorage.removeItem(idConstants.tokens.dcsJwtToken);
    localStorage.removeItem(idConstants.tokens.dcsRefreshToken);
    localStorage.removeItem(idConstants.tokens.dcsIdToken);
    localStorage.removeItem(idConstants.tokens.dcsCode);
  }
  localStorage.removeItem(idConstants.tokens.aaaJwtToken);
  localStorage.removeItem(idConstants.tokens.aaaRefreshToken);
  localStorage.removeItem(idConstants.salesRepSelectedCompany.selectedCompany);
  localStorage.removeItem(idConstants.selectedCompanyObject.selectedCompany);
  localStorage.removeItem(idConstants.userLoginRole.role);

  dispatch({
    type: 'SET_CURRENT_USER',
    payload: false
  });
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('prevUserName');
  localStorage.removeItem('prevPathName');
  localStorage.removeItem('userName');
  await dispatch(clearLoginStorage());
  dispatch({
    type: 'UPDATE_COMPANY_ERROR',
    payload: {}
  });
  dispatch({
    type: 'UPDATE_COMPANY_DETAILS',
    payload: {}
  });
  federatedAuthCode();
};

export const sessionExpiryLogOut = (pathname) => async (dispatch) => {
  let prevUserName = localStorage.userName;
  await dispatch(clearLoginStorage());

  dispatch({
    type: 'UPDATE_COMPANY_ERROR',
    payload: {}
  });
  dispatch({
    type: 'UPDATE_COMPANY_DETAILS',
    payload: {}
  });
  localStorage.setItem('prevUserName', prevUserName);
  localStorage.setItem('prevPathName', pathname);
  federatedAuthCode();
};

export const federatedAuthCode = () => {
  const params = new URLSearchParams();
  const dcsParams = new URLSearchParams();
  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    dcsParams.append('client_id', Constants.DCS_CONFIG.CLIENT_ID);
    dcsParams.append('redirect_uri', Constants.CALLBACKS.FEDERATED_CALLBACK);
    let dcsLogoutURL = `${Constants.LOGIN_ACTION.DCS_LOGOUT_AUTH_CODE}?federated&${dcsParams}`;
    params.append('redirect_uri', dcsLogoutURL);
  } else {
    params.append('redirect_uri', Constants.CALLBACKS.FEDERATED_CALLBACK);
  }
  let logoutURL = `${Constants.LOGIN_ACTION.LOGOUT_AUTH_CODE}?federated&${params}`;
  window.location.href = logoutURL;
};

export const invalidateRefreshToken = () => (dispatch) => {
  fetch(Constants.LOGOUT_URL, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    body: qs.stringify({
      client_id: Constants.AAA_CONFIG.CLIENT_ID,
      refresh_token: localStorage.getItem(idConstants.tokens.aaaRefreshToken)
    })
  })
    .then((resposne) => {
      dispatch({
        type: 'TOKEN_INVALIDATED',
        payload: true
      });
    })
    .catch(() => {
      dispatch({
        type: 'TOKEN_INVALIDATED',
        payload: true
      });
    });
};

export const regenerateDCSTokens = (companyId) => (dispatch) => {
  const requestObj = {
    code: localStorage.getItem(idConstants.tokens.dcsCode),
    grant_type: 'refresh_token',
    client_id: Constants.DCS_CONFIG.CLIENT_ID,
    code_verfier: localStorage.aaaVerifier,
    refresh_token: localStorage.getItem(idConstants.tokens.dcsRefreshToken),
    redirect_uri: Constants.CALLBACKS.AAA_AUTH_CALLBACK
  };
  if (
    (!fetchDCSTokensPromise &&
      isExpired(localStorage.getItem(idConstants.tokens.dcsRefreshToken))) ||
    companyId
  ) {
    fetchDCSTokensPromise = fetch(Constants.LOGIN_ACTION.GET_DCS_ACCESS_TOKEN, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: qs.stringify(requestObj)
    })
      .then((response) => response?.json())
      .then((data) => {
        const { access_token, refresh_token, id_token } = data;

        if (!access_token || !refresh_token || !id_token) {
          throw new Error('Error in generating tokens');
        }
        // store the token in the localStorage
        fetchDCSTokensPromise = null;
        localStorage.setItem(idConstants.tokens.dcsJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.dcsRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.dcsIdToken, id_token);
        // set our token in header
      })
      .catch((error) => {
        fetchDCSTokensPromise = null;
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error
        });
      });
    return fetchDCSTokensPromise;
  } else {
    return fetchDCSTokensPromise;
  }
};
export const checkUserAgreementAcceptance = () => async (dispatch) => {
  return await axios
    .get(Constants.REGISTER_ACTION.CHECK_TENANT_USER_AGREEMENT, {
      params: {
        tenantId: SELLER_TENANT_ID,
        regionCode: getRegionCode()
      }
    })
    .then(async (tenantResponse) => {
      return await axios
        .get(Constants.REGISTER_ACTION.CHECK_USER_AGREEMENT, {
          params: {
            userId: localStorage.getItem('userId')
          }
        })
        .then((userResponse) => {
          const tenantConsentResponse = tenantResponse.data;
          const userConsentResponse = userResponse.data;
          tenantConsentResponse?.data
            ?.filter((consentData) => consentData.userConsentNeeded)
            ?.forEach((consentItem) => {
              if (
                userConsentResponse?.data?.find(
                  (userConsentItem) =>
                    userConsentItem.docMetaInfoId === consentItem.docMetaInfoId
                )
              ) {
                consentItem.docMetaInfoId = userConsentResponse?.data?.filter(
                  (userConsentItem) =>
                    userConsentItem.documentTypeId === consentItem.documentTypeId
                )[0]?.docMetaInfoId;
                consentItem.accepted = userConsentResponse?.data?.filter(
                  (userConsentItem) =>
                    userConsentItem.documentTypeId === consentItem.documentTypeId
                )[0]?.accepted;
              } else consentItem.accepted = false;
            });
          dispatch({
            type: 'CHECK_USER_AGREEMENT',
            payload: tenantConsentResponse.data
          });
          return tenantConsentResponse.data;
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};

export const checkIfReseller = (companyId) => async (dispatch) => {
  await axios
    .get(Constants.REGISTER_ACTION.CHECK_RESELLER, {
      params: {
        companyId: companyId
      }
    })
    .then((response) => {
      dispatch({
        type: 'CHECK_RESELLER',
        payload: response.data
      });
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};

export const acceptUserAgreementTerms = (agreementMasterData) => (dispatch) => {
  const reqPayload = {
    data: {
      docMetaInfo: agreementMasterData,
      retrieveCompanyDetails: true
    },
    metaInfo: {
      typeOfUser: idConstants.typeOfUsers.b2b.toUpperCase(),
      b2bIdentifier:
        store.getState().loginReducer.userDetails?.companyDetails?.companyId,
      userId: localStorage.getItem('userId'),
      role: Constants.AAA_CONFIG.SELLER_ROLE
    }
  };
  axios
    .post(Constants.REGISTER_ACTION.ACCEPT_USER_COMPANY_TERMS, reqPayload)
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};

export const deregisterUser = () => async (dispatch) => {
  localStorage.removeItem('prevUserName');
  localStorage.removeItem('prevPathName');
  localStorage.removeItem('userName');

  await dispatch(clearLoginStorage());

  dispatch({
    type: 'UPDATE_COMPANY_ERROR',
    payload: {}
  });
  dispatch({
    type: 'UPDATE_COMPANY_DETAILS',
    payload: {}
  });
  const params = new URLSearchParams();
  const dcsParams = new URLSearchParams();
  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    dcsParams.append('client_id', Constants.DCS_CONFIG.CLIENT_ID);
    dcsParams.append('redirect_uri', Constants.CALLBACKS.DEREGISTRATION_CALLBACK);
    let dcsLogoutURL = `${Constants.LOGIN_ACTION.DCS_LOGOUT_AUTH_CODE}?federated&${dcsParams}`;
    params.append('redirect_uri', dcsLogoutURL);
  } else {
    params.append('redirect_uri', Constants.CALLBACKS.DEREGISTRATION_CALLBACK);
  }
  let logoutURL = `${Constants.LOGIN_ACTION.LOGOUT_AUTH_CODE}?federated&${params}`;
  window.location.href = logoutURL;
};

export function setPortalView(value) {
  return {
    type: 'SET_PORTAL_VIEW',
    payload: value
  };
}

export default handleAAASignupLogin;
