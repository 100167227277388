const idConstants = {
  tokens: {
    aaaJwtToken: 'aaaJwtToken',
    aaaRefreshToken: 'aaaRefreshToken',
    aaaIdToken: 'aaaIdToken',
    dcsJwtToken: 'dcsJwtToken',
    dcsIdToken: 'dcsIdToken',
    dcsRefreshToken: 'dcsRefreshToken',
    aaaVerifier: 'aaaVerifier',
    dcsVerifier: 'dcsVerifier',
    aaaCode: 'aaaCode',
    dcsCode: 'dcsCode'
  },
  userLoginRole: {
    role: 'userLoginRole'
  },
  salesRepSelectedCompany: {
    selectedCompany: 'salesRepSelectedCompany'
  },
  selectedCompanyObject: {
    selectedCompany: 'selectedCompany'
  },
  userId: 'userId',
  sellerName: 'sellerName',
  sellerCompanyId: 'sellerCompanyId',
  coverImage: 'coverImage',
  all: 'All',
  allTenants: 'All Tenants',
  euCountryCodes: [
    'DE',
    'NL',
    'DK',
    'LU',
    'SE',
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'EE',
    'FI',
    'FR',
    'GR',
    'HU',
    'IE',
    'LV',
    'LT',
    'MT',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES'
  ],
  countryCode: {
    countryId: 'f497f48f-0355-4bf6-9554-692b57613f9c',
    countryCode: 'IN',
    country: 'India'
  },
  countryDetailsUS: {
    countryId: '8252fe63-e8f6-45c0-aaa7-e1e209fb576f',
    countryCode: 'US'
  },
  stateCondition: {
    publishedConsumed: 'PUBLISHED_CONSUMED'
  },
  drafted: {
    description: 'DRAFTED',
    statusId: '742cffe3-4754-4479-9369-f72368674bb9'
  },
  submitted: {
    description: 'SUBMITTED',
    statusId: '6d78da39-181d-4ead-b5ff-b11559cd171a'
  },
  published: {
    description: 'PUBLISHED',
    statusId: 'ff841ba0-2141-4c57-b26a-d5af00e03782'
  },
  approved: {
    description: 'APPROVED',
    statusId: '6a8f9734-cace-4469-a8d5-342d2b8c9ba9'
  },
  rejected: {
    description: 'REJECTED',
    statusId: 'd5f25fa8-7efa-41f5-9e05-17dd1a9428ff'
  },
  tenantRejected: {
    description: 'TENANT_REJECTED',
    statusId: '119b4102-ec81-491c-a1cc-fafea25e26e1'
  },
  api: {
    categoryId: 'fd9f9032-3ece-4d33-a5df-3ecc24370ff8',
    productCategoryName: 'API'
  },
  data: {
    categoryId: 'a667657b-a184-4363-af1a-9d415e419f44',
    productCategoryName: 'Data'
  },
  hardware: {
    categoryId: 'e298c066-96db-477a-883e-4d42f15d91f1',
    parentCategoryId: 'cdbd6194-8a24-4893-8dc0-d2e1ad534657',
    formId: '2d5bbed6-92f6-40c5-b969-fb9abd1bacf0',
    productCategoryName: 'Hardware'
  },
  solutions: {
    categoryId: '6d0ecf14-258f-4096-98c2-791e9e622f82',
    productCategoryName: 'Solutions'
  },
  sdk: {
    categoryId: '2f9f15dd-9ceb-4db4-b5bc-83842e222f49',
    productCategoryName: 'SDK'
  },
  professionalServices: {
    categoryId: '4f43cd54-b8b7-412b-a69c-10c8e8c2c6f7',
    productCategoryName: 'Professional Services'
  },
  workPackage: {
    categoryId: 'd15b1004-d768-4654-a8d4-e30f59fa7fca',
    productCategoryName: 'Work Package'
  },
  transform: {
    categoryId: '6f37503c-f822-4a59-ab75-8c7413f8d902',
    productCategoryName: 'Transform'
  },
  automotives: {
    categoryId: 'aba483a4-ae6f-4551-9a10-4d3fc7588ada',
    productCategoryName: 'Automotives'
  },
  automotiveSpares: {
    categoryId: 'cc58b797-56f6-4d38-87cf-1915e7916e39',
    productCategoryName: 'Automotive Spares'
  },
  talent: {
    categoryId: '5670ee28-147f-4472-acb9-957306feb46a',
    productCategoryName: 'Talent'
  },
  best: {
    saas: {
      categoryId: '8eb6ee76-3f19-4c62-854f-dd59f52ab186'
    },
    paas: {
      categoryId: '8f6797a6-4e1f-4c3d-80c9-3c143c0abf05'
    },
    iaas: {
      categoryId: '9679cb14-0f30-4896-ac19-780b8606a5a9'
    },
    preDefinedWorkflow: {
      categoryId: 'f7b159af-091c-491c-865a-9c74fc36f377'
    },
    customWorkflow: {
      categoryId: 'cec967ec-ba68-4ab7-83e1-9bf971b8ff72'
    },
    methods: {
      categoryId: 'd5c805c0-515e-4409-82e4-f3206394d5ae'
    }
  },
  oAuth2Authentication: '0a32c1c8-5da0-43fd-b7bb-bc3b6e5ddf0c',
  authCodeGrantTypeId: 'a2b967db-7b15-4979-a629-614597908645',
  noAuthTypeId: '150dbafa-ae48-48c6-a97a-ae675635cdd0',
  clientCredentialsId: '0ed6b316-f76f-4445-aa98-621abe868b5c',
  pricing: {
    payPerCallBillingCycleType: 'PAY_PER_CALL',
    yearBillingCycleType: 'YEARLY',
    monthBillingCycleType: 'MONTHLY',
    freePlanBillingCycleType: 'FREE_PLAN',
    oneTimePlanBillingCycleType: 'ONE_TIME_PLAN',
    bandWidthRateLimitType: 'DATA_BANDWIDTH',
    monthTimeUnitCode: 'MONTHLY',
    hourBurstControl: 'REQUEST_PER_HOUR',
    minuteBurstControl: 'REQUEST_PER_MIN',
    secondBurstControl: 'REQUEST_PER_SEC',
    definedByUsage: 'DEFINED_BY_USAGE',
    definedBySeller: 'DEFINED_BY_SELLER',
    notApplicable: 'NOT_APPLICABLE',
    reqCountLimitType: 'REQUEST_COUNT',
    basicPlanId: 'f531c824-ba10-474d-8793-9e33284646b7',
    goldPlanId: '209974e1-dcb5-42bb-b1bc-ec3ae8436eed',
    premiumPlanId: '5fa46a90-e6df-44f3-ab5e-cb994aca20a2',
    kiloByte: 'KB',
    rupeesCurrencyCode: 'INR',
    dailyRateTimeLimit: 'DAILY',
    perReqHitSlabType: 'PER_REQUEST_HIT',
    fullSlabType: 'FULL_SLAB',
    consumptionNoMeasurementType: 'NO_MEASUREMENT'
  },
  endpointParameters: {
    multipartFormDataContentTypeId: '19d2cd12-889d-4fdb-85e4-425ba76171a2',
    urlEncodedContentTypeId: '6925014c-c8aa-4f39-9c1c-1c2772b5b291',
    notApplicableContentTypeId: '43cdfac6-4d73-42fb-ae61-79992f27325a'
  },
  grantType: {
    deviceCode: 'a9073e7b-0a47-45cc-9965-bc4187761cb7',
    authorizationCode: 'a2b967db-7b15-4979-a629-614597908645'
  },
  login: {
    clientSecret: 'aeaa43b3-fc30-42c0-b3a1-92cdd0811d6d'
  },
  register: {
    approveStatus: '2e1325cf-240e-4060-8833-b1fde933dd95'
  },
  documentType: {
    techDocs: '6e952be4-12b0-4753-a0da-7591c618f51c',
    thumbnailImg: '87389ac9-3c7d-4b99-965b-aa2a224bea7f',
    coverImg: '1e726a62-7df2-40b8-b85f-b27b700a0b0a',
    videoFile: 'd5e6c74b-8246-4cbb-9401-aeae8c98760b',
    mutlimediaImageFile: '738a0b13-2efd-428a-a3a7-58fbfff1f4c2',
    highlightsDocument: 'ef69bdde-b513-4831-a74c-686a1c0ef605',
    caseStudyImageFile: '9412b952-0bed-41ed-9fc1-df19b7c674cc',
    videoLinks: 'd0152830-dfda-4110-a9fc-264e94b54b20',
    podDocument: '94ef78c5-66d1-4d53-8d96-4080fd509666',
    termsAndConditionsDocument: 'tcbb8b72-0fd8-4c26-bd8d-10f4755b0179',
    inTransitDocument: '3c5221c8-2b71-4cb1-80df-ec9d6f5b814d',
    dpnDocument: '26600e36-a9aa-4ab2-86b2-7295cc25eb52',
    refundPolicyDocument: '1139adc0-7670-4e4b-89e5-3c170c9fb8cc',
    returnAndReplacementPolicyDocument: '21ce40d2-c11e-4561-af70-f7f03d9f6901',
    userManualDocument: '614ff7e1-f0ca-4a28-af13-9622410067f4',
    certificateDocument: 'f4894b84-bb24-42ba-a5f6-a49e9f68a9fa',
    statementOfWork: '3f0c51d6-5053-469c-b43c-746adee881b2'
  },
  throttlePolicy: {
    ipThrottleTypeId: '90346909-e27f-4fe9-85e6-17d3edaeb722',
    headerThrottleTypeId: 'f9d9cbc4-41d7-491c-b75f-f07e4e036122',
    queryThrottleTypeId: 'd20e8f2c-5c77-4cdf-9d27-6652fce9d1ee'
  },
  alertType: {
    abnormalBackendTime: '92fb2f36-8182-45d0-93ed-4dd19fc58c2f',
    abnormalResponseTime: '1fba51f6-386a-41f6-83d4-e7963ceb8e18'
  },
  sidebarConstants: {
    dashboard: { title: 'Dashboard', key: '8u3i34kj' },
    myProducts: { title: 'My Products', key: 'we3e3r43' },
    faq: { title: 'FAQ', key: 'hfg34j7d' },
    apiStatistics: { title: 'Statistics', key: '5t44r4rt' },
    throttlePolicy: { title: 'Advanced Throttling Policies', key: 't4r3e33e3' },
    apiAlerts: { title: 'Manage Alerts', key: '5654r3er3' },
    apiProduct: { title: 'API', key: 'e3e45t54e' },
    solutionProduct: { title: 'Solution', key: 'e3e89t54e' },
    dataProduct: { title: 'Data', key: 'u65r44r3' },
    addThrottlingPolicy: { title: 'Throttling Policy', key: 'e873ye82' },
    cloudInfra: { title: 'Cloud Infrastructure', key: 'yt834f9s' },
    hardwareProduct: { title: 'Hardware', key: 'e3e45t13' },
    orderMangement: { title: 'Order Management', key: 'e3e45t14' },
    proposalRequest: { title: 'Proposal Request', key: 'e3fs5t14' },
    quotationsRequest: { title: 'Request for Quotations', key: '56fj5t14' },
    proposals: { title: 'Proposals', key: 'p3e45tl4' },
    myOrders: { title: 'My Orders', key: 'e3e45t15' },
    orderHistory: { title: 'Order History', key: 'e3e45t16' },
    dynamicProducts: { title: 'Dynamic Products', key: 'e3e45t29' },
    automotiveSpares: { title: 'AutoMotive Spares', key: 'e3e45t28' }
  },
  docId: {
    pan: '961603dd-1f1e-4461-9f8e-7cf73ca4dd6a',
    gst: '62486d06-6c75-424b-85e0-0bd6ab58b56c',
    vatId: '62486d06-6c75-424b-85e0-0bd6ab58b56c',
    w9Id: '62486d06-6c75-424b-85e0-0bd6ab58b56c',
    otherDoc: '42e1a3dd-8ceb-4aa7-a40c-d35a1184eb85',
    cheque: 'deec58e8-2f3f-46d1-b37d-9af2f0192034'
  },
  agreementTypes: {
    termsConditions: '36a1b008-6b44-4905-9370-007939a96292',
    privacyPolicies: 'f439e945-c36e-4a91-979d-c2f26ba39f17',
    eula: '11f3a013-9ca2-4020-a45d-40c619bbe49e'
  },
  documentTypeIds: {
    termsConditions: '36a1b008-6b44-4905-9370-007939a96292',
    privacyPolicy: 'f439e945-c36e-4a91-979d-c2f26ba39f17',
    corporateInfo: '4ea3fc02-dba6-4b1a-b415-1669d14b7d4e',
    legalInfo: '44189308-2508-4527-af08-ca1d63acfc56',
    termsOfUse: '986d9241-a8d3-4c41-bbd1-2bd9d4970c35',
    dpnDocument: '26600e36-a9aa-4ab2-86b2-7295cc25eb52'
  },
  errorCodes: {
    deregError: 'E000070'
  },
  gateWayURL: {
    version: '1.0.0'
  },
  method: {
    get: '57ac73ba-77e0-4a33-a929-3d65ab8f2fe7',
    post: 'a0e18101-8606-4fe1-bd0e-77bee764a76b',
    put: '616bb2f1-5dda-4dd5-bea9-6a2ee7e0bbe7',
    patch: '18f71415-2689-4c74-b984-f4a9792bf94b',
    delete: '3f0e9156-7e65-40b6-b33b-5d6585df1c82'
  },
  auditLogsModuleIds: {
    pricingModuleId: 'd213c00e-c773-4e0e-b562-1ca599e897e0'
  },
  oneTimeBillingType: {
    billingTypeCode: 'ONE_TIME_PURCHASE'
  },
  recurringBillingType: {
    billingTypeCode: 'RECURRING_PAYMENT'
  },
  recurringInvoiceBillingType: {
    billingTypeCode: 'RECURRING_INVOICE'
  },
  contactSalesBillingType: {
    billingTypeCode: 'CONTACT_SALES'
  },
  oneTimePostBillingType: {
    billingTypeCode: 'ONE_TIME_POST_PAYMENT'
  },
  freePlanBillingType: {
    billingTypeCode: 'FREE'
  },
  freePolicyBillingType: {
    billingTypeCode: 'FREE_POLICY'
  },
  publishInProgress: {
    description: 'PUBLISH_IN_PROGRESS'
  },
  tenantPublish: {
    description: 'TENANT_PUBLISH',
    statusId: 'b3ea7ad1-2468-417d-8051-d31dd227fbe8'
  },
  inProgress: {
    description: 'IN PROGRESS'
  },
  readyForPublish: {
    statusId: 'dc841ba0-2141-4c57-b26a-d5ap98k84542',
    description: 'READY_FOR_PUBLISH'
  },
  quotationDocumentType: {
    buyerImg: '6d54d820-3cf5-11ee-be56-0242ac120002',
    buyerDoc: '75f3286a-3cf5-11ee-be56-0242ac120002',
    sellerImg: '7d88e7d6-3cf5-11ee-be56-0242ac120002',
    sellerDoc: '860046de-3cf5-11ee-be56-0242ac120002'
  },
  dpnDocument: {
    name: 'dataprotectionpolicydocument'
  },
  usageAvailability: {
    standalone: 'Standalone',
    workflow: 'Workflow'
  },
  bestFields: {
    availability: 'availability',
    adapterAccess: 'adapterAccess',
    adapterLocation: 'adapterLocation'
  },
  currencyOptions: [
    {
      regionCode: 'APAC',
      currencies: ['INR']
    },
    {
      regionCode: 'EU',
      currencies: ['EUR']
    },
    {
      regionCode: 'NA',
      currencies: ['USD']
    }
  ],
  occurrenceType: {
    oneTime: 'One Time',
    recurring: 'Recurring'
  },
  pricingPlanType: {
    usageBased: 'USAGE_BASED',
    customPlans: 'CUSTOM_PLANS',
    payAsYouGo: 'PAY_AS_YOU_GO',
    flatRate: 'FLAT_RATE',
    freePlan: 'FREE',
    freePolicy: 'FREE_POLICY',
    tryout: 'TRYOUT'
  },
  pricingPaymentType: {
    prepaid: 'PREPAID',
    postpaid: 'POSTPAID',
    notApplicable: 'NOT_APPLICABLE'
  },
  pricingPaymentModePref: {
    invoice: 'INVOICE',
    emandate: 'EMANDATE',
    netbanking: 'NETBANKING'
  },
  typeOfUsers: {
    b2b: 'b2b',
    b2c: 'b2c'
  },
  typeOfRole: {
    seller: 'seller',
    buyer: 'buyer'
  },
  pricingPlanOccurrence: {
    oneTime: 'ONE_TIME',
    recurring: 'RECURRING'
  },
  companyName: {
    charslength: 136,
    sections: 4
  },
  order: {
    statusId: {
      suspended: {
        id: '2cc16cbf-9d1e-4de5-828a-825d5bd98712',
        name: 'SUSPENDED'
      },
      subscribed: {
        id: '62b5ac5e-8c76-4bf3-996c-05b79c66534b',
        name: 'SUBSCRIBED'
      },
      cancellationInProgress: {
        id: 'ba71f4c8-ecc0-42a5-935f-c6d68c0e450a',
        name: 'CANCELLATIONINPROGRESS'
      },
      blocked: {
        id: 'e84f47ea-d3ae-40e0-be90-2942f2969268',
        name: 'BLOCKED'
      },
      unSubscribed: {
        id: 'f649c471-c936-4c02-8364-f76a84ded0f6',
        name: 'UNSUBSCRIBED'
      }
    }
  },
  footerLinks: [
    '/terms-of-use',
    '/corporate-information',
    '/data-protection-policy',
    '/legal-notice'
  ]
};

export const PRICING = {
  PLAN_GROUP_TYPE: {
    GENERIC: { LABEL: 'Generic (default)', VALUE: 'GENERIC' },
    PRIVATE: { LABEL: 'Private (customer specific)', VALUE: 'PRIVATE' }
  }
};

export const REGION = {
  IN: {
    CODE: 'IN'
  }
};

export default idConstants;
