import axios from 'axios';
import * as Constants from '../configuration';
import i18next from 'i18next';

export function getApiStatistics(
  fromDateVal,
  toDateVal,
  role,
  page,
  rowsPerPage,
  docDownload
) {
  const payload = {
    from: fromDateVal,
    to: toDateVal,
    role: role,
    apps: null,
    isTryout: false,
    tenantId: 'mos'
  };
  return (dispatch) =>
    new Promise((resolve) => {
      axios({
        method: 'POST',
        url: Constants.API_STATS_ACTION.USAGE_TRACKING + `/${page}/${rowsPerPage}`,
        data: payload,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          resolve(response);
          if (!docDownload) {
            dispatch({
              type: 'GET_USAGE_TRACKING',
              payload: response.data,
              headers: response.headers
            });
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response?.data?.status === 404) {
            dispatch({
              type: 'GET_USAGE_TRACKING',
              payload: {},
              headers: {},
              errorMsg: i18next.t('NO_DATA_AVAILABLE') + ' !'
            });
          } else {
            dispatch({
              type: 'ERROR',
              error: error
            });
          }
        });
    });
}
export const getUserDetails = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Constants.USER_DETAILS_ACTION.USER_DETAILS,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      );
      const data = response.data ? response.data : { organization: {} };
      dispatch({
        type: 'SET_USER_DETAILS',
        payload: data
      });
      dispatch({
        type: 'COUNTRY_CODE_REGION',
        payload: response.data[0]?.countryCode,
        flag: true
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: 'ERROR',
        error: err
      });
    }
  };
};

function checkIfArray(param) {
  if (Array.isArray(param)) {
    return param;
  } else {
    return param ? param.split(',').map((item) => item.trim()) : [];
  }
}

export function getApiMetrics(metrics, fromDateVal, toDateVal, role, apiIds) {
  return (dispatch) =>
    axios
      .post(
        Constants.API_STATS_ACTION.USAGE_METRICS,
        {
          metrics: checkIfArray(metrics),
          from: fromDateVal,
          to: toDateVal,
          role: role,
          apis: checkIfArray(apiIds)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_USAGE_METRICS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
