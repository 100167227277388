// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Libs
import i18next from 'i18next';

// Containers
import SingleSelectDropdown from '../../../msreact-kit/html-components/dropDowm';

//Components
import OrderWidget from './charts/order';
import ProductWidget from './charts/product';
import RevenueWidget from './charts/revenue';
import TopSellingProductWidget from './charts/topSellingProduct';

// Styles
import './index.scss';

// Utils
import { getTenantOptions, getCategoryList } from './utils';

//Data
import idConstants from '../../../data/idConstants';

// API's
import { getTenantCategory } from '../../../actions/api/CategoryMappingAction';


const UnifiedSellerDashboard = React.memo(() => {
  const dispatch = useDispatch();
  const [selectedTenant, setSelectedTenant] = useState(idConstants.allTenants);
  const [tenantList, setTenantList] = useState([]);

  const { userCompanyStatus, tenantCategoryMapping } = useSelector((state) => {
    return {
      userCompanyStatus: state?.CompanyDetailsReducer?.userCompanyStatus,
      tenantCategoryMapping: state?.CategoryMappingReducer?.tenantCategoryMapping,
    };
  });

  useEffect(() => {
    getCategoryList(idConstants.submitted.statusId, idConstants, dispatch);
  }, []);

  const tenantHandleChange = (event) => {
    const selectedValue = event.value.id;
    setSelectedTenant(selectedValue);
  };

  useEffect(() => {
    if (userCompanyStatus?.companyRegisteredStatus !== 'approved') {
      return;
    }
    dispatch(getTenantCategory());
  }, []);


  useEffect(() => {
    if (!tenantCategoryMapping || tenantCategoryMapping.length === 0) {
      return;
    }

    const tenantOptions = getTenantOptions(tenantCategoryMapping);
    tenantOptions.unshift({
      id: idConstants.allTenants,
      label: i18next.t('DYNAMIC_FORM.API_STATISTICS.ALL'),
    });
    setTenantList(tenantOptions);
  }, [tenantCategoryMapping]);

  return (
    <>
      <div className='usp-dashboard__container'>
        <div className='usp-dashboard__header'>
          <div className='usp-dashboard__heading-text'>
            {i18next.t('DASHBOARD.TITLE')}
          </div>
          <div>
            <div className='usp-dashboard__dropdown'>
              <SingleSelectDropdown
                options={tenantList}
                handleChange={tenantHandleChange}
                fieldValue={tenantList[0]?.id}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            className='usp-dashboard__card-container'
          >
            <div>
              <OrderWidget selectedTenant={selectedTenant} />
            </div>
            <div>
              <ProductWidget selectedTenant={selectedTenant} />
            </div>
            <div>
              <RevenueWidget selectedTenant={selectedTenant} />
            </div>
            <div>
              <TopSellingProductWidget selectedTenant={selectedTenant} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default UnifiedSellerDashboard;
