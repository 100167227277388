import axios from 'axios';
import {
  ORDER_FULFILLMENT_ACTION,
  USER_SERVICE_CAPABILITIES_ACTION,
  PRICING_PLAN_CAPABILITIES_ACTION
} from '../../actions/configuration';
import { getProductDocsListById } from '../ProductDetailAction';
import * as ACTION_TYPES from '../ActionTypes';
import idConstants from '../../data/idConstants';
import { getStateList } from './getStateList';

const ORDER_STATUS = {
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  READY_TO_SHIP: 'READY_TO_SHIP',
  IN_TRANSIT: 'INTRANSIT',
  POD_UPLOADED: 'DELIVERED',
  ORDER_DELIVERED: 'BUYER_APPROVED',
  ORDER_REJECTED: 'ORDER_REJECTED',
  REJECTED_WITH_POD: 'REJECTED_WITH_POD'
};

export const userPayloadData = (orderDescription) => ({
  userDetails: {
    typeOfUser: idConstants.typeOfUsers.b2b,
    userInfo: [
      {
        b2bIdentifier: orderDescription?.sellerDetails?.companyId ?? orderDescription?.productDetailsDto?.companyId,
        userId: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
        emailId: orderDescription?.sellerDetails?.userEmailId,
        role: idConstants.typeOfRole.seller
      },
      {
        b2bIdentifier: orderDescription?.buyerDetails?.companyId,
        userId: orderDescription?.buyerDetails?.userId,
        emailId: orderDescription?.buyerDetails?.userEmailId,
        role: idConstants.typeOfRole.buyer
      }
    ]
  },
  mappingInfo: {
    itemId: orderDescription?.productOrderDto?.productId
  },
  classifiers: {
    categoryId: orderDescription?.orderSubscriptionMap?.productCategoryId
  }
});

export function getOrderSummary(orderId) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    dispatch({
      type: ACTION_TYPES.SET_SHIPPING_ADDRESS,
      payload: {}
    });
    axios({
      method: 'GET',
      url: `${ORDER_FULFILLMENT_ACTION.GET_ORDER_SUMMARY}/${orderId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (
          !response?.data?.buyerDetails ||
          !response?.data?.productOrderDto?.productId
        ) {
          return dispatch({
            type: ACTION_TYPES.CHANGE_ERROR_STATE,
            payload: true
          });
        } else {
          dispatch({
            type: ACTION_TYPES.GET_ORDER_SUMMARY,
            payload: response.data
          });
          if (
            response?.data?.buyerDetails?.userId &&
            response?.data?.buyerDetails?.companyId &&
            response?.data?.orderSubscriptionMap?.tenantId
          ) {
            axios
              .get(
                `${USER_SERVICE_CAPABILITIES_ACTION.GET_COMPANY_DETAILS}userId=${response?.data?.buyerDetails?.userId}&companyId=${response?.data?.buyerDetails?.companyId}`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'X-tenant': response?.data?.orderSubscriptionMap?.tenantId
                  }
                }
              )
              .then((companyRegistrationDetailsResponse) => {
                const { data } = companyRegistrationDetailsResponse;
                dispatch({
                  type: ACTION_TYPES.SET_BILLING_ADDRESS,
                  payload: data.data
                });
                if (
                  response?.data?.productDetailsDto?.category?.displayName ===
                    idConstants.hardware.productCategoryName &&
                  companyRegistrationDetailsResponse
                ) {
                  axios({
                    method: 'GET',
                    url: `${ORDER_FULFILLMENT_ACTION.GET_SHIPPING_ADDRESS}?productOrderId=${response?.data?.productOrderDto?.productOrderId}`,
                    headers: {
                      'Content-Type': 'application/json',
                      'X-tenant': response?.data?.orderSubscriptionMap?.tenantId
                    }
                  })
                    .then((response) => {
                      dispatch(getStateList()).then((stateList) => {
                        const stateItem = stateList.find(
                          (item) => item.isoCode === response?.data.state
                        );
                        const updatedShippingAddress = {
                          ...response?.data,
                          state: stateItem?.stateName,
                          country:
                            response.data.country ===
                              idConstants.countryCode.countryCode &&
                            idConstants.countryCode.country
                        };
                        if (
                          updatedShippingAddress?.houseNumber &&
                          updatedShippingAddress?.street &&
                          updatedShippingAddress?.city &&
                          updatedShippingAddress?.state &&
                          updatedShippingAddress?.country &&
                          updatedShippingAddress?.postalCode
                        ) {
                          dispatch({
                            type: ACTION_TYPES.SET_SHIPPING_ADDRESS,
                            payload: updatedShippingAddress
                          });
                        }
                      });
                    })
                    .catch((error) => {
                      dispatch({
                        type: ACTION_TYPES.SET_LOADER,
                        payload: false
                      });
                      dispatch({
                        type: 'ERROR',
                        error: error
                      });
                    });
                }
              })
              .catch((error) => {
                dispatch({
                  type: ACTION_TYPES.SET_LOADER,
                  payload: false
                });
                dispatch({
                  type: 'ERROR',
                  error: error
                });
              });
          }
        }

        if (response?.data?.productOrderDto?.productId) {
          dispatch(
            getProductDocsListById(response.data.productOrderDto.productId, 'all')
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
  };
}

export function getOrderStatus(productOrderDetailId) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    return axios
      .get(`${ORDER_FULFILLMENT_ACTION.GET_ORDER_STATUS}/${productOrderDetailId}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.GET_ORDER_STATUS,
          payload: {
            data: response.data,
            cancellable: response?.headers['is-cancellable']
          }
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      });
  };
}

export function getComments(productOrderDetailId) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    return axios
      .get(
        `${ORDER_FULFILLMENT_ACTION.GET_COMMENTS}?productOrderDetailId=${productOrderDetailId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.GET_COMMENTS,
          payload: response.data
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      });
  };
}

export function confirmOrder(productOrderDetailId, orderDescription) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    axios
      .put(
        `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            orderStatus: ORDER_STATUS.ORDER_CONFIRMED,
            comment: '',
            blobFileUrl: '',
            productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
            productOrderTrackingDetailsDTO: {
              logisticsName: '',
              lrNumber: '',
              trackingUrl: '',
              expectedDeliveryDate: ''
            }
          },
          ...userPayloadData(orderDescription)
        }
      )
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.CHANGE_ORDER_STATUS,
          payload: response.data.data
        });

        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
  };
}

export function rejectPod(
  formData,
  fileUploadRemark,
  productOrderDetailId,
  orderDescription
) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    dispatch(fileUpload(formData)).then((fileData) => {
      let podDocumentPayload = null;
      if (fileData) {
        podDocumentPayload = {
          blobFileUrl: fileData?.data?.url,
          fileName: fileData?.data?.filename,
          fileSize: fileData?.data?.fileSize
        };
      }
      axios
        .put(
          `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              orderStatus: ORDER_STATUS.REJECTED_WITH_POD,
              comment: fileUploadRemark,
              productOrderPODUploadDto: podDocumentPayload,
              blobFileUrl: '',
              productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
              productOrderTrackingDetailsDTO: {
                logisticsName: '',
                lrNumber: '',
                trackingUrl: '',
                expectedDeliveryDate: ''
              }
            },
            ...userPayloadData(orderDescription)
          }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.CHANGE_ORDER_STATUS,
            payload: response.data.data
          });
          dispatch({
            type: ACTION_TYPES.SET_LOADER,
            payload: false
          });
        })
        .catch((error) => {
          dispatch({
            type: ACTION_TYPES.SET_LOADER,
            payload: false
          });
          dispatch({ type: 'ERROR', payload: error });
        });
    });
  };
}

export function rejectOrder() {
  return (dispatch) =>
    axios
      .get(ORDER_FULFILLMENT_ACTION, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: '',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function addShippingRemark(productOrderDetailId, comment, orderDescription) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    axios
      .put(
        `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            orderStatus: ORDER_STATUS.READY_TO_SHIP,
            comment: comment,
            blobFileUrl: '',
            productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
            productOrderTrackingDetailsDTO: {
              logisticsName: '',
              lrNumber: '',
              trackingUrl: '',
              expectedDeliveryDate: ''
            }
          },
          ...userPayloadData(orderDescription)
        }
      )
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.CHANGE_ORDER_STATUS,
          payload: response.data.data
        });

        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
  };
}

export const fileUpload = (formData) => {
  if (!formData) {
    // Except Other categories formData will be null by default if user is not uploading the POD file
    return (dispatch) => {
      return Promise.resolve(null);
    };
  }
  return (dispatch) => {
    return axios({
      method: 'POST',
      url: `${ORDER_FULFILLMENT_ACTION.UPLOAD_POD_DOCUMENT}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        dispatch({ type: 'ERROR', payload: error });
      });
  };
};

export function addTransitDetails(
  productOrderDetailId,
  transitData,
  imageData,
  orderDescription
) {
  // commented code to be used in upload file future release
  if (imageData) {
    return (dispatch) => {
      dispatch({
        type: ACTION_TYPES.SET_LOADER,
        payload: true
      });
      dispatch(fileUpload(imageData)).then((response) => {
        axios
          .put(
            `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
            {
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                orderStatus: ORDER_STATUS.IN_TRANSIT,
                comment: '',
                blobFileUrl: '',
                productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
                productOrderTrackingDetailsDTO: {
                  logisticsName: transitData.logisticsName,
                  lrNumber: transitData.lrNumber,
                  trackingUrl: transitData.trackingUrl,
                  expectedDeliveryDate: transitData.expectedDeliveryDate,
                  blobFileUrl: response?.data?.url,
                  fileName: response?.data?.filename,
                  fileSize: response?.data?.fileSize
                }
              },
              ...userPayloadData(orderDescription)
            }
          )
          .then((response) => {
            dispatch({
              type: ACTION_TYPES.CHANGE_ORDER_STATUS,
              payload: response.data.data
            });
            dispatch({
              type: ACTION_TYPES.SET_LOADER,
              payload: false
            });
          })
          .catch((error) => {
            dispatch({
              type: ACTION_TYPES.SET_LOADER,
              payload: false
            });
            dispatch({
              type: 'ERROR',
              error: error
            });
          });
      });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: ACTION_TYPES.SET_LOADER,
        payload: true
      });
      axios
        .put(
          `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              orderStatus: ORDER_STATUS.IN_TRANSIT,
              comment: '',
              blobFileUrl: '',
              productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
              productOrderTrackingDetailsDTO: {
                logisticsName: transitData.logisticsName,
                lrNumber: transitData.lrNumber,
                trackingUrl: transitData.trackingUrl,
                expectedDeliveryDate: transitData.expectedDeliveryDate
              }
            },
            ...userPayloadData(orderDescription)
          }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.CHANGE_ORDER_STATUS,
            payload: response.data.data
          });
          dispatch({
            type: ACTION_TYPES.SET_LOADER,
            payload: false
          });
        })
        .catch((error) => {
          dispatch({
            type: ACTION_TYPES.SET_LOADER,
            payload: false
          });
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    };
  }
}

export function uploadPodDocument(
  formData,
  fileUploadRemark,
  productOrderDetailId,
  orderDescription
) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    dispatch(fileUpload(formData)).then((fileData) => {
      let podDocumentPayload = null;
      if (fileData) {
        podDocumentPayload = {
          blobFileUrl: fileData?.data?.url,
          fileName: fileData?.data?.filename,
          fileSize: fileData?.data?.fileSize
        };
      }
      axios
        .put(
          `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              orderStatus: ORDER_STATUS.POD_UPLOADED,
              comment: fileUploadRemark,
              productOrderPODUploadDto: podDocumentPayload,
              blobFileUrl: '',
              productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
              productOrderTrackingDetailsDTO: {
                logisticsName: '',
                lrNumber: '',
                trackingUrl: '',
                expectedDeliveryDate: ''
              }
            },
            ...userPayloadData(orderDescription)
          }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.CHANGE_ORDER_STATUS,
            payload: response.data.data
          });
          dispatch({
            type: ACTION_TYPES.SET_LOADER,
            payload: false
          });
        })
        .catch((error) => {
          dispatch({
            type: ACTION_TYPES.SET_LOADER,
            payload: false
          });
          dispatch({ type: 'ERROR', payload: error });
        });
    });
  };
}

export function postComment(productOrderId, comment, productOrderDetailId) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    axios
      .post(`${ORDER_FULFILLMENT_ACTION.GET_COMMENTS}`, {
        productOrderId: productOrderId,
        comment: comment,
        productOrderDetailId: productOrderDetailId
      })
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.POST_COMMENT,
          payload: 1
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
  };
}

export const submitRejection = (data, productOrderDetailId, orderDescription) => {
  const { rejectedItemValue, rejectionTextAreaValue } = data;
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    axios
      .put(
        `${ORDER_FULFILLMENT_ACTION.CHANGE_ORDER_STATUS}/${productOrderDetailId}`,
        {
          data: {
            orderStatus: ORDER_STATUS.ORDER_REJECTED,
            comment: rejectionTextAreaValue,
            rejectReasonId: rejectedItemValue.value.id,
            blobFileUrl: '',
            productCreatedBy: orderDescription?.sellerDetails?.userId ?? orderDescription?.productDetailsDto?.createdBy,
            productOrderTrackingDetailsDTO: {
              logisticsName: '',
              lrNumber: '',
              trackingUrl: '',
              expectedDeliveryDate: ''
            }
          },
          ...userPayloadData(orderDescription)
        }
      )
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.CHANGE_ORDER_STATUS,
          payload: response.data.data
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
  };
};

export const getRejectionReasons = (productCategoryId) => {
  return (dispatch) => {
    axios
      .get(
        `${ORDER_FULFILLMENT_ACTION.GET_REJECTION_REASON}?productCategoryId=${productCategoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        const { data } = response;
        if (data.length) {
          dispatch({
            type: ACTION_TYPES.GET_REJECTION_REASON,
            payload: data
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
  };
};

export const resetOrderState = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.RESET_ORDER_STATE
    });
    dispatch({
      type: 'GET_PRODUCT_THUMBNAIL_IMG',
      payload: []
    });
  };
};

export function getPricingPolices(productId) {
  return (dispatch) =>
    axios
      .get(
        PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
          `pricingPlan?itemId=${productId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PRICING_DETAILS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
