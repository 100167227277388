import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { IconButton, Table } from '../../../msreact-kit/packages/html-components';
import capitalizeText from '../../../utils/capitalize-text';
import Images from '../../../assets/img';
import idConstants, { PRICING } from '../../../data/idConstants';
import CustomToolTip from '../../Shared/custom-tooltip';
import { getRateLimitTypeName } from '../../../utils/get-rate-limit-type';

// Component
import { ChartGrid } from '@mobilitystore/charts-interface';
import CustomCell from '../../../msreact-kit/html-components/custom-cell';

// CSS
import './index.scss';

const PricingPlanDialog = ({ planPreviewData, handleCancel, rateLimitTypes }) => {
  const {
    planName,
    planCost,
    currency,
    description,
    planBillingCycle,
    planBenefits,
    planGroupName,
    privatePlanDetails
  } = planPreviewData;

  const privatePlanColDefs = [
    {
      field: 'companyId',
      headerName: 'Company ID',
      flex: 1,
      cellRenderer: (cellProps) => (
        <CustomCell {...cellProps} showThumbnail={false} />
      )
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      cellRenderer: (cellProps) => (
        <CustomCell {...cellProps} showThumbnail={false} />
      )
    }
  ];

  const [pricingPlanCost, setPricingPlanCost] = useState();

  let tableOrder = [];

  useEffect(() => {
    let priceValues = [];
    planPreviewData?.rateLimit?.slabLimitDetails?.forEach((data) => {
      priceValues.push(parseFloat(data.cost));
      priceValues.sort((a, b) => a - b);
      setPricingPlanCost(`${parseFloat(priceValues[0]).toFixed(2)} ${
        planPreviewData?.currency?.currencyCode
      } - ${parseFloat(priceValues[priceValues.length - 1]).toFixed(2)}
     ${planPreviewData?.currency?.currencyCode}`);
    });
  }, []);

  const getConsumptionUnit = (consumptionUnitOrg) => {
    if (
      consumptionUnitOrg?.toUpperCase()?.replace(' ', '_') ===
      idConstants.pricing.reqCountLimitType
    ) {
      return i18next.t('DYNAMIC_FORM.PRICING_PLAN.REQUEST');
    }

    return getRateLimitTypeName(rateLimitTypes, consumptionUnitOrg);
  };

  const getBillingCycle = () => {
    if (
      idConstants.oneTimeBillingType.billingTypeCode ===
      planBillingCycle?.billingCycleType
    )
      return i18next.t('DYNAMIC_FORM.PAYMENT_OPTIONS.ONE_TIME_PAYMENT');

    if (
      planBillingCycle?.billingCycleType ===
      idConstants.pricing.monthBillingCycleType
    )
      return i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY');

    return planBillingCycle?.billingCycleType;
  };

  const slabPricingRowData = [
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.SL_NO'),
      value: '1',
      name: 'serialNumber'
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICING_TIER'),
      value: '',
      name: 'pricingTier'
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA'),
      value:
        planPreviewData?.rateLimit?.timeUnit?.timeUnitCode ===
        idConstants.pricing.monthBillingCycleType
          ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY')
          : planPreviewData?.rateLimit?.timeUnit?.timeUnitCode
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICE'),
      value: '',
      name: 'price'
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.UNIT'),
      value:
        planPreviewData?.rateLimit?.chargeableUnit?.chargeableUnitType ===
        idConstants?.pricing?.perReqHitSlabType
          ? `${i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER')} ${getConsumptionUnit(planPreviewData?.rateLimit?.rateLimitType?.limitType)}`
          : i18next.t('DYNAMIC_FORM.PRICING_CARD.FULL_SLAB')
    }
  ];

  const getLimitTypeDisplayText = (limitType) => {
    switch (limitType) {
      case idConstants.pricing.consumptionNoMeasurementType:
        return i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE');
      case idConstants.pricing.reqCountLimitType:
        return i18next.t('DYNAMIC_FORM.PRICING_PLAN.REQUEST');
      default:
        return planPreviewData?.rateLimit?.rateLimitType?.limitType
          .split('_')
          .map((word) => capitalizeText(word))
          .join(' ');
    }
  };

  const rowData = [
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA'),
      value: getConsumptionUnit(planPreviewData?.rateLimit?.timeUnit?.timeUnitCode)
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICE'),
      value: planCost + ' ' + currency?.currencyCode
    }
  ];

  if (
    planPreviewData?.rateLimit?.rateLimitType?.limitType !==
    idConstants.pricing.consumptionNoMeasurementType
  ) {
    rowData.push({
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.UNIT'),
      value:
        planPreviewData?.rateLimit?.rateLimitType?.limitType ===
        idConstants.pricing.consumptionNoMeasurementType
          ? i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE')
          : i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER') +
            ' ' +
            getConsumptionUnit(planPreviewData?.rateLimit?.rateLimitType?.limitType)
    });
  }

  const activationFeeData = [
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.LIMIT'),
      value: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE')
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA'),
      value: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE')
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICE'),
      value: planPreviewData?.activationFee + ' ' + currency?.currencyCode
    },
    {
      title: i18next.t('SHARED.REMARKS'),
      value: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.BILLED_IN_FIRST_MONTH')
    }
  ];

  let slabPricingTableData = [
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.BILLING_CYCLE'),
      value: getBillingCycle(),
      subTitle: [
        {
          title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CONSUMPTION_QUOTA'),
          value:
            planPreviewData?.rateLimit?.timeUnit?.timeUnitCode ===
            idConstants.pricing.monthBillingCycleType
              ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY')
              : planPreviewData?.rateLimit?.timeUnit?.timeUnitCode
        }
      ]
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_BENEFITS'),
      value: planBenefits
    }
  ];

  if (
    planPreviewData?.rateLimit?.rateLimitType?.limitType !==
    idConstants.pricing.consumptionNoMeasurementType
  ) {
    slabPricingTableData[0].subTitle.push({
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CONSUMPTION_UNIT'),
      value: getLimitTypeDisplayText(
        planPreviewData?.rateLimit?.rateLimitType?.limitType
      )
    });
  }

  let tableDataValue = [
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.BILLING_CYCLE'),
      value: getBillingCycle()
    },
    {
      title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_BENEFITS'),
      value: planBenefits
    }
  ];

  if (
    planPreviewData?.rateLimit?.rateLimitType?.limitType !==
    idConstants.pricing.consumptionNoMeasurementType
  ) {
    tableDataValue[0].subTitle = [
      {
        title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CONSUMPTION_UNIT'),
        value: getConsumptionUnit(
          planPreviewData?.rateLimit?.rateLimitType?.limitType
        )
      }
    ];
  }

  const getTableData = (data, tableData, index) => {
    if (!data.name) return data.value;

    switch (data.name) {
      case 'serialNumber':
        return index + 1;
      case 'pricingTier':
        if (tableData.maxValue === '-1')
          return `${tableData.minValue} +${planPreviewData?.rateLimit?.rateLimitType?.limitType === idConstants.pricing.reqCountLimitType ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.REQUESTS') : getConsumptionUnit(planPreviewData?.rateLimit?.rateLimitType?.limitType)}`;

        return `${tableData.minValue} to ${tableData.maxValue} ${planPreviewData?.rateLimit?.rateLimitType?.limitType === idConstants.pricing.reqCountLimitType ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.REQUESTS') : getConsumptionUnit(planPreviewData?.rateLimit?.rateLimitType?.limitType)}`;
      case 'price':
        return `${tableData.cost}  ${currency?.currencyCode}`;
      default:
        return i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE');
    }
  };

  const getPlanDataTable = (tableColumnData) => {
    const tableAttributes = {
      header: tableColumnData.map(
        (data) =>
          data.value && (
            <th
              key={data.title}
              className='plan__table_container_style_content -size-m'
              scope='col'
            >
              {data.title}
            </th>
          )
      ),

      content:
        tableColumnData?.length > 0 &&
        tableColumnData.map(
          (data) =>
            data.value && (
              <td
                key={data.value}
                className='plan__table_container_style_details -size-m'
              >
                {data?.value
                  ? data.value
                  : i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE')}
              </td>
            )
        )
    };

    return tableAttributes;
  };

  const fixedPlanData = getPlanDataTable(rowData);

  const usageBasedData = {
    header: slabPricingRowData.map(
      (data) =>
        data.value !== null && (
          <th
            key={data.title}
            className='plan__table_container_style_content -size-m'
            scope='col'
          >
            {data.title}
          </th>
        )
    ),

    content: planPreviewData?.rateLimit?.slabLimitDetails?.map(
      (tableData, index) => (
        <tr
          className='plan__table_container_style_row'
          key={tableData?.slabLimitDetailId}
        >
          {slabPricingRowData?.length > 0 &&
            slabPricingRowData.map(
              (data) =>
                data.value !== null && (
                  <td
                    key={data.value}
                    className='plan__table_container_style_details -size-m'
                  >
                    {getTableData(data, tableData, index)}
                  </td>
                )
            )}
        </tr>
      )
    )
  };

  const activationBased = getPlanDataTable(activationFeeData);

  const getBillingType = () => {
    if (
      planPreviewData?.billingType?.billingTypeCode ===
        idConstants.recurringBillingType.billingTypeCode ||
      planPreviewData?.billingType?.billingTypeCode ===
        idConstants.recurringInvoiceBillingType.billingTypeCode
    ) {
      //Recurring Payment Type only will have SLAB and TIER
      switch (planPreviewData?.rateLimit?.chargeableUnit?.chargeableUnitType) {
        case idConstants.pricing.perReqHitSlabType:
          return i18next.t('DYNAMIC_FORM.PRICING_CARD.PER_REQUEST_HIT');
        case idConstants.pricing.fullSlabType:
          return i18next.t('DYNAMIC_FORM.PRICING_CARD.FULL_SLAB');
        default:
          return '';
      }
    } else return '';
  };
  const getPlanPrice = () => {
    if (
      planPreviewData?.planCost > 0 ||
      planPreviewData?.billingType?.billingTypeCode ===
        idConstants.freePlanBillingType.billingTypeCode
    ) {
      if (planPreviewData.isSlabPricing)
        return `${
          parseFloat(planCost).toFixed(2) +
          ' ' +
          `${currency?.currencyCode} (+ ${i18next.t(
            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.USAGE'
          )})`
        }`;

      if (
        planPreviewData?.billingType?.billingTypeCode ===
        idConstants.freePlanBillingType.billingTypeCode
      )
        return `${
          parseFloat(planCost).toFixed(2) + ' ' + `${currency?.currencyCode}`
        }`;

      if (planPreviewData.isSlabPricing) return pricingPlanCost;

      return parseFloat(planCost).toFixed(2) + ' ' + currency?.currencyCode;
    }
  };

  if (planPreviewData?.isSlabPricing)
    tableOrder = [
      {
        title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.USAGE_BASED_PRICE'),
        data: usageBasedData
      }
    ];

  if (planPreviewData?.planCost > 0)
    tableOrder = [
      {
        title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.FIXED_PRICE'),
        data: fixedPlanData
      }
    ];

  if (planPreviewData?.activationFee)
    tableOrder = [
      {
        title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.USAGE_BASED_PRICE'),
        data: activationBased
      }
    ];

  if (planPreviewData?.isSlabPricing && planPreviewData?.planCost > 0)
    tableOrder = [
      {
        title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.FIXED_PRICE'),
        data: fixedPlanData
      },
      {
        title: i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.USAGE_BASED_PRICE'),
        data: usageBasedData
      }
    ];

  const getChargeableUnit = () => {
    if (!planPreviewData?.isSlabPricing && planPreviewData?.planCost > 0) {
      return planPreviewData?.rateLimit?.rateLimitType?.limitType !==
        idConstants.pricing.consumptionNoMeasurementType
        ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER') +
            ' ' +
            getConsumptionUnit(planPreviewData?.rateLimit?.rateLimitType?.limitType)
        : '';
    }

    if (
      planPreviewData?.rateLimit?.chargeableUnit?.chargeableUnitType ===
      idConstants?.pricing?.perReqHitSlabType
    ) {
      return `${i18next.t(
        'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER'
      )} ${getConsumptionUnit(
        planPreviewData?.rateLimit?.rateLimitType?.limitType
      )}`;
    }

    return getBillingType();
  };

  const gridProps = {
    rowData: privatePlanDetails.companyDetails,
    columnDefs: privatePlanColDefs,
    suppressDragLeaveHidesColumns: true,
    suppressMoveWhenColumnDragging: true,
    filter: 'agTextColumnFilter'
  };

  return (
    <div className='plan__body'>
      <div className='plan__dialog'>
        <div className='plan__container'>
          <div className='plan__header'>
            <CustomToolTip
              id={planName}
              tooltipData={planName}
              width='dynamic'
              className='plan__plan-name'
            >
              <h4 className='plan__title text-ellipsis'>{planName}</h4>
            </CustomToolTip>
            <h5 className='plan__price'>{getPlanPrice()}</h5>
            <p className='plan__title'>{getChargeableUnit()}</p>
            <IconButton
              label=''
              className='dialog_remark__close'
              iconCss='ui-ic-close'
              iconTitle={i18next.t('DYNAMIC_FORM.ICON_TITLE.CLOSE')}
              onClick={handleCancel}
              isIcon={true}
            />
          </div>
          <div className='plan__content'>
            <div className='plan__content__container'>
              <span className='plan__content__container__title -size-m'>
                {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_DESCRIPTION')}
              </span>
              <span
                data-test-id='plan-description'
                id='simple-modal-title'
                className='plan__content__container__plan__description__details -size-m'
              >
                {description}
              </span>
            </div>
            <div className='plan__description'>
              <div className='plan__table_container'>
                {tableOrder?.length > 0 &&
                  tableOrder?.map((tableOrderData) => {
                    return (
                      <div key={tableOrderData?.title}>
                        <p className='plan__table_container-price -size-m'>
                          {tableOrderData?.title}
                        </p>
                        <Table
                          cssName='plan'
                          hidePagination={true}
                          rowData={tableOrderData?.data}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className='plan__details'>
              {(planPreviewData?.billingType?.billingTypeCode ===
                idConstants.recurringBillingType.billingTypeCode ||
              planPreviewData?.billingType?.billingTypeCode ===
                idConstants.recurringInvoiceBillingType.billingTypeCode
                ? slabPricingTableData
                : tableDataValue
              ).map((item) => (
                <div className='plan__details_section' key={item.title}>
                  <div className='plan__details_section_card'>
                    <div className='plan__details_section_card_division'>
                      <span className='plan__details_section_card_title -size-m'>
                        {item.title}
                      </span>
                      <li className='plan__details_section_card_division_list'>
                        <div className='plan__details_section_card_division_description'>
                          {Array.isArray(item.value) ? (
                            item?.value?.length > 0 &&
                            item?.value.map((data) => (
                              <div
                                className='plan__details_section_card_division_image'
                                key={data?.planBenefitId}
                              >
                                <img
                                  src={Images.checkGrey}
                                  className='plan__details_section_card_division_img'
                                  alt={i18next.t(
                                    'DYNAMIC_FORM.ALTERNATE_TEXT.CHECK_GREY'
                                  )}
                                />
                                <CustomToolTip
                                  id={data?.planBenefitId}
                                  tooltipData={data.benefitDescription}
                                  width='dynamic'
                                  className='description__customtooltip'
                                >
                                  <p
                                    className='benefit_description -size-m text-ellipsis plan-details-title'
                                    id={data?.planBenefitId}
                                  >
                                    {data.benefitDescription}
                                  </p>
                                </CustomToolTip>
                              </div>
                            ))
                          ) : (
                            <div className='plan__details_section_card_division_image'>
                              <img
                                src={Images.checkGrey}
                                className='plan__details_section_card_division_img'
                                alt={i18next.t(
                                  'DYNAMIC_FORM.ALTERNATE_TEXT.CHECK_GREY'
                                )}
                              />
                              <span className='plan-details-title -size-m'>
                                {item.value}
                              </span>
                            </div>
                          )}
                        </div>
                      </li>

                      {item?.subTitle &&
                        Array.isArray(item?.subTitle) &&
                        item?.subTitle.map((data) => (
                          <>
                            <span className='plan__details_section_card_title -size-m'>
                              {data.title}
                            </span>
                            <li className='plan__details_section_card_division_list'>
                              <div className='plan__details_section_card_division_benefits'>
                                <img
                                  src={Images.checkGrey}
                                  className='plan__details_section_card_division_img'
                                  alt={i18next.t(
                                    'DYNAMIC_FORM.ALTERNATE_TEXT.CHECK_GREY'
                                  )}
                                />
                                <p className='-size-m'>
                                  {data?.value
                                    ? data.value
                                    : i18next.t(
                                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE'
                                      )}
                                </p>
                              </div>
                            </li>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {planGroupName === PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE && (
              <div className='plan__details__addl-config'>
                <div className='plan__details__addl-config__title'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_TYPE')}
                </div>
                <div className='plan__details__addl-config__desc'>
                  {planGroupName}
                </div>
                <div className='plan__details__addl-config__title'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PREFERRED_TENANT')}
                </div>
                <div className='plan__details__addl-config__desc'>
                  {privatePlanDetails.tenantId}
                </div>
                {privatePlanDetails.companyDetails?.length > 0 && (
                  <ChartGrid gridProps={gridProps} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlanDialog;

PricingPlanDialog.propTypes = {
  planPreviewData: PropTypes.object,
  handleCancel: PropTypes.func
};

PricingPlanDialog.defaultProps = {
  planPreviewData: {
    planName: 'Plan Name',
    planCost: '1234',
    currency: { currencyCode: 'INR' },
    description: 'description',
    planBillingCycle: { billingCycleType: 'FREE PLAN' },
    planBenefits: [{ benefitDescription: 'Benefit description' }]
  },
  handleCancel: () => null
};
