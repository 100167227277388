import { separatorData, parameterConstants } from '../../data/appConstants';
import toastMessage from '../../data/toastMessages';
import Attributes from '../../data/attributes';
import isEmpty from '../../validator/isEmpty';
import { formatEndpointDetails } from '../../shared/shared-function/formatEndpointDetails';

const initialState = {
  prodBasicInfo: {},
  breadCrumbValue: '',
  prodDetailedInfo: {},
  allProducts: [],
  allProductsCallLoaded: false,
  errorInfo: {},
  publishErrorInfo: null,
  authenticationKey: '',
  validation: {},
  swaggerFilesRes: {},
  swaggerFilesErr: null,
  prodEndErrorInfo: null,
  baseUrlError: {},
  prodNameError: {},
  prodNameEdit: true,
  editableAttributes: Attributes,
  editableDetails: [],
  productsCount: [],
  responseHeaders: {},
  allProductsCount: [],
  publishData: {},
  info: {
    //parameters to check the api state and dispaly appropriate msg
    isEdit: false,
    isApiEdited: false,
    isBasicInfoEdited: false,
    isPlanEdited: false,
    previousSession: 0,
    descriptionChanged: false,
    nonApprovalFieldChanged: false,
    settingsChanged: false,
    apiAdded: false,
    visited: false,
    isDraft: true,
    showPopup: false,
    isView: false,
    isViewEdit: false,
    resetValidation: true,
    showDashBoard: false,
    isConsumer: false,
    throttlingChanged: false,
    isThrottlingEdited: false,
    activeStep: 1,
    isDocumentationEdited: false,
    throttlePolicyId: '',
    isTryOutPlanEdited: false,
    documentationChanged: false
  },
  deleteProdError: undefined,
  deleteProdData: {},
  DELETE_API_CONSUMED: '',
  productSharesData: {},
  tenantProductStatus: {},
  productStatusInfo: {}
};

const SolutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PRODUCT_INFO':
      return Object.assign({}, state, {
        prodBasicInfo: action.payload1,
        prodDetailedInfo: action.payload2.productDetailedInfo
      });
    case 'UPDATE_PRODUCT_INFO':
      return Object.assign({}, state, { prodBasicInfo: action.payload1 });
    case 'GET_ALL_PRODUCTS':
      return Object.assign({}, state, {
        allProducts: action.payload,
        responseHeaders: action.headers
      });
    case 'GET_ALL_PRODUCTS_LOADED':
      return Object.assign({}, state, { allProductsCallLoaded: action.payload });
    case 'GET_PRODUCTS_COUNT':
      return Object.assign({}, state, { productsCount: action.payload });
    case 'GET_ALL_PRODUCTS_COUNT':
      return Object.assign({}, state, { allProductsCount: action.payload });
    case 'GENERATE_SECRET_KEY':
      return Object.assign({}, state, {
        authenticationKey: action.payload,
        errorInfo: {}
      });
    case 'PRODUCT_SHARES_DATA':
      return Object.assign({}, state, {
        productSharesData: action.payload,
        errorInfo: {}
      });
    case 'SAVE_ENDPOINT_INFO':
      return Object.assign({}, state, {
        prodDetailedInfo: action.payload,
        prodEndErrorInfo: null
      });
    case 'RESET_PRODUCT':
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.prodBasicInfo = {};
      stateCopy.prodDetailedInfo = {};
      stateCopy.errorInfo = {};
      stateCopy.authenticationKey = '';
      stateCopy.prodNameEdit = true;
      return stateCopy;
    case 'VALIDATE_PRODUCT_UNIQUENESS':
      return Object.assign({}, state, { prodNameError: {} });
    case 'PRODUCT_NAME_ERROR':
      return Object.assign({}, state, { prodNameError: action.error });
    case 'VALIDATE_PROD_NAME_EDIT':
      return Object.assign({}, state, { prodNameEdit: action.payload });
    case 'VALIDATE_BASE_URL_UNIQUENESS':
      return Object.assign({}, state, { baseUrlError: {} });
    case 'VALIDATE_BASE_URL_ERROR':
      return Object.assign({}, state, { baseUrlError: action.error });
    case 'SAVE_PRODUCT_DETAILED_INFO':
    case 'UPDATE_PRODUCT_DETAILED_INFO':
    case 'SAVE_SECRET_HEADER_PARAM':
      return Object.assign({}, state, {
        prodDetailedInfo: action.payload.prodDetailedInfo,
        errorInfo: {}
      });
    case 'REDUX_STORAGE':
      return Object.assign({}, state, {
        prodBasicInfo: action.payload.prodBasicInfo,
        prodDetailedInfo: action.payload.prodDetailedInfo,
        validation: action.payload.validation
      });
    case 'PRODUCT_INFO_ERROR':
      return Object.assign({}, state, { errorInfo: action.error });
    case 'DELETE_GROUP':
    case 'DELETE_ENDPOINT':
      return Object.assign({}, state, {
        prodDetailedInfo: action.payload
      });
    case 'UPDATE_INFO':
      return Object.assign({}, state, { info: action.payload });
    case 'DELETE_SECRETPARAM':
      return Object.assign({}, state, { prodDetailedInfo: action.payload });
    case 'DELETE_ENDPOINT_PARAMETER':
      return Object.assign({}, state, { prodDetailedInfo: action.payload });
    case 'GET_PRODUCT_DETAILED_INFO':
      let prodBasicInfo =
        action.payload.prodBasicInfo !== undefined &&
        action.payload.prodBasicInfo !== null
          ? action.payload.prodBasicInfo
          : '';
      let prodDetailedInfo =
        action.payload.productDetailedInfo !== undefined &&
        action.payload.productDetailedInfo !== null
          ? action.payload.productDetailedInfo
          : '';
      let businessCategoriesMap =
        action.payload.prodBasicInfo.productBusinessCategoryMap;
      let businessCategories = [];
      let secretHeaderParamData = [];
      prodBasicInfo.approvedDate = action.payload.approvedDate;
      prodBasicInfo.publishedDate = action.payload.publishedDate;
      if (
        prodDetailedInfo.secretHeaderParamData === undefined &&
        prodDetailedInfo.accessControls !== undefined
      ) {
        if (prodDetailedInfo.accessControls.secretHeaders !== undefined) {
          prodDetailedInfo.accessControls.secretHeaders.map((header) => {
            header.type = 'header';
            secretHeaderParamData.push(header);
            header.id = header._id;
            return true;
          });
        }
        if (prodDetailedInfo.accessControls.secretParameter !== undefined) {
          prodDetailedInfo.accessControls.secretParameter.map((parameter) => {
            parameter.type = 'parameter';
            parameter.id = parameter._id;
            secretHeaderParamData.push(parameter);
            return true;
          });
        }
        prodDetailedInfo.secretHeaderParamData = secretHeaderParamData;
      }

      if (businessCategoriesMap.length > 0) {
        businessCategoriesMap.map((value) => {
          let categoryObj = {};
          categoryObj = {
            id: value.businessCategory.businessCategoryId,
            name: value.businessCategory.businessCategoryName
          };
          businessCategories.push(categoryObj);
          return true;
        });
      }
      prodBasicInfo.businessCategories = businessCategories;
      if (prodDetailedInfo.accessControls !== undefined) {
        if (prodDetailedInfo.accessControls.authDetails !== undefined) {
          let authDetails = prodDetailedInfo.accessControls.authDetails;
          let separatorObj = !!authDetails.separator
            ? separatorData.find(function (item) {
                return item.value === authDetails.separator;
              })
            : separatorData[1];
          prodDetailedInfo.authType = authDetails.authType
            ? authDetails.authType
            : '';
          prodDetailedInfo.authTokenUrl = !!authDetails.authTokenUrl
            ? authDetails.authTokenUrl
            : '';
          prodDetailedInfo.clientAuthentication = !!authDetails.clientAuthentication
            ? authDetails.clientAuthentication
            : '';
          prodDetailedInfo.grantType = authDetails.grantType
            ? authDetails.grantType
            : '';
          prodDetailedInfo.authorizationURL = !!authDetails.authorizationURL
            ? authDetails.authorizationURL
            : '';
          prodDetailedInfo.verificationURL = !!authDetails.verificationURL
            ? authDetails.verificationURL
            : '';
          prodDetailedInfo.separator = separatorObj.id;
          prodDetailedInfo.scope = !!authDetails.scope
            ? authDetails.scope.join(separatorObj.value)
            : '';
        }
      }
      let groupDataResponse =
        prodDetailedInfo.groupData !== undefined
          ? prodDetailedInfo.groupData
          : prodDetailedInfo.endpointGroups;
      let groupData = [];
      if (groupDataResponse !== undefined) {
        if (groupDataResponse.length > 0) {
          groupDataResponse.map((value) => {
            groupData.push({
              name: value.groupName,
              description: value.groupDesc,
              id: value._id,
              index: value.index
            });
            return true;
          });
        }
      }
      let endpointsResponse =
        prodDetailedInfo.endpointsData !== undefined
          ? prodDetailedInfo.endpointsData
          : prodDetailedInfo.endPointDetails;
      let endpoints = [];
      if (endpointsResponse !== undefined) {
        if (endpointsResponse.length > 0) {
          endpointsResponse.map((value) => {
            let headers = [];
            if (value.headers !== undefined) {
              value.headers.map((header) => {
                headers.push({
                  parameter: parameterConstants.headerConst,
                  dataType: header.datatype,
                  name: header.name,
                  description:
                    header.description && header.description !== 'null'
                      ? header.description
                      : '',
                  id: header._id,
                  required: header.isRequired
                });
                return true;
              });
            }

            let parameters = [];
            if (value.parameters !== undefined) {
              value.parameters.map((parameter) => {
                parameters.push({
                  parameter: parameterConstants.queryConst,
                  dataType: parameter.datatype,
                  name: parameter.name,
                  description:
                    parameter.description && parameter.description !== 'null'
                      ? parameter.description
                      : '',
                  id: parameter._id,
                  required: parameter.isRequired
                });
                return true;
              });
            }
            let formData = [];
            if (value.formdata !== undefined) {
              value.formdata.map((form) => {
                formData.push({
                  parameter: parameterConstants.formDataConst,
                  dataType: form.datatype,
                  name: form.name,
                  description:
                    form.description && form.description !== 'null'
                      ? form.description
                      : '',
                  id: form._id,
                  required: form.isRequired
                });
                return true;
              });
            }
            let formUrlEncoded = [];
            if (value.formurlEncoded !== undefined) {
              value.formurlEncoded.map((form) => {
                formUrlEncoded.push({
                  parameter: parameterConstants.formUrlEncodedConst,
                  dataType: form.datatype,
                  name: form.name,
                  description:
                    form.description && form.description !== 'null'
                      ? form.description
                      : '',
                  id: form._id,
                  required: form.isRequired
                });
                return true;
              });
            }
            let body = [];
            if (value.body !== undefined) {
              value.body.map((bodyData) => {
                body.push({
                  parameter: parameterConstants.rawBodyConst,
                  dataType: bodyData.datatype,
                  name: bodyData.name,
                  description:
                    bodyData.description && bodyData.description !== 'null'
                      ? bodyData.description
                      : '',
                  id: bodyData._id,
                  required: bodyData.isRequired
                });
                return true;
              });
            }
            let pathParameterData = [];
            if (value.pathParameter !== undefined) {
              value.pathParameter.map((pathData) => {
                pathParameterData.push({
                  Name: pathData.name !== undefined ? pathData.name : '',
                  DataType: pathData.datatype !== undefined ? pathData.datatype : '',
                  Description:
                    pathData.description !== undefined &&
                    pathData.description !== 'null'
                      ? pathData.description
                      : '',
                  Required:
                    pathData.isRequired !== undefined ? pathData.isRequired : '',
                  id: pathData._id !== undefined ? pathData._id : ''
                });
                return true;
              });
            }
            let chargeableStatusCodeData = [];
            if (value.chargeableStatusCode !== undefined) {
              value.chargeableStatusCode.map((chargeableData) => {
                chargeableStatusCodeData.push(chargeableData);
                return true;
              });
            }
            endpoints.push({
              method: value.method !== undefined ? value.method : '',
              name: value.name !== undefined ? value.name : '',
              id: value._id !== undefined ? value._id : '',
              header: headers,
              query: parameters,
              formData: formData,
              formUrlEncoded: formUrlEncoded,
              body: body,
              contentType: value.contentType,
              group: value.group !== undefined ? value.group : '',
              description: value.description !== undefined ? value.description : '',
              relativePath: value.path !== undefined ? value.path : '',
              chargeableStatusCode:
                chargeableStatusCodeData !== undefined
                  ? chargeableStatusCodeData
                  : '',
              paramCount:
                headers.length +
                parameters.length +
                formData.length +
                formUrlEncoded.length +
                body.length,
              relativePathData:
                pathParameterData !== undefined ? pathParameterData : '',
              requestSample:
                value.requestSample?.length > 0 ? value.requestSample : undefined,
              responseSample:
                value.responseSample?.length > 0 ? value.responseSample : undefined,
              index: value.index
            });
            return true;
          });
        }
      }

      if (prodDetailedInfo) {
        prodDetailedInfo.authorizationKey =
          prodDetailedInfo.authorizationKey !== undefined
            ? prodDetailedInfo.authorizationKey
            : '';
        prodDetailedInfo.baseUrl = !!prodDetailedInfo.baseUrl
          ? prodDetailedInfo.baseUrl
          : '';
        prodDetailedInfo.groupData = groupData;
        prodDetailedInfo.endpointsData = endpoints;
        prodDetailedInfo.productSacCode = action.payload.productDetailedInfo
          .productSACCode
          ? action.payload.productDetailedInfo.productSACCode
          : '';
      }
      return Object.assign({}, state, {
        prodBasicInfo: prodBasicInfo,
        prodDetailedInfo: prodDetailedInfo,
        prodName: action.payload.prodBasicInfo.productName
      });
    case 'PUBLISH_API':
      return Object.assign({}, state, {
        allProducts: action.payload1,
        publishData: action.payload2,
        publishErrorInfo: null
      });
    case 'RESET_PUBLISH_API':
      return Object.assign({}, state, { publishData: action.payload });
    case 'PUBLISH_ERROR':
      return Object.assign({}, state, {
        publishErrorInfo: action.error,
        selectedIndex: action.selectedIndex,
        errorMsg: action.errorMsg
      });
    case 'UPLOAD_SWAGGER_FILE':
      let prodInfo = {};
      prodInfo.productName = action.payload.apiName;
      prodInfo.productShortDesc =
        action.payload.description !== undefined ? action.payload.description : '';
      prodInfo.businessCategories = [];
      let prodDetInfo = {};
      prodDetInfo.baseUrl = action.payload.baseUrl;
      prodDetInfo.productLongDesc = '';
      prodDetInfo.termsAndConditions = action.payload.termsAndConditions
        ? action.payload.termsAndConditions
        : '';
      const formattedEndpointDetails = formatEndpointDetails(action.payload);
      const isValidRequestExample = formattedEndpointDetails.isValidRequestExample;
      const isValidResponseExample = formattedEndpointDetails.isValidResponseExample;

      if (!isEmpty(prodDetInfo)) {
        prodDetInfo.groupData = formattedEndpointDetails.groupData;
        prodDetInfo.endpointsData = formattedEndpointDetails.endpointsData;
      }

      const fileErrorMessage = () => {
        if (!isValidRequestExample.includes(false)) {
          if (!isValidResponseExample.includes(false)) {
            return { message: toastMessage.invalidResSwaggerUpload.msg };
          } else {
            return null;
          }
        } else {
          return { message: toastMessage.invalidReqSwaggerUpload.msg };
        }
      };

      return Object.assign({}, state, {
        prodBasicInfo:
          isValidRequestExample.includes(false) ||
          isValidResponseExample.includes(false)
            ? {}
            : prodInfo,
        swaggerProdDetailedInfo:
          isValidRequestExample.includes(false) ||
          isValidResponseExample.includes(false)
            ? {}
            : prodDetInfo,
        swaggerFilesErr:
          isValidRequestExample.includes(false) &&
          isValidResponseExample.includes(false)
            ? { message: toastMessage.invalidReqResSwaggerUpload.msg }
            : fileErrorMessage()
      });
    case 'UPLOAD_SWAGGER_ERROR':
      return Object.assign({}, state, { swaggerFilesErr: action.error });
    case 'PRODUCT_ENDPOINT_ERROR':
      let prodDetGroup = {};
      prodDetGroup.groupData = action.groupsdata;
      return Object.assign({}, state, {
        prodDetailedInfo: prodDetGroup,
        prodEndErrorInfo: action.error
      });
    case 'EDITABLE_ATTRIBUTES_INFO':
      let attributesArray = [];
      action.payload.map((attribute) => {
        attributesArray.push(attribute.attributeId);
        return true;
      });
      for (let key in Attributes) {
        if (attributesArray.includes(Attributes[key].id)) {
          Attributes[key].disabled = false;
        } else {
          Attributes[key].disabled = true;
        }
      }
      return Object.assign({}, state, { editableAttributes: Attributes });
    case 'EDITABLE_DETAILS_INFO':
      return Object.assign({}, state, { editableDetails: action.payload });
    case 'DELETE_PROD':
      return Object.assign({}, state, {
        deleteProdData: action.payload,
        deleteProdConsumed: undefined
      });
    case 'DELETE_PROD_ERROR':
      return Object.assign({}, state, { deleteProdError: action.error });
    case 'DELETE_PROD_CONSUMED':
      return Object.assign({}, state, { deleteProdConsumed: action.errorMsg });
    case 'SET_BREADCRUMB_VALUE':
      return Object.assign({}, state, { breadCrumbValue: action.payload });
    case 'TENANT_PRODUCT_STATUS':
      return Object.assign({}, state, {
        tenantProductStatus: action.payload,
        errorInfo: {}
      });
    case 'SAVE_PRODUCT_STATUS_INFO':
      return Object.assign({}, state, { productStatusInfo: action.payload });
    default:
      return state;
  }
};

export default SolutionReducer;
