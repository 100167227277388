import { combineReducers } from 'redux';
import SolutionReducer from './api/SolutionReducer';
import LoaderReducer from './LoaderReducer';
import ProgressReducer from './ProgressReducer';
import FileUploadReducer from './FileUploadReducer';
import SnackbarReducer from './SnackbarReducer';
import MasterDataReducer from './MasterDataReducer';
import ProductDetailReducer from './ProductDetailReducer';
import SuccessReducer from './SuccessReducer';
import ErrorReducer from './ErrorReducer';
import PricingPlanReducer from './api/PricingPlanReducer';
import ProductPricingPlanReducer from './api/pricing-plan-reducer';
import loginReducer from './LoginReducer';
import ApiStatisticsReducer from './api/ApiStatisticsReducer';
import rejectReasonReducer from './RejectionReasonReducer';
import EndpointsReducer from './api/EndpointsReducer';
import CountryReducer from './CountryReducer';
import ApiThrottlingReducer from './api/ApiThrottlingReducer';
import ApiAlertsReducer from './api/ApiAlertsReducer';
import ApiSkuIdsReducer from './ApiSkuIdsReducer';
import DeleteAccountReducer from './DeleteAccountReducer';
import CompanyDetailsReducer from './CompanyDetailsReducer';
import SalesRepCompanyIdReducer from './SalesRepCompanyIdReducer';
import ProfileReducer from './ProfileReducer';
import DocumentReducer from './DocumentReducer';
import FormReducer from './api/FormReducer';
import HardwareProductReducer from './api/HardwareProductReducer';
import StockAvailReducer from './api/StockAvailReducer';
import SkuiIdGenerateReducer from './api/AdminSkuIdGenerateReducer';
import CategoryManagementReducer from './CategoryManagementReducer';
import AdminOnboardingFormReducer from './api/AdminOnboardingFormReducer';
import OrdersReducer from './api/OrdersReducer';
import OrderSummaryReducer from './api/OrderSummaryReducer';
import QuotationReducer from './api/QuotationReducer';
import SettingsReducer from './api/SettingsReducer';
import CategoryMappingReducer from './api/CategoryMappingReducer';
import UnifiedSellerDashboardReducer from './UnifiedSellerDashboardReducer';

import PublishRequestReducer from './api/PublishRequestReducer';
import ConsumptionReducer from './api/ConsumptionReducer';
import TenantServiceReducer from './tenant-service-reducer';

/**
 * Combine Reducers In One Object
 */
export default combineReducers({
  SuccessReducer,
  ErrorReducer,
  SolutionReducer,
  LoaderReducer,
  ProgressReducer,
  FileUploadReducer,
  SnackbarReducer,
  MasterDataReducer,
  ProductDetailReducer,
  PricingPlanReducer,
  loginReducer,
  ApiStatisticsReducer,
  rejectReasonReducer,
  EndpointsReducer,
  CountryReducer,
  ApiThrottlingReducer,
  ApiAlertsReducer,
  ApiSkuIdsReducer,
  DeleteAccountReducer,
  CompanyDetailsReducer,
  SalesRepCompanyIdReducer,
  ProfileReducer,
  DocumentReducer,
  ProductPricingPlanReducer,
  FormReducer,
  StockAvailReducer,
  SkuiIdGenerateReducer,
  UnifiedSellerDashboardReducer,
  HardwareProductReducer,
  CategoryManagementReducer,
  AdminOnboardingFormReducer,
  OrderSummaryReducer,
  OrdersReducer,
  QuotationReducer,
  PublishRequestReducer,
  SettingsReducer,
  CategoryMappingReducer,
  ConsumptionReducer,
  TenantServiceReducer
});
