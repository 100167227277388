// React
import axios from 'axios';

import { BASE_URL, USER_SERVICE, CAPABILITIES, VERSION_1 } from './configuration';
import { USER_SERVICE_ENDPOINT } from './configuration/user-service';

export const USER_SERVICE_CAPABILITIES_URL = `${BASE_URL}/${USER_SERVICE}/${CAPABILITIES}/${VERSION_1}`;

export const getTenantMap = (companyId) => {
  return (dispatch) =>
    axios({
      method: 'GET',
      url:
        USER_SERVICE_CAPABILITIES_URL +
        USER_SERVICE_ENDPOINT.TENANT_MAP +
        '?companyId=' +
        companyId
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};
