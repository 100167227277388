import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import navigationOptions from './navigationOptionsSeller';
import { getCategories } from '../../../actions/CategoryManagementAction';
import idConstants from '../../../data/idConstants';
import { GAEventAction } from '../../../data/ga-constants';
import { toLowercaseReplaceSpace } from '../../../shared/shared-function/helperFunctions';
import './index.scss';
import { getRegionCode } from '../../../utils/get-region-details';
import {
  accessRoles,
  regionDetails,
  activeCategoryIdInRegion
} from '../../../data/appConstants';

const SideNavigation = ({
  data,
  linkBuyerAccount,
  open,
  handleMenuClick,
  customClassName
}) => {
  const dispatch = useDispatch();
  const isOpen = true;
  const [localSellerCompanyId, setLocalSellerCompanyId] = useState('');
  const { companyStatus, categoryList } = useSelector((state) => {
    return {
      companyStatus: state.CompanyDetailsReducer.userCompanyStatus,
      categoryList: state.CategoryManagementReducer.categoryList
    };
  });
  const role = localStorage.getItem(idConstants.userLoginRole.role);

  const [navigationOptionsData, setNavigationOptionsData] = useState(
    data ? data : navigationOptions
  );

  useEffect(() => {
    const updateDataFromLocalStorage = () => {
      const localStorageData = localStorage.getItem(
        idConstants.salesRepSelectedCompany.selectedCompany
      );
      if (localStorageData) {
        setLocalSellerCompanyId(localStorageData);
      }
    };
    updateDataFromLocalStorage();
    window.addEventListener(idConstants.sellerCompanyId, updateDataFromLocalStorage);
    return () => {
      window.removeEventListener(
        idConstants.sellerCompanyId,
        updateDataFromLocalStorage
      );
    };
  }, [localStorage.getItem(idConstants.salesRepSelectedCompany.selectedCompany)]);

  useEffect(() => {
    if (role === accessRoles.sales_rep) {
      setNavigationOptionsData(
        navigationOptions.filter((item) => item.url === '/dashboard')
      );
    }
  }, [localSellerCompanyId]);

  const handleSellerSideNavigation = () => {
    if (
      companyStatus.companyRegisteredStatus &&
      companyStatus.companyRegisteredStatus === 'approved' &&
      categoryList?.length === 0
    ) {
      dispatch(getCategories(idConstants.submitted.statusId)).then((response) => {
        if (response) {
          const navigationData = getSideBarMenus(response);
          setNavigationOptionsData(navigationData);
        }
      });
    } else {
      const navigationData = getSideBarMenus(categoryList);
      if (navigationData) {
        setNavigationOptionsData(navigationData);
      }
    }
  };

  useEffect(() => {
    if (role?.length > 0 && role !== accessRoles.seller && localSellerCompanyId) {
      dispatch(getCategories(idConstants.submitted.statusId)).then((response) => {
        if (response) {
          const navigationData = getSideBarMenus(response);
          let myproductsData = navigationData.filter(
            (item) =>
              item.url === '/dashboard' || item.url === '/dashboard/myproducts'
          );
          setNavigationOptionsData(myproductsData);
        }
      });
    }
    if (role !== accessRoles.sales_rep) {
      handleSellerSideNavigation();
    }
  }, [companyStatus, localSellerCompanyId, role]);

  const getSideBarMenus = (data) => {
    if (data?.content?.length > 0) {
      const dynamicData = data.content
        .map((category) => ({
          sidebarKey: category.productCategoryName,
          title: category.productCategoryName,
          categoryId: category.productCategoryId,
          icon: (
            <svg
              className='MuiSvgIcon-root a-icon'
              focusable='false'
              viewBox='0 0 24 24'
              aria-hidden='true'
            >
              <path d='M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z'></path>
            </svg>
          ),
          url: `/dashboard/my-products/${toLowercaseReplaceSpace(
            category.productCategoryName
          )}`,
          possibleUrls: `/dashboard/my-products/${category.productCategoryName}`,
          key: category.productCategoryName,
          hasSubMenus: true,
          disabled: false,
          visibility: true,
          displayOptions: false,
          //GA-202
          trackEventData: {
            eventCategory: 'side_bar',
            eventAction: GAEventAction.clicked,
            eventLabel: category?.productCategoryName
          }
        }))
        .filter((item) =>
          getRegionCode() !== regionDetails[0].regionCode
            ? activeCategoryIdInRegion[process.env.REACT_APP_REGION]?.includes(
                item.categoryId
              )
            : true
        );

      const navigationData = navigationOptions.map((navigation) => {
        if (navigation.sidebarKey === idConstants.sidebarConstants.myProducts.key) {
          return {
            ...navigation,
            subMenus:
              getRegionCode() !== regionDetails[0].regionCode
                ? [...dynamicData]
                : [...navigation.subMenus, ...dynamicData]
          };
        }
        return navigation;
      });
      return navigationData;
    }
  };

  const isCompanyApproved =
    companyStatus?.companyRegisteredStatus === 'approved' &&
    companyStatus?.areCompanyDetailsCaptured === true;

  const onSubmenuClicked = (item, level, childItem) => {
    const updateDisplayOptions = (data, sidebarKey) => {
      if (data.sidebarKey === sidebarKey) {
        data.displayOptions = !data.displayOptions;
      } else {
        data.displayOptions = false;
      }
      return data;
    };

    const obj = [...navigationOptionsData];

    if (level === 1) {
      obj.forEach((data) => {
        data.hasSubMenus
          ? updateDisplayOptions(data, item.sidebarKey)
          : updateDisplayOptions(data, null);
      });
    }

    if (level === 2) {
      obj.forEach((data) => {
        if (data.sidebarKey === item.sidebarKey && data.displayOptions) {
          data.subMenus.forEach((subItem) => {
            updateDisplayOptions(subItem, childItem.sidebarKey);
          });
        } else {
          data.subMenus?.forEach((subItem) => updateDisplayOptions(subItem, null));
        }
      });
    }

    setNavigationOptionsData(obj);
  };

  return (
    <div
      className={`${!open ? 'side-navigation-hide' : 'side-navigation'} ${customClassName}`}
    >
      <nav
        className={`m-side-navigation nav_alignment ${isOpen ? '-open' : ''} ${
          role === accessRoles.sales_rep || isCompanyApproved
            ? ''
            : 'side-navigation-disabled'
        }`}
        aria-label='Side Navigation'
        aria-hidden='false'
      >
        <ul className='m-side-navigation__menuItems' role='menubar'>
          {navigationOptionsData?.map((item) => {
            if (item.visibility === false) return null;
            const translateTitle = (title) => {
              return i18next.t(title);
            };

            const icon = { ...item?.icon };

            if (item?.icon?.props) {
              icon.props = { ...item?.icon?.props };
              icon.props.title = translateTitle(item?.icon?.props.title);
            }
            if (!item.hasSubMenus) {
              return (
                <li
                  className='m-side-navigation__menuItem'
                  role='none'
                  key={item.sidebarKey}
                  onClick={() => handleMenuClick(false, item.trackEventData)}
                >
                  <Link
                    to={item.url}
                    role='menuitem'
                    className='m-side-navigation__link'
                  >
                    {icon}
                    <span className='m-side-navigation__label'>
                      {i18next.t(item.title)}
                    </span>
                  </Link>
                </li>
              );
            }
            if (item.hasSubMenus) {
              return (
                <li
                  className={`m-side-navigation__menuItem ${
                    item.displayOptions && '-open'
                  }`}
                  role='none'
                  key={item.sidebarKey}
                >
                  <button
                    type='button'
                    className='m-side-navigation__group --nopadding'
                    aria-haspopup='true'
                    role='menuitem'
                  >
                    <Link
                      to={item.url}
                      role='menuitem'
                      className='m-side-navigation__link'
                      onClick={() => handleMenuClick(false, item.trackEventData)}
                    >
                      {icon}
                      <span className='m-side-navigation__label'>
                        {i18next.t(item.title)}
                      </span>
                    </Link>
                    <i
                      className='a-icon arrow boschicon-bosch-ic-down'
                      title='down'
                      onClick={() => onSubmenuClicked(item, 1)}
                    />
                  </button>
                  <ul
                    className='m-side-navigation__menuSubitems -sub-menu'
                    role='menu'
                  >
                    {item.subMenus.map((subItem) => {
                      const translateTitle = (title) => {
                        return i18next.t(title);
                      };

                      const icon = { ...subItem?.icon };

                      if (subItem?.icon?.props) {
                        icon.props = { ...subItem?.icon?.props };
                        icon.props.title = translateTitle(
                          subItem?.icon?.props.title
                        );
                      }
                      if (
                        !subItem.subItemlevel2?.some((item) => item.visibility) &&
                        subItem.visibility
                      ) {
                        return (
                          <li
                            className='m-side-navigation__menuItem -sub-menu'
                            role='none'
                            key={subItem.sidebarKey}
                            onClick={() =>
                              handleMenuClick(false, subItem.trackEventData)
                            }
                          >
                            <Link
                              to={subItem.url}
                              role='menuitem'
                              className='m-side-navigation__link'
                              tabIndex={-1}
                            >
                              <span>{icon}</span>
                              <span className='m-side-navigation__label'>
                                {i18next.t(subItem.title)}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                      if (subItem.subItemlevel2) {
                        return (
                          <li
                            className={`m-side-navigation__menuItem -sub-menu`}
                            key={subItem.sidebarKey}
                            role='none'
                          >
                            <button
                              type='button'
                              className='m-side-navigation__group sub-menu-btn --nopadding'
                              aria-haspopup='true'
                              role='menuitem'
                            >
                              <Link
                                to={subItem.url}
                                role='menuitem'
                                className='m-side-navigation__link'
                                onClick={() =>
                                  handleMenuClick(false, subItem.trackEventData)
                                }
                              >
                                {icon}
                                <span className='m-side-navigation__label'>
                                  {i18next.t(subItem.title)}
                                </span>
                              </Link>
                              <i
                                className={`a-icon arrow boschicon-bosch-ic-${
                                  subItem.displayOptions ? 'down' : 'up'
                                }`}
                                title='down'
                                onClick={() => onSubmenuClicked(item, 2, subItem)}
                              />
                            </button>
                            <ul
                              className='m-side-navigation__menuSubitems'
                              role='menu'
                            >
                              {subItem.subItemlevel2.map((subLevel2Item) => {
                                const translateTitle = (title) => {
                                  return i18next.t(title);
                                };

                                const icon = { ...subLevel2Item?.icon };

                                if (subLevel2Item?.icon?.props) {
                                  icon.props = { ...subLevel2Item?.icon?.props };
                                  icon.props.title = translateTitle(
                                    subLevel2Item?.icon?.props.title
                                  );
                                }
                                return (
                                  subItem.displayOptions &&
                                  subItem.visibility && (
                                    <li
                                      className='m-side-navigation__menuItem -sub-menu'
                                      role='none'
                                      key={subLevel2Item.sidebarKey}
                                      onClick={() =>
                                        handleMenuClick(
                                          false,
                                          subLevel2Item.trackEventData
                                        )
                                      }
                                    >
                                      <Link
                                        to={subLevel2Item.url}
                                        role='menuitem'
                                        className='m-side-navigation__link'
                                      >
                                        {icon}
                                        <span className='m-side-navigation__label'>
                                          {i18next.t(subLevel2Item.title)}
                                        </span>
                                      </Link>
                                    </li>
                                  )
                                );
                              })}
                            </ul>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </li>
              );
            }
            return null;
          })}
        </ul>
        {/* Commenting as per MS-16579*/}
        {/* <Link role='menuitem' className='m-side-navigation__link'>
          <div className='switch__market__link'>
            {<i className='a-icon boschicon-bosch-ic-up-down' title='switch' />}
            <span
              className='m-side-navigation__label -size-m'
              onClick={() => linkBuyerAccount()}
            >
              Switch to Marketplace
            </span>
          </div>
        </Link> */}
      </nav>
    </div>
  );
};
export default SideNavigation;
