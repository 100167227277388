import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { getCurrencyFormatWithIsoCode } from '../product-detail';
import CategoryHighlighter from '../../category-highlight';
import { convertUTCToLocalDateTime } from '../../../../utils/date-time-utils';
import capitalizeText from '../../../../utils/capitalize-text';
import { capitalizeFirstLetter } from '../../../../utils';
import variables from '../../../../variables.module.scss';
import { orderStatus as orderStatusUtils } from '../../../../shared/shared-function/helperFunctions';
import './index.scss';
import idConstants from '../../../../data/idConstants';

export const OrderOverview = ({ currentOrderStage }) => {
  let {
    orderDescription = {},
    billingAddress: userBillingAddress = {},
    shippingAddress: shippingAddress,
    pricingPolicies = []
  } = useSelector((state) => state.OrderSummaryReducer);
  const getProductDocDetail = useSelector((state) => state.ProductDetailReducer);
  const [data, setData] = useState([]);
  const [filteredPricingPolicies, setFilteredPricingPolicies] = useState(null);
  const isTransformCategory = orderDescription?.productDetailsDto?.category?.id === idConstants.transform.categoryId;

  useEffect(() => {
    if (getProductDocDetail?.productDocDetail) {
      setData(
        getProductDocDetail?.productDocDetail.filter((document) =>
          [
            idConstants.documentType.certificateDocument,
            idConstants.documentType.termsAndConditionsDocument,
            idConstants.documentType.returnAndReplacementPolicyDocument,
            idConstants.documentType.refundPolicyDocument,
            idConstants.documentType.userManualDocument
          ].includes(document.docTypeId)
        )
      );
    }
  }, [getProductDocDetail]);

  useEffect(() => {
    if (pricingPolicies?.length > 0) {
      const pricingPoliciesDetails = pricingPolicies?.find(
        (planDetails) =>
          planDetails.planId === orderDescription?.productOrderDto?.policyId
      );
      setFilteredPricingPolicies(pricingPoliciesDetails);
    }
  }, [pricingPolicies, orderDescription]);

  if (Object.keys(orderDescription)?.length === 0) return null;

  const showPaymentStatus =
    currentOrderStage.status === orderStatusUtils.ORDER_REJECTED ||
    currentOrderStage.status === orderStatusUtils.CONFIRMED_ORDER_CANCELLED ||
    currentOrderStage.status === orderStatusUtils.ORDER_CANCELLED ||
    currentOrderStage.status === orderStatusUtils.REJECTED_WITH_POD ||
    currentOrderStage.status === orderStatusUtils.BUYER_REJECTED;

  const getBillingAddress = () => {
    const addressObj = userBillingAddress?.shippingAddress;
    let billingAddress = '';
    if (addressObj?.addressLine1) {
      billingAddress += addressObj?.addressLine1 + ', ';
    }
    if (addressObj?.addressLine2) {
      billingAddress += addressObj?.addressLine2 + ', ';
    }
    if (addressObj?.cityName) {
      billingAddress += addressObj?.cityName + ', ';
    }
    if (addressObj?.districtName) {
      billingAddress += addressObj?.districtName + ', ';
    }
    if (addressObj?.stateCode) {
      billingAddress += addressObj?.stateCode + ', ';
    }
    if (addressObj?.countryName) {
      billingAddress += addressObj?.countryName + ', ';
    }
    if (addressObj?.postalCode) {
      billingAddress += addressObj?.postalCode + '. ';
    }
    if (billingAddress)
      return billingAddress;
    else
      return i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOT_APPLICABLE');
  };

  return (
    <div className='order-overview-container'>
      <div className='order-overview'>
        <div className='order-overview__head order-overview-container'>
          <span className='highlight -mobile'>
            {i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.ORDER_ID')}:{' '}
            {isTransformCategory ? orderDescription?.productOrderDto?.productOrderId : orderDescription?.productOrderDto?.orderId}
          </span>
          <span className='-size-s order-overview__head__order-date'>
            <CategoryHighlighter
              categoryColor={variables.badgeMainBg}
              category={` ${i18next.t(
                'DYNAMIC_FORM.SHARED.ORDERED_ON'
              )}: ${convertUTCToLocalDateTime(orderDescription?.orderSubscriptionMap?.orderDate)}`}
            />
          </span>
        </div>
        <div className='order-overview__body'>
          {Object.keys(userBillingAddress)?.length > 0 ? (
            <div className='order-overview__body__address'>
              <div style={{ marginBottom: '1rem' }}>
                {isTransformCategory &&
                  <div className='names-container'>
                    <div>
                      <p className='text-bold'>{i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.USER_NAME')}</p>
                      <p>{userBillingAddress.userFirstName} {userBillingAddress.userLastName}</p>
                    </div>
                    <div>
                      <p className='text-bold'>{i18next.t('SHARED.COMPANY_NAME')}</p>
                      <p>{userBillingAddress.companyName}</p>
                    </div>
                  </div>
                }
                <p className='text-bold'>
                  {capitalizeFirstLetter(
                    i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.BILLING_ADDRESS')
                  )}
                </p>
                <p>{getBillingAddress()}</p>
                <div className='email-container'>
                  <p>{userBillingAddress?.userEmailId}</p>
                  <p>
                    {userBillingAddress?.shippingAddress?.contactNumber ||
                      userBillingAddress?.shippingAddress?.alternateNo}
                  </p>
                  <p>{isTransformCategory && userBillingAddress?.userTelephoneNo}</p>
                </div> 
              </div>
              {orderDescription?.category?.displayName ===
                idConstants.hardware.productCategoryName &&
                Object.keys(shippingAddress)?.length > 0 && (
                  <div style={{ marginBottom: '1rem' }}>
                    <p className='text-bold'>
                      {capitalizeFirstLetter(
                        i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.SHIPPING_ADDRESS')
                      )}
                    </p>
                    <p>{`${
                      shippingAddress?.houseNumber +
                      ', ' +
                      shippingAddress?.street +
                      ', ' +
                      shippingAddress?.city +
                      ', ' +
                      shippingAddress?.state +
                      ', ' +
                      shippingAddress?.country +
                      ', ' +
                      shippingAddress?.postalCode
                    }`}</p>
                  </div>
                )}
              {/*commenting the code as paymentMode has to be changed to payment type but still need some Confirmation  */}
              {/* <p>
                <span className='text-bold'>
                  {capitalizeFirstLetter(i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.PAYMENT_METHOD'))}{' '}
                  :
                </span>{' '}
                {orderDescription?.paymentMode}
              </p> */}
            </div>
          ) : null}
          <hr className='a-divider a-divider--vertical' />
          <div className='order-overview__body__summary'>
            <p className='order-overview__body__flex'>
              <p className='payment-status'>
                <span className='text-bold'>
                  {capitalizeFirstLetter(i18next.t('ORDER.UNIT_PRICE'))}
                </span>
                {filteredPricingPolicies && (
                  <span>
                    {' '}
                    {parseFloat(filteredPricingPolicies?.planCost).toLocaleString(
                      `en-${filteredPricingPolicies?.currency?.currencyCode?.slice(0, 2)}`,
                      getCurrencyFormatWithIsoCode(
                        filteredPricingPolicies?.currency?.currencyCode
                      )
                    )}{' '}
                  </span>
                )}
              </p>
              <div className='margin_top_bottom'>
                <p className='payment-status'>
                  <span className='text-bold'>
                    {capitalizeFirstLetter(
                      i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.ORDER_QUANTITY')
                    )}
                  </span>
                  <span> {orderDescription?.productOrderDto?.cartItemCount} </span>
                </p>
              </div>
              <div className='margin_top_bottom'>
                <p className='order-overview__body__summary--flex item-grand-total'>
                  <span>
                    {' '}
                    {capitalizeFirstLetter(
                      i18next.t('DYNAMIC_FORM.SHARED.GRAND_TOTAL')
                    )}{' '}
                    :{' '}
                  </span>
                  {filteredPricingPolicies &&
                    orderDescription?.productOrderDto?.cartItemCount &&
                    filteredPricingPolicies?.currency?.currencyCode && (
                      <span>
                        {parseFloat(
                          filteredPricingPolicies?.planCost *
                            orderDescription?.productOrderDto?.cartItemCount
                        ).toLocaleString(
                          `en-${filteredPricingPolicies?.currency?.currencyCode?.slice(0, 2)}`,
                          getCurrencyFormatWithIsoCode(
                            filteredPricingPolicies?.currency?.currencyCode
                          )
                        )}
                      </span>
                    )}
                </p>
                <p className='order-overview__body__summary__tax-text'>
                  **
                  {i18next.t(
                    'ORDER_MANAGEMENT.ORDER_OVERVIEW.TAXES_AND_SHIPPING_CHARGES_INCLUDED'
                  )}
                </p>
              </div>
              {!showPaymentStatus && (
                <p
                  className={
                    'order-overview__body__summary--flex item-grand-total payment-type'
                  }
                >
                  <span>
                    {capitalizeFirstLetter(
                      i18next.t('DYNAMIC_FORM.SHARED.PAYMENT_STATUS')
                    )}{' '}
                    :
                  </span>
                  <span>
                    {orderDescription?.orderSubscriptionMap?.paymentStatus
                      ? capitalizeText(
                          orderDescription?.orderSubscriptionMap?.paymentStatus
                        )
                      : 'Open'}
                  </span>
                </p>
              )}
            </p>
          </div>
          <hr className='a-divider a-divider--vertical' />
          <div className='order-overview__body__documents'>
            <div>
              <p className='text-bold'>
                {i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.DOCUMENTS')}
              </p>
              <div className='order-overview__body__documents__list'>
                {data?.length === 0 ? (
                  <p>{i18next.t('ORDER_MANAGEMENT.ORDER_OVERVIEW.NO_DOCUMENTS')}.</p>
                ) : (
                  data?.map((item) => (
                    <div
                      className='order-overview__body__documents__list--item'
                      key={item.index}
                    >
                      <span className='a-link'>
                        <a
                          href={item?.blobFileUrl}
                          className='product-documents'
                          target='_blank'
                          rel='noreferrer'
                          download
                        >
                          {' '}
                          <i
                            className={`a-icon boschicon-bosch-ic-document-${'pdf'}`}
                          />{' '}
                        </a>{' '}
                      </span>
                      <span className='doc-name'>{item?.documentName}</span>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOverview;
