// Utils
import { getRegionObject } from './get-region-details';

export const getTenantList = (tenantCategoryMapping) => {
  if (tenantCategoryMapping?.length > 0) {
    const tenantOptions = tenantCategoryMapping
      .filter(
        (item) =>
          getRegionObject().regionCode.includes(item.tenantInfo.regionCode) &&
          item.tenantInfo.sellerTenantId === process.env.REACT_APP_SELLER_TENANT_ID
      )
      ?.map((item) => ({
        label: item.tenantInfo.tenantName,
        value: item.tenantInfo.tenantId
      }));
    return tenantOptions;
  }
};
