
// Axios
import axios from 'axios';

// Configuration
import { ORDER_FULFILLMENT_SERVICE_CAPABILITIES, BASE_URL, ORDER_FULFILLMENT_SERVICE, CAPABILITIES, VERSION } from './configuration';

// Action
import { userPayloadData } from './api/OrderSummaryAction';

// Endpoint
import { ORDER_FULFILLMENT_ENDPOINT } from './configuration/order-fullfillment-service';

// Libs
import i18next from 'i18next';

// Data
import idConstants from '../data/idConstants';

export const ORDER_FULFILLMENT_CAPABILITIES_URL = `${BASE_URL}/${ORDER_FULFILLMENT_SERVICE}/${CAPABILITIES}/${VERSION}`;

export const getOrdersCount = (dispatch, categoryId, tenantId, startDate, endDate) => {

  let productCategoryId;
  let selectedTenant;

  if (tenantId && tenantId !== idConstants.allTenants) {
    selectedTenant = tenantId;
  }
  if (categoryId && categoryId !== idConstants.all) {
    productCategoryId = categoryId;
  }

  const params = {
    productCategroyId: productCategoryId,
    tenantId: selectedTenant,
  };

  if (startDate && startDate !== idConstants.all) {
    params.startDate = startDate;
    params.endDate = endDate;
  }

  return axios({
    method: 'GET',
    url:
      `${ORDER_FULFILLMENT_SERVICE_CAPABILITIES.ORDER_COUNT}`,
    data: {},
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then((response) => {
    return response.data;
  })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};

export const confirmSellerApproval = (productOrderDetailId, orderDescription) => {
  return (dispatch) => {
    const data={
      orderStatus: i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.COMPLETED').toUpperCase(),
      comment: '',
      blobFileUrl: '',
      productOrderTrackingDetailsDTO: {
        logisticsName: '',
        lrNumber: '',
        trackingUrl: '',
        expectedDeliveryDate: ''
      }
    };
    dispatch({
      type: 'SET_LOADER',
      payload: true
    });
    axios
      .put(`${ORDER_FULFILLMENT_CAPABILITIES_URL+ORDER_FULFILLMENT_ENDPOINT.CHANGE_ORDER_STATUS}/${productOrderDetailId}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data,
        ...userPayloadData(orderDescription)
      })
      .then((response) => {
        dispatch({
          type: 'CHANGE_ORDER_STATUS',
          payload: response.data
        });
        dispatch({
          type: 'SET_LOADER',
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: 'SET_LOADER',
          payload: false
        });
      });
  };
};