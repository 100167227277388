import idConstants from '../../data/idConstants';

const CDN_LINK = `${process.env.REACT_APP_CDN_LINK}/assets/img`;

const images = {
  addIcon: `${CDN_LINK}/add.svg`,
  addApiImg: `${CDN_LINK}/seller/add-api.png`,
  abortFrame: `${CDN_LINK}/admin/abort-frame.svg`,
  addIconFrame: `${CDN_LINK}/seller/add-frame.svg`,
  addLink: `${CDN_LINK}/seller/add-link.svg`,
  addProduct: `${CDN_LINK}/seller/add-product.svg`,
  alertError: `${CDN_LINK}/alert-error.svg`,
  alertErrorBlack: `${CDN_LINK}/seller/alert-error-black.svg`,
  alertErrorWhite: `${CDN_LINK}/alert-error-white.svg`,
  alertSuccessGreen: `${CDN_LINK}/admin/alert-success-filled.svg`,
  alertSuccessOrange: `${CDN_LINK}/admin/alert-success-filled-orange.svg`,
  alertSuccess: `${CDN_LINK}/alert-success.svg`,
  api: {
    thumbnail: `${CDN_LINK}/api-thumbnail.png`,
    coverImg: `${CDN_LINK}/cover-image.jpg`
  },
  approvedExpression: `${CDN_LINK}/approved-expression.png`,
  arrowDown: `${CDN_LINK}/seller/arrow-down.svg`,
  backgroundDashboard: `${CDN_LINK}/seller/background-dashboard.png`,
  backIcon: `${CDN_LINK}/seller/back-left.svg`,
  boschLogo: `${CDN_LINK}/bosch-logo-only.svg`,
  boschStripSmall: `${CDN_LINK}/seller/supergraphic-topbar.jpg`,
  browseIcon: `${CDN_LINK}/seller/browse-icon.png`,
  buyerIcon: `${CDN_LINK}/seller/buyer.svg`,
  calenderIcon: `${CDN_LINK}/calendar.svg`,
  checkGrey: `${CDN_LINK}/check-grey.svg`,
  checkMarkBold: `${CDN_LINK}/checkmark-bold.svg`,
  closeIcon: `${CDN_LINK}/close.svg`,
  dashBoardImg: `${CDN_LINK}/dashboard-image.jpg`,
  deleteIcon: `${CDN_LINK}/delete.svg`,
  boschIcons: {
    peopleIcon: `${CDN_LINK}/people.svg`,
    appIcon: `${CDN_LINK}/directory-cloud.svg`
  },
  documentDoc: `${CDN_LINK}/document-doc.svg`,
  externallink: `${CDN_LINK}/seller/externallink.svg`,
  filter: `${CDN_LINK}/filter.svg`,
  filterIcon: `${CDN_LINK}/filter-icon.svg`,
  firstApiImg: `${CDN_LINK}/first-api.svg`,
  forwardIcon: `${CDN_LINK}/forward-icon.svg`,
  imagery: `${CDN_LINK}/seller/imagery.svg`,
  infoIcon: `${CDN_LINK}/info.svg`,
  infoBgBlueIcon: `${CDN_LINK}/seller/info-bg-blue.svg`,
  maleWorkingOnComputer: `${CDN_LINK}/male-working-on-computer.svg`,
  maleWorkingOnComputerBehindBlue: `${CDN_LINK}/male-working-on-computer-behind-blue.svg`,
  mobilityTitleLogo: `${CDN_LINK}/mobility-title-logo.png`,
  myAccountImg: `${CDN_LINK}/my-account.png`,
  paperClip: `${CDN_LINK}/paper-clip.svg`,
  pdfBoschLogo: `${CDN_LINK}/pdf-bosch-logo.png`,
  startPlay: `${CDN_LINK}/start-play-frame.svg`,
  boschStrip: `${CDN_LINK}/topbar.png`,
  thumbnail: `${CDN_LINK}/thumbnail.svg`,
  upDown: `${CDN_LINK}/up-down.svg`,
  errorImage: `${CDN_LINK}/error-image.svg`,
  checkMark: `${CDN_LINK}/seller/checkmark.svg`,
  checkIcon: `${CDN_LINK}/seller/check-mark.svg`,
  cloudInfra: `${CDN_LINK}/seller/cloud-infra-icon.svg`,
  contractDocumentIcon: `${CDN_LINK}/seller/contract-document.svg`,
  apiThrottling: {
    throttlingSaveBg: `${CDN_LINK}/seller/throttling-save-bg.jpg`,
    throttlingCardBg: `${CDN_LINK}/seller/throttling-card-bg.jpg`,
    conditionGroup: `${CDN_LINK}/seller/condition-group.svg`
  },
  defaultThumbnail: `${CDN_LINK}/default-thumbnail.jpg`,
  descriptionIcon: `${CDN_LINK}/seller/description.svg`,
  documentPdf: `${CDN_LINK}/seller/document-pdf.svg`,
  documentSwaggerIcon: `${CDN_LINK}/seller/document-swagger.svg`,
  boschSystemIcon: `${CDN_LINK}/seller/bosch-systems.svg`,
  downloadIcon: `${CDN_LINK}/seller/download-icon.svg`,
  editIcon: `${CDN_LINK}/seller/edit.svg`,
  faqBanner: `${CDN_LINK}/seller/faq-banner.png`,
  generalFaq: `${CDN_LINK}/seller/faq-icon.svg`,
  faqIcon: `${CDN_LINK}/seller/faq-sidebar-icon.svg`,
  groupAddImage: `${CDN_LINK}/seller/group-add.svg`,
  jsonRPCImg: `${CDN_LINK}/seller/json-rpc.png`,
  manualImg: `${CDN_LINK}/seller/manual.png`,
  monitor: `${CDN_LINK}/seller/monitor.svg`,
  accessDenied: `${CDN_LINK}/seller/access-denied.svg`,
  noResultIcon: `${CDN_LINK}/seller/noresult.svg`,
  pending: `${CDN_LINK}/seller/pending.png`,
  popupIcon: `${CDN_LINK}/seller/popup-icon.jpg`,
  rejected: `${CDN_LINK}/seller/rejected.png`,
  rejectedExpression: `${CDN_LINK}/seller/rejected-expression.png`,
  restApiImg: `${CDN_LINK}/seller/rest-api.png`,
  sellerIcon: `${CDN_LINK}/seller/seller-icon.svg`,
  soapApiImg: `${CDN_LINK}/seller/soap-api.png`,
  successIcon: `${CDN_LINK}/seller/success-icon.svg`,
  swaggerImg: `${CDN_LINK}/seller/swagger.png`,
  viewProfile: `${CDN_LINK}/seller/view-profile.png`,
  webServicesImg: `${CDN_LINK}/seller/web-services.png`,
  welcomeImage: `${CDN_LINK}/seller/welcome-page-banner.svg`,
  loginSellerProfileImg: `${CDN_LINK}/seller/login-seller-profile-image.svg`,
  loginSalesRepProfileImg: `${CDN_LINK}/seller/login-sales-rep-profile-image.svg`,
  loginLandingImage: `${CDN_LINK}/seller/login-landing-image.svg`,
  checkMarkWhite: `${CDN_LINK}/seller/checkmark-white.svg`,
  userRemoveWhite: `${CDN_LINK}/seller/user-remove-white.svg`,
  sellerSwitch: `${CDN_LINK}/seller/seller-switch.svg`,
  singleKeyIDImage: `${CDN_LINK}/seller/logo-single-key-id.svg`,
  closeRed: `${CDN_LINK}/seller/close-red.svg`,
  copy: `${CDN_LINK}/seller/copy.svg`,
  watchOn: `${CDN_LINK}/admin/watch-on.svg`,
  watchOff: `${CDN_LINK}/admin/watch-off.svg`,
  pin: `${CDN_LINK}/seller/pin.svg`,
  thumbnailImg: {
    [idConstants.api.categoryId]: `${CDN_LINK}/api-thumbnail.png`,
    [idConstants.best.saas.categoryId]: `${CDN_LINK}/best/tools-saas-logo.svg`,
    [idConstants.best.paas.categoryId]: `${CDN_LINK}/best/tools-paas-logo.svg`,
    [idConstants.best.iaas.categoryId]: `${CDN_LINK}/best/tools-iaas-logo.svg`,
    [idConstants.best.preDefinedWorkflow.categoryId]:
      `${CDN_LINK}/best/tools-pre-defined-workflow-logo.svg`,
    [idConstants.best.methods.categoryId]: `${CDN_LINK}/best/tools-methods-logo.svg`,

    [idConstants.hardware.categoryId]: `${CDN_LINK}/hardware-thumbnail.svg`,
    [idConstants.solutions.categoryId]: `${CDN_LINK}/solutions-thumbnail.png`,
    [idConstants.workPackage.categoryId]: `${CDN_LINK}/professional-services-thumbnail.svg`,
    [idConstants.data.categoryId]: `${CDN_LINK}/data-thumbnail.png`,
    [idConstants.professionalServices.categoryId]: `${CDN_LINK}/professional-services-thumbnail.svg`,
    [idConstants.sdk.categoryId]: `${CDN_LINK}/sdk-services-thumbnail.svg`,
    [idConstants.transform.categoryId]: `${CDN_LINK}/transform-thumbnail.svg`,
  },
  usageIcon: `${CDN_LINK}/seller/chart-average.svg`,
  priceIcon: `${CDN_LINK}/seller/money-currency.svg`,
  clockIcon: `${CDN_LINK}/seller/clock.svg`,
  documentView: `${CDN_LINK}/seller/document-view.svg`,
  pricingPendingApproval: `${CDN_LINK}/admin/pricing-pending-approval.svg`,
  globeIcon: `${CDN_LINK}/seller/globe-icon.svg`,
  groupIcon: `${CDN_LINK}/seller/group-icon.svg`,
  salesRep: `${CDN_LINK}/seller/sales-rep.svg`,
  plan: {
    exclusiveRibbon: `${CDN_LINK}/plan-exclusive-ribbon.svg`
  }
};
export default images;
