import 'moment-timezone';

export const getColourNode = (data) => {
  let currentNode = 0;
  for (const colorNode of data) {
    if (colorNode.statusUpdateTime) {
      currentNode = data.indexOf(colorNode);
    }
  }
  return { currentNode, maxLength: data.length };
};

export const changeDateFormat = (
  date,
  options = {
    timeZone: 'Asia/Kolkata',
    dateStyle: 'full',
    timeStyle: 'medium'
  },
  format = 'en-In'
) => {
  const isoDate = date;
  const indianDate = new Date(isoDate).toLocaleString(format, options);
  return indianDate;
};

export const orderStatus = {
  ORDER_CREATED: 'ORDER_CREATED',
  REJECT: 'REJECT',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  READY_TO_SHIP: 'READY_TO_SHIP',
  INTRANSIT: 'INTRANSIT',
  IN_PROGRESS: 'IN_PROGRESS',
  DELIVERED: 'DELIVERED',
  BUYER_APPROVED: 'BUYER_APPROVED',
  NEW: 'NEW',
  ONGOING_ORDER: 'ONGOING_ORDER',
  REPLACEMENT: 'REPLACEMENT',
  CANCELLED: 'CANCELLED',
  RETURN_AND_REFUNDED: 'RETURN_AND_REFUNDED',
  REJECTED_WITH_POD: 'REJECTED_WITH_POD',
  ORDER_REJECTED: 'ORDER_REJECTED',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  CONFIRMED_ORDER_CANCELLED: 'CONFIRMED_ORDER_CANCELLED',
  BUYER_REJECTED: 'BUYER_REJECTED',
  ORDER_HISTORY: 'ORDER_HISTORY',
  COMPLETED: 'COMPLETED',
};

export const handleMaxCountText = (maxCount, value, isNumber) => {
  let valueLength;
  if (isNumber) {
    valueLength = value;
  } else {
    valueLength = value?.toString().length;
  }

  if (valueLength > 0) {
    const count = Number(maxCount) - Number(valueLength);
    return count > 0 ? count : 0;
  }
  return maxCount;
};

export const getUploadedDoc = (data, removeFile, cb) => {
  for (const element of data.steps) {
    if (element?.name === 'prodBasicInfo') {
      for (const attribute of element.attributes) {
        if (attribute?.name === 'termsAndConditionsAttachment' && attribute?.value) {
          return attribute?.value[0];
        }
      }
    }
  }

  return null;
};

export const toLowercaseReplaceSpace = (text) => {
  return text?.toLowerCase().replace(/\s+/g, '-');
};
