import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptUserAgreementTerms,
  generateAAAaccessToken,
  generateDCSAccessToken,
  logout,
  handleAAASignupLogin,
  invalidateRefreshToken,
  handleDCSLoginSignup
} from '../../../actions/LoginAction';
import HeaderLogin from '../../BaseLayout/HeaderLogin';
import './style.scss';
import AgreementModal from '../../../components/Shared/Utils/Modal/AgreementModal';
import { createUserEntry } from '../../../actions/ProfileAction';
import * as Constants from '../../../actions/configuration';
import ConfirmModal from '../../Shared/Utils/Modal/ConfirmationModal';
import Loader from '../../Shared/Utils/Loader';
import idConstants from '../../../data/idConstants';
import store from '../../../store';

export default function AuthCallback(props) {
  const dispatchRedux = useDispatch();

  const reduxStore = useSelector((state) => state);
  const validToken = reduxStore?.loginReducer?.validToken;
  const isRegistrationCheckDone =
    reduxStore?.CompanyDetailsReducer?.isRegistrationCheckDone;
  const companyDetailsError = reduxStore?.CompanyDetailsReducer?.companyDetailsError;
  const checkUserAgreement = reduxStore?.loginReducer?.checkUserAgreement;
  const userCompanyStatus = reduxStore?.CompanyDetailsReducer?.userCompanyStatus;
  const tokenInvalidated = reduxStore?.loginReducer?.tokenInvalidated;
  const userDeregistered = reduxStore?.ProfileReducer?.userDeregistered;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmationModalLoader, setConfirmationModalLoader] = useState(false);
  const [showPrivacyPoliciesModal, setShowPrivacyPoliciesModal] = useState(false);
  const [privacyPoliciesPdfUrl, setPrivacyPoliciesPdfUrl] = useState('');
  const [privacyPoliciesAgreementMasterData, setPrivacyPoliciesAgreementMasterData] =
    useState('');
  const [isSwitchMessage, setSwitchMessage] = useState(false);
  const [createUserCall, setCreateUserCall] = useState(false);

  const getAAAAuthCode = () => {
    const callbackParams = new URLSearchParams(props.location.search);
    localStorage.setItem(idConstants.tokens.aaaCode, callbackParams.get('code'));
    if (callbackParams.get(idConstants.tokens.aaaVerifier)) {
      localStorage.setItem(
        idConstants.tokens.aaaVerifier,
        callbackParams.get(idConstants.tokens.aaaVerifier)
      );
    }
    dispatchRedux(generateAAAaccessToken(callbackParams.get('code')));
    if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') handleDCSLoginSignup();
    else setCreateUserCall(true);
  };

  const getDCSAuthCode = () => {
    const callbackParams = new URLSearchParams(props.location.search);
    localStorage.setItem(idConstants.tokens.dcsCode, callbackParams.get('code'));
    dispatchRedux(generateDCSAccessToken(callbackParams.get('code')));
    setCreateUserCall(true);
  };

  useEffect(() => {
    if (store.getState().loginReducer.countryCodeFlagSet && createUserCall) {
      setCreateUserCall(false);
      dispatchRedux(createUserEntry());
    }
  }, [store.getState().loginReducer.countryCodeFlagSet]);

  const dependencyArrayCheckAgreement = [
    validToken,
    isRegistrationCheckDone,
    companyDetailsError,
    checkUserAgreement,
    userCompanyStatus
  ];
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false')
    dependencyArrayCheckAgreement.push(
      localStorage.getItem(idConstants.tokens.dcsJwtToken)
    );

  useEffect(() => {
    const toggledIf =
      Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false'
        ? validToken &&
          checkUserAgreement &&
          localStorage.getItem(idConstants.tokens.dcsJwtToken)
        : validToken && checkUserAgreement;

    if (
      toggledIf &&
      (isRegistrationCheckDone || companyDetailsError) &&
      (!userCompanyStatus.areCompanyDetailsCaptured ||
        (userCompanyStatus.areCompanyDetailsCaptured &&
          (!checkUserAgreement.some(
            (userAgreement) =>
              idConstants.documentTypeIds.privacyPolicy ===
              userAgreement.documentTypeId
          ) ||
            checkUserAgreement.some(
              (userAgreement) =>
                idConstants.documentTypeIds.privacyPolicy ===
                  userAgreement.documentTypeId && userAgreement.accepted
            ))))
    ) {
      props.history.push('/dashboard');
    }
  }, dependencyArrayCheckAgreement);

  const dependencyArrayUserCompanyStatus = [
    validToken,
    userCompanyStatus,
    checkUserAgreement
  ];
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false')
    dependencyArrayUserCompanyStatus.push(
      localStorage.getItem(idConstants.tokens.dcsJwtToken)
    );

  useEffect(() => {
    const toggledIf =
      Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false'
        ? validToken &&
          localStorage.getItem(idConstants.tokens.dcsJwtToken) &&
          userCompanyStatus &&
          userCompanyStatus.areCompanyDetailsCaptured &&
          checkUserAgreement
        : validToken &&
          userCompanyStatus &&
          userCompanyStatus.areCompanyDetailsCaptured &&
          checkUserAgreement;
    if (toggledIf) {
      const privacyPolicy = checkUserAgreement.find(
        (userAgreement) =>
          idConstants.documentTypeIds.privacyPolicy === userAgreement.documentTypeId
      );
      if (privacyPolicy && !privacyPolicy.accepted) {
        setShowPrivacyPoliciesModal(true);
        setPrivacyPoliciesPdfUrl(
          `${process.env.REACT_APP_STAAS_CDN_URL}${privacyPolicy.blobFileUrl?.substring(privacyPolicy.blobFileUrl?.indexOf('/') + 1)}`
        );
        setPrivacyPoliciesAgreementMasterData(privacyPolicy);
      }
    }
  }, dependencyArrayUserCompanyStatus);

  useEffect(() => {
    if (tokenInvalidated) {
      props.history.push('/login');
      dispatchRedux({
        type: 'TOKEN_INVALIDATED',
        payload: false
      });
    }
  }, [tokenInvalidated]);

  useEffect(() => {
    if (userDeregistered?.error === idConstants.errorCodes.deregError) {
      setOpenConfirmModal(true);
    }
  }, [userDeregistered]);

  useEffect(() => {
    if (
      window.location.pathname.split('/')[1] ===
      Constants.CALLBACK_PARAM.AAA_CALLBACK
    ) {
      getAAAAuthCode();
    }
  }, []);

  useEffect(() => {
    if (
      Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false' &&
      window.location.pathname.split('/')[1] ===
        Constants.CALLBACK_PARAM.DCS_CALLBACK
    ) {
      getDCSAuthCode();
    }
  }, []);

  useEffect(() => {
    if (
      window.location.pathname.split('/')[1] ===
      Constants.CALLBACK_PARAM.SWITCH_CALLBACK
    ) {
      setSwitchMessage(true);
      handleAAASignupLogin();
    }
  }, []);

  useEffect(() => {
    if (
      window.location.pathname.split('/')[1] ===
      Constants.CALLBACK_PARAM.DEREGISTRATION_CALLBACK
    ) {
      window.location.href = process.env.REACT_APP_SKID_DELETE;
    }
  }, []);

  useEffect(() => {
    if (
      window.location.pathname.split('/')[1] ===
      Constants.CALLBACK_PARAM.FEDERATED_CALLBACK
    ) {
      dispatchRedux(invalidateRefreshToken());
    }
  }, []);

  const handleAgreePrivacyPolicies = () => {
    const privacyDTO = {};
    const privacyPolicy = [];
    privacyDTO.docMetaInfoId = privacyPoliciesAgreementMasterData.docMetaInfoId;
    privacyDTO.documentTypeId = privacyPoliciesAgreementMasterData.documentTypeId;
    privacyDTO.userConsentNeeded =
      privacyPoliciesAgreementMasterData.userConsentNeeded;
    privacyDTO.accepted = true;
    privacyPolicy.push(privacyDTO);
    dispatchRedux(acceptUserAgreementTerms(privacyPolicy));
  };

  const handleDeclineAgreements = () => {
    dispatchRedux(logout());
    setShowPrivacyPoliciesModal(false);
  };

  const handleLogOutUser = () => {
    setOpenConfirmModal(false);
    setConfirmationModalLoader(true);
    dispatchRedux(logout());
  };

  return (
    <div className='redirect-message'>
      <HeaderLogin />
      <span className='redirect-message-span'>
        {isSwitchMessage
          ? 'Please Wait while we switch you to the SELLER Marketplace !'
          : 'Redirecting, please wait...'}
      </span>
      {showPrivacyPoliciesModal && (
        <AgreementModal
          title='Privacy Policy'
          actionRequired={true}
          okAction={handleAgreePrivacyPolicies}
          closeAction={handleDeclineAgreements}
          pdfUrl={privacyPoliciesPdfUrl}
        />
      )}
      {confirmationModalLoader && <Loader />}
      {openConfirmModal && (
        <ConfirmModal
          modalContent=''
          heading={userDeregistered?.message}
          category={'approve'}
          callback={handleLogOutUser}
          okBtnAction={handleLogOutUser}
          cancelBtn='disable'
          noCloseIcon
          {...props}
        />
      )}
    </div>
  );
}
