export const initialPrivatePlanConfigPayload = {
  tenantId: '',
  companyId: []
};

export const initialPlanGroupNameAttribute = {
  attributeId: null,
  name: 'planGroupName',
  displayName: 'Plan Type',
  description: '',
  xpath: null,
  jsonType: null,
  attributeType: 'SINGLESELECT',
  placeHolder: 'Select preferable plan type',
  displayOrder: 1,
  isVisibleForBuyer: null,
  isElementRequired: true,
  stateChangeOnEdit: false,
  isEncryptable: false,
  validation: {
    rule: {
      required: {
        isRequired: true,
        errorCode: 'MS102',
        errorMessage: ''
      }
    }
  },
  nonEditableAttributeValidation: null,
  optionType: null,
  initialValue: null,
  value: 'Generic (default)',
  options: [
    {
      label: 'Generic (default)',
      id: 1,
      value: 'GENERIC'
    },
    {
      label: 'Private (customer specific)',
      id: 2,
      value: 'PRIVATE'
    }
  ],
  attributes: null,
  sections: [],
  hide: false
};

export const initialPrivatePlanConfigAttributes = [
  {
    attributeId: null,
    name: 'tenantId',
    displayName: 'Select Marketplace',
    description: '',
    xpath: null,
    jsonType: null,
    attributeType: 'SINGLESELECT',
    placeHolder: 'Select the preferred marketplace',
    displayOrder: 2,
    isVisibleForBuyer: null,
    isElementRequired: true,
    stateChangeOnEdit: false,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    error: '',
    nonEditableAttributeValidation: null,
    optionType: null,
    initialValue: null,
    value: '',
    options: [],
    attributes: null,
    sections: [],
    hide: true
  },
  {
    attributeId: null,
    attributeType: 'COMBINE',
    className: 'add-company-id',
    attributes: [
      {
        attributeId: null,
        name: 'companyId',
        displayName: 'Company Id',
        description: '',
        xpath: null,
        jsonType: null,
        attributeType: 'TEXT',
        placeHolder: 'Enter company id',
        displayOrder: 7,
        isVisibleForBuyer: null,
        isElementRequired: true,
        stateChangeOnEdit: false,
        isEncryptable: false,
        validation: {
          rule: {
            required: {
              isRequired: false,
              errorCode: 'MS102',
              errorMessage: ''
            },
            regex: {
              pattern: '^[0-9a-zA-Z]*$',
              errorCode: 'MS107',
              errorMessage: 'Company Id is invalid'
            },
            length: {
              min: 0,
              max: 35,
              errorCode: 'MS105',
              errorMessage: 'Required'
            }
          }
        },
        error: '',
        nonEditableAttributeValidation: null,
        optionType: null,
        initialValue: null,
        options: null,
        attributes: null,
        sections: [],
        hide: true
      },
      {
        attributeId: null,
        name: 'addCompanyId',
        displayName: '+',
        description: '',
        xpath: null,
        jsonType: null,
        attributeType: 'PRIMARY_BUTTON',
        placeHolder: '',
        displayOrder: 4,
        isVisibleForBuyer: null,
        isElementRequired: true,
        stateChangeOnEdit: false,
        isEncryptable: false,
        validation: {
          rule: {
            required: {
              isRequired: true,
              errorCode: 'MS102',
              errorMessage: ''
            }
          }
        },
        nonEditableAttributeValidation: null,
        optionType: null,
        initialValue: null,
        options: null,
        attributes: null,
        sections: [],
        hide: true
      }
    ],
    hide: true
  }
];
