// React
import React, { useState, useEffect, useRef } from 'react';

// Libs
import i18next from 'i18next';

// Component
import LightTooltip from '../../../components/Shared/Tooltip';

// Assets
import Images from '../../../assets/img';

// CSS
import './index.scss';

const CustomCell = ({ data, value, documentDetails, showThumbnail }) => {
  const textRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const thumbnailImg =
    Images.thumbnailImg[data?.categoryId] || Images.defaultThumbnail;
  const productDocument = documentDetails?.find(
    (item) => item.productId === data?.productId
  );
  const imageUrl = productDocument ? productDocument.blobFileUrl : thumbnailImg;

  const isTextOverflowing = (element) => {
    return element.scrollWidth > element.clientWidth;
  };

  useEffect(() => {
    if (textRef.current) {
      setShowTooltip(isTextOverflowing(textRef.current));
    }
  }, [value]);

  return (
    <div className='chart-grid__custom-cell'>
      {showThumbnail && (
        <img
          src={imageUrl}
          alt={i18next.t('DASHBOARD.CHART.TOP_SELLING.THUMBNAIL')}
          className='chart-grid__custom-cell__image'
        />
      )}
      {showTooltip ? (
        <LightTooltip title={value}>
          <span ref={textRef} className='chart-grid__custom-cell__text'>
            {value}
          </span>
        </LightTooltip>
      ) : (
        <span ref={textRef} className='chart-grid__custom-cell__text'>
          {value}
        </span>
      )}
    </div>
  );
};

export default CustomCell;
