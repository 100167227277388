const initialState = {
  tenantCategoryMapping: null,
  tenants: null
};

const TenantServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TENANT_CATEGORY_MAPPING':
      return Object.assign({}, state, {
        tenantCategoryMapping: action.payload
      });
    case 'TENANTS':
      return Object.assign({}, state, { tenants: action.payload });
    default:
      return state;
  }
};

export default TenantServiceReducer;
