import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { PrimaryButton, Table } from '../../../msreact-kit/packages/html-components';
import './index.scss';
import { retrieveMSCompanyDetails } from '../../../actions/ProfileAction';
import Loader from '../../Shared/Utils/Loader';
import idConstants from '../../../data/idConstants';
import SingleSelectDropdown from '../../../msreact-kit/html-components/dropDowm';

const SalesRepDashboard = (props) => {
  const dispatchRedux = useDispatch();
  const [sellerList, setSellerList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);

  const tableHeaders = [
    {
      id: 1,
      value: 'SALES_REP_TABLE.TABLE_HEADERS.SELLER_NAME',
      key: 'sellername',
      style: 'header-style'
    },
    {
      id: 2,
      value: 'SALES_REP_TABLE.TABLE_HEADERS.EMAIL_ID',
      key: 'emailid',
      style: 'header-style'
    },
    {
      id: 3,
      value: 'SALES_REP_TABLE.TABLE_HEADERS.PHONE_NUMBER',
      key: 'phonenumber',
      style: 'header-style'
    },
    {
      id: 4,
      value: 'SALES_REP_TABLE.TABLE_HEADERS.ACTION',
      key: 'action',
      style: 'header-style'
    }
  ];

  useEffect(() => {
    if (props?.sellerList) {
      setSellerList(transformArray(props?.sellerList));
    }
  }, [props?.sellerList]);

  const transformArray = (sellerList) => {
    const tempSellerList = sellerList.map((item) => ({
      id: item.userId,
      label: item.companyName
    }));
    return tempSellerList.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
  };

  const goToProductsPage = () => {
    props.history.push('/dashboard/myproducts');
  };

  useEffect(() => {
    const savedCompany = localStorage.getItem(
      idConstants.selectedCompanyObject.selectedCompany
    );
    if (savedCompany && props?.sellerList.length > 0) {
      const parsedCompany = JSON.parse(savedCompany);
      setSelectedCompany(parsedCompany);
      onFindAction(parsedCompany);
    }
  }, [props?.sellerList]);

  const onFindAction = (selectedCompany) => {
    localStorage.setItem(
      idConstants.selectedCompanyObject.selectedCompany,
      JSON.stringify(selectedCompany)
    );
    if (selectedCompany) {
      setLoader(true);
      const companyId = props?.sellerList?.filter(
        (item) => item.userId === selectedCompany.id
      );
      dispatchRedux({
        type: 'SET_SALES_REP_COMPANY_ID',
        payload: companyId?.[0]?.companyId
      });
      localStorage.setItem(
        idConstants.salesRepSelectedCompany.selectedCompany,
        companyId?.[0]?.companyId
      );
      window.dispatchEvent(
        new Event(idConstants.selectedCompanyObject.selectedCompany)
      );
      dispatchRedux(retrieveMSCompanyDetails(selectedCompany.id)).then((response) => {
        const sellerTableData = [];
        if (response) {   
            localStorage.setItem(
              idConstants.sellerName,
              response?.userFirstName + ' ' + response?.userLastName
            );
            const tempdata = {
              sellername: (
                <div className='table-styling'>
                  <i className='a-icon boschicon-bosch-ic-my-brand-frame -size-l user-icon'></i>{' '}
                  {response?.userFirstName + ' ' + response?.userLastName}
                </div>
              ),
              emailid: response?.userEmailId,
              phonenumber: response?.userTelephoneNo,
              action: (
                <div>
                  <PrimaryButton
                    label={i18next.t('SALES_REP_TABLE.VIEW_PRODUCTS')}
                    onClick={() => goToProductsPage()}
                  />
                </div>
              )
            };
            sellerTableData.push(tempdata);
         
        }
        setTableData(sellerTableData);
        setLoader(false);
      });
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className='dashboard-container-box'>
        <div className='dashboard-user-info-box'>
          <div className='dashboard-box-user-details'>
            {' '}
            <i className='a-icon boschicon-bosch-ic-my-brand-frame -size-l user-icon'></i>
            <div className='dashboard-user-data-box'>
              <p className='welcome-text-font-size'>
                {i18next.t('LANDING_PAGE.WELCOME')}{' '}
                <span className='bold-text'>
                  {' '}
                  {localStorage.getItem('userName')}
                </span>
              </p>
              <br />
              <p className='sub-text-sales-rep'>
                <i className='a-icon-size boschicon-bosch-ic-my-brand-frame'></i>
                <p>{i18next.t('LANDING_PAGE.SALES_REPRESENTATIVE')}</p>
              </p>
            </div>
          </div>
          <div className='select-field-box'>
            <div className='select-drop-down-box'>
              <SingleSelectDropdown
                options={sellerList}
                displayName={i18next.t('SALES_REP_TABLE.DISPLAY_NAME')}
                placeHolder={i18next.t('SALES_REP_TABLE.PLACEHOLDER')}
                handleChange={(event) => {
                  setSelectedCompany(event.value);
                }}
                fieldValue={selectedCompany?.label}
                name='company select'
              />
            </div>
            <div>
              <PrimaryButton
                startIcon={
                  <i
                    className='a-icon ui-ic-search'
                    title={i18next.t('FILTER_SECTION.SEARCH_TEXT')}
                  ></i>
                }
                label={i18next.t('SALES_REP_TABLE.FIND')}
                onClick={() => onFindAction(selectedCompany)}
                disabled={
                  !selectedCompany ||
                  (typeof selectedCompany === 'object' &&
                    Object.keys(selectedCompany)?.length === 0)
                }
              />
            </div>
          </div>
        </div>

        {tableData.length > 0 && (
          <div>
            <div className='seller-list-box'>
              <p className='dashboard-box-title'>
                {i18next.t('LANDING_PAGE.LIST_OF_SELLER')} ({tableData.length})
              </p>
            </div>
            <div className='dashboard-table-header__seller_table'>
              <Table
                rowData={tableHeaders}
                data={tableData}
                hidePagination={true}
                centerAlignClassName='field-center-align'
                cssName='dashboard-table-header'
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SalesRepDashboard;
