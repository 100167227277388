import axios from 'axios';
import * as Constants from './configuration';
import { getDCSCompanyStatus } from './CompanyAction';
import { checkUserAgreementAcceptance } from './LoginAction';
import IdConstants from '../data/idConstants';

export function setBankDetails(obj) {
  return (dispatch) =>
    dispatch({
      type: 'BANK_DETAILS',
      payload: obj
    });
}

export function setBankDetailsError(obj) {
  return (dispatch) =>
    dispatch({
      type: 'BANK_DETAILS_ERR',
      payload: obj
    });
}

export function retrieveMSCompanyDetails(userId, companyId) {
  return async (dispatch) =>
    await axios({
      method: 'GET',
      url: Constants.USER_SERVICE_CAPABILITIES_ACTION.GET_COMPANY_DETAILS,
      params: {
        userId: userId,
        companyId: companyId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response?.data) {
          dispatch({
            type: 'MS_COMPANY_DETAILS',
            payload: response.data.data
          });
          return response.data.data;
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_MS_COMPANY_DETAILS',
          payload: error
        });
      });
}

export function retrieveMSCompanyDetailsForViewProfile(companyId) {
  return async (dispatch) =>
    await axios({
      method: 'GET',
      url: Constants.COMPANY.GET_MS_COMPANY_DETAILS + '&companyId=' + companyId,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response?.data) {
          dispatch({
            type: 'MS_VIEW_COMPANY_DETAILS',
            payload: response.data
          });
          return response.data;
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_MS_VIEW_COMPANY_DETAILS',
          payload: error
        });
      });
}

export function createUserEntry() {
  return async (dispatch) =>
    await axios({
      method: 'POST',
      url: Constants.REGISTER_ACTION.CREATE_USER_ENTRY,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        dispatch(getDCSCompanyStatus());
        dispatch(checkUserAgreementAcceptance());
      })
      .catch((error) => {
        if (error?.response?.data?.error === IdConstants.errorCodes.deregError) {
          dispatch({
            type: 'USER_DEREGISTERED',
            payload: error.response.data
          });
        }
      });
}
