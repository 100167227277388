// Libs
import i18next from 'i18next';

// Utils
import { getRegionObject } from '../../../utils/get-region-details';

// Data
import idConstants, { REGION } from '../../../data/idConstants';
import { activeCategoryIdInRegion } from '../../../data/appConstants';

// Action
import { getOrdersCount } from '../../../actions/order-fulfilment-service';
import { getProductsCount } from '../../../actions/MyProductsAction';
import { getCategories } from '../../../actions/CategoryManagementAction';
import { getRevenueCount, getTopSellingProducts } from '../../../actions/aggregator-service-action';
import { getDocumentDetails } from '../../../actions/document-service-action';


export const getTenantOptions = (tenantCategoryMapping) => {
  if (tenantCategoryMapping?.length > 0) {
    const tenantOptions = tenantCategoryMapping
      .filter((item) =>
        getRegionObject().regionCode.includes(item.tenantInfo.regionCode)
        && item.tenantInfo.sellerTenantId === process.env.REACT_APP_SELLER_TENANT_ID
      )
      ?.map((item) => ({
        id: item.tenantInfo.tenantId,
        label: item.tenantInfo.tenantName
      }));
    return tenantOptions;
  }
};
export const formatStatusName = (statusName) => {
  return statusName
    ?.toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Formats a Date object into a URL-encoded string in the format:"Day Month Date Year HH:MM:SS GMT±TZOffset", e.g., "Mon Oct 02 2023 15:45:30 GMT+0530"
// Returns the formatted date as a URL-encoded string.
export const dateFormater = (date) => {

  const day = date.toDateString().slice(0, 3);
  const dayOfMonth = String(date.getDate()).padStart(2, '0');
  const month = date.toDateString().slice(4, 7);
  const yearFull = date.getFullYear();


  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');


  const timezoneOffset = -date.getTimezoneOffset();
  const tzHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const tzMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
  const timezoneSign = timezoneOffset >= 0 ? '+' : '-';


  const formattedDate = `${day} ${month} ${dayOfMonth} ${yearFull} ${hours}:${minutes}:${seconds} GMT${timezoneSign}${tzHours}${tzMinutes}`;

  return formattedDate;
};

// Calculates a date range based on the input value, and formats both start and end dates. Returns an object with 'start' and 'end' dates as URL-encoded formatted strings.
// If the input value doesn't match expected cases, returns null.
export const calculateDateRange = (value) => {
  const today = new Date();
  let startDate = null;
  let endDate = null;

  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  if (value === 'current') {
    startDate = new Date(currentYear, currentMonth, 1);
    endDate = today;
  } else if (value === '0') {
    startDate = new Date(currentYear, currentMonth - 1, 1);
    endDate = new Date(currentYear, currentMonth, 0);
  } else if (value === '90') {
    startDate = new Date(currentYear, currentMonth - 3, 1);
    endDate = new Date(currentYear, currentMonth, 0);
  } else if (value === '180') {
    startDate = new Date(currentYear, currentMonth - 6, 1);
    endDate = new Date(currentYear, currentMonth, 0);
  } else if (value === idConstants.all) {
    return { start: idConstants.all, end: idConstants.all };
  }
  return {
    start: dateFormater(startDate),
    end: dateFormater(endDate),
  };
};


export const getOrderDetails = async (dispatch, categoryId, selectedTenant, startDate, endDate) => {
  try {
    const response = await getOrdersCount(dispatch, categoryId, selectedTenant, startDate, endDate);
    if (response?.length > 0) {
      const orderDetails = response?.map((item) => ({
        name: formatStatusName(item.orderStatus),
        value: item.totalCount,
      }));
      dispatch({
        type: 'SET_ORDER_DETAILS',
        payload: orderDetails,
      });
    } else {
      dispatch({
        type: 'SET_ORDER_DETAILS',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'SET_ORDER_DETAILS',
      payload: [],
    });

    return [];
  }
};

export const getProductsDetails = async (categoryId, selectedTenant, startDate, endDate, dispatch) => {
  try {
    const response = await dispatch(getProductsCount('', '', '', categoryId, selectedTenant, startDate, endDate));
    if (response?.length > 0) {
      const productDetails = response
        .filter(item => item.statusId !== idConstants.readyForPublish.statusId)
        ?.map((item) => ({
          name: formatStatusName(item.statusName),
          value: item.productCount,
        }));
      dispatch({
        type: 'SET_PRODUCT_DETAILS',
        payload: productDetails,
      });
    } else {
      dispatch({
        type: 'SET_PRODUCT_DETAILS',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'SET_PRODUCT_DETAILS',
      payload: [],
    });

    return [];
  }
};

export const getRevenueDetails = async (dispatch, categoryId, selectedTenant, startDate, endDate) => {
  try {
    const response = await getRevenueCount(dispatch, categoryId, selectedTenant, startDate, endDate);
    if (response?.length > 0) {
      const revenueDetails = response?.map((item) => {
        const dateString = item.month.includes('-')
          ? item.month
          : `${item.month}-01`; 
        const date = new Date(dateString);
        const month = date.toLocaleString('default', { month: 'short' });
        return {
          name: month,
          value: item.totalRevenue,
        };
      });
      dispatch({
        type: 'SET_REVENUE_DETAILS',
        payload: revenueDetails,
      });
    } else {
      dispatch({
        type: 'SET_REVENUE_DETAILS',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'SET_REVENUE_DETAILS',
      payload: [],
    });
  }
};

export const getTopSellingProductDetails = async (dispatch, selectedTenant) => {
  try {
    const response = await getTopSellingProducts(dispatch, selectedTenant);
    if (response?.length > 0) {
      const topSellingProductDetails = response?.map((item) => ({
        productId: item.productId,
        name: item.productName,
        gmv: item.revenue,
        quantity: item.quantity,
        categoryId: item.productCategoryId,
      }));
      dispatch({
        type: 'SET_TOP_SELLING_PRODUCT_DETAILS',
        payload: topSellingProductDetails,
      });
    } else {
      dispatch({
        type: 'SET_TOP_SELLING_PRODUCT_DETAILS',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'SET_TOP_SELLING_PRODUCT_DETAILS',
      payload: [],
    });
  }
};

export const getProductThumbnailDetails = async (dispatch, productIds) => {
  try {
    const documentTypeId = idConstants.documentType.thumbnailImg;
    const response = await getDocumentDetails(dispatch, productIds, documentTypeId);
    if (response?.length > 0) {
      const productThumbnailDetails = response?.map((item) => ({
        blobFileUrl: item.blobFileUrl,
        productId: item.productId,
      }));

      dispatch({
        type: 'SET_PRODUCT_THUMBNAIL_DETAILS',
        payload: productThumbnailDetails,
      });
    } else {
      dispatch({
        type: 'SET_PRODUCT_THUMBNAIL_DETAILS',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'SET_PRODUCT_THUMBNAIL_DETAILS',
      payload: [],
    });
  }
};

export const getCategoryList = async (statusId, idConstants, dispatch) => {
  try {
    const response = await dispatch(getCategories(statusId));
    if (response?.content?.length > 0) {
      let filteredCategoryList = response.content?.filter(
        (category) =>
          category?.myProductsCounts && category?.myProductsCounts?.length > 0
      )?.map((category) => ({
        displayName: category.productCategoryName,
        value: category.productCategoryId,
      }))?.filter((item) =>
        process.env.REACT_APP_REGION !== REGION.IN.CODE
          ? activeCategoryIdInRegion[process.env.REACT_APP_REGION]?.includes(
            item.value
          )
          : true
      );

      if (process.env.REACT_APP_REGION === REGION.IN.CODE) {
        filteredCategoryList.unshift({
          displayName: idConstants.api.productCategoryName,
          value: idConstants.api.categoryId,
        });
      }

      filteredCategoryList.unshift({
        displayName: i18next.t('HOME_PAGE.HEADER_FOOTER.ALL_CATEGORIES'),
        value: idConstants.all,
      });
      dispatch({
        type: 'SET_CATEGORY_LIST',
        payload: filteredCategoryList,
      });

    } else {
      dispatch({
        type: 'SET_CATEGORY_LIST',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'SET_CATEGORY_LIST',
      payload: [],
    });
  }
};

export const formatTotalRevenue = (total, region) => {
  let locale;
  let currency;
  switch (region) {
    case 'EU':
      locale = 'de-DE';
      currency = ' EURO';
      break;
    case 'NA':
      locale = 'en-US';
      currency = ' USD';
      break;
    default:
      locale = 'en-IN';
      currency = ' INR';
      break;
  }

  return new Intl.NumberFormat(locale).format(total) + currency;
};
