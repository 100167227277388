import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { ChartGrid } from '@mobilitystore/charts-interface';
import CustomCell from '../../../../../../msreact-kit/html-components/custom-cell';

// Assets
import Images from '../../../../../../assets/img';

// Actions
import { updateDeletedCompanyId } from '../../../../../../actions/api/pricing-plan-action';

// CSS
import './index.scss';

const CustomButtonComponent = (props) => {
  const dispatch = useDispatch();

  const handleDeleteCompanyId = (companyId) => {
    dispatch(updateDeletedCompanyId(companyId));
  };

  return (
    <div
      className='button delete-tier-button '
      onClick={() => handleDeleteCompanyId(props.data.companyId)}
    >
      <img src={Images.deleteIcon} alt='icon' />
    </div>
  );
};

export const CompanyGrid = ({ data }) => {
  // Column Definitions: Defines the columns to be displayed.
  const colDefs = [
    {
      field: 'companyId',
      headerName: 'Company ID',
      cellRenderer: (cellProps) => (
        <CustomCell {...cellProps} showThumbnail={false} />
      )
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      cellRenderer: (cellProps) => (
        <CustomCell {...cellProps} showThumbnail={false} />
      )
    },
    {
      field: 'button',
      headerName: '',
      cellRenderer: CustomButtonComponent,
      maxWidth: 60
    }
  ];

  const gridProps = {
    rowData: data,
    columnDefs: colDefs,
    suppressDragLeaveHidesColumns: true,
    filter: 'agTextColumnFilter'
  };

  return <ChartGrid gridProps={gridProps} />;
};
