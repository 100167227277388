import { TENANT, SELLER_TENANT_ID } from '../../../actions/configuration';
import IdConstants from '../../../data/idConstants';
import { pricingPlanType } from '../../../data/appConstants';

export const INITIAL_CURRENT_PLAN_MKOS = {
  planName: '',
  description: '',
  planBenefits: [],
  billingCycleType: '',
  price: 0,
  consumption: '',
  currencyCode: '',
  rateLimitType: '',
  quota: '',
  chargeableUnit: { chargeableUnitType: '' },
  requestCount: 1,
  overUsagePrice: 0,
  isSlabPricing: false,
  isSoftLimit: false,
  paymentPreference: 'true',
  planGroupName: 'GENERIC',
  planDetails: {
    planType: '',
    paymentType: '',
    paymentModePref: '',
    occurrence: '0'
  }
};

export const INITIAL_CURRENT_PLAN_MKOS_INT = {
  planName: '',
  description: '',
  planBenefits: [],
  billingCycleType: 'ONE_TIME_PURCHASE',
  price: 0,
  consumption: 'NOT_APPLICABLE',
  currencyCode: '',
  rateLimitType: 'NO_MEASUREMENT',
  quota: '',
  chargeableUnit: { chargeableUnitType: '' },
  requestCount: 1,
  overUsagePrice: 0,
  isSlabPricing: false,
  isSoftLimit: false,
  paymentPreference: 'false',
  planGroupName: 'GENERIC',
  planDetails: {
    planType: '',
    paymentType: '',
    paymentModePref: '',
    occurrence: '0'
  }
};

export const getInitialCurrentPlan = () => {
  if (SELLER_TENANT_ID === TENANT.MKOSINT) {
    return INITIAL_CURRENT_PLAN_MKOS_INT;
  }
  return INITIAL_CURRENT_PLAN_MKOS;
};

export const getValidationFieldsInPaymentOptions = () => {
  if (SELLER_TENANT_ID === TENANT.MKOSINT) {
    return ['currency'];
  }
  return ['netPrice', 'pricingType', 'billingCycle', 'currency'];
};

export const INITIAL_CURRENT_API_PLAN_MKOS_INT = {
  planName: '',
  description: '',
  currency: {
    currencyCode: ''
  },
  planCost: 0,
  isSlabPricing: false,
  planBillingCycle: {
    billingCycleType: 'MONTHLY'
  },
  planBenefits: [],
  isSoftLimit: true,
  consumption: 'NOT_APPLICABLE',
  rateLimit: {
    rateLimitType: {
      limitType: 'REQUEST_COUNT'
    },
    timeUnit: {
      timeUnitCode: 'MONTHLY'
    },
    limitCount: 1,
    chargeableUnit: {
      chargeableUnitType: 'PER_REQUEST_HIT'
    }
  },
  softLimitData: {
    additionalCost: 0
  },
  paymentPreference: {
    isRequired: false
  },
  planDetails: {
    planType: 'FREE',
    paymentType: 'NOT_APPLICABLE',
    paymentModePref: 'INVOICE',
    occurrence: '1'
  }
};

export const INITIAL_CURRENT_API_TRYOUT_PLAN_MKOS_INT = {
  planName: '',
  description: 'tryout plan',
  currency: {
    currencyCode: 'INR'
  },
  planBillingCycle: {
    billingCycleType: 'MONTHLY'
  },
  rateLimit: {
    rateLimitType: {
      limitType: 'REQUEST_COUNT'
    },
    timeUnit: {
      timeUnitCode: 'MONTHLY'
    },
    limitCount: 1,
    chargeableUnit: {
      chargeableUnitType: 'PER_REQUEST_HIT'
    }
  },
  isSlabPricing: false,
  planCost: 0,
  consumption: 'NOT_APPLICABLE',
  isSoftLimit: false,
  tryOut: true
};

export const INITIAL_CURRENT_API_TRYOUT_PLAN_MKOS = {
  planId: '',
  planName: '',
  description: 'tryout plan',
  billingCycleType: IdConstants.pricing.oneTimePlanBillingCycleType,
  price: '0',
  consumption: 'NOT_APPLICABLE',
  currencyCode: IdConstants.pricing.rupeesCurrencyCode,
  rateLimitType: IdConstants.pricing.reqCountLimitType,
  requestCount: '',
  quota: IdConstants.pricing.monthTimeUnitCode,
  isSoftLimit: false,
  tryOut: true
};

export const getTryOutPlan = () => {
  if (SELLER_TENANT_ID === TENANT.MKOSINT) {
    return INITIAL_CURRENT_API_TRYOUT_PLAN_MKOS_INT;
  }
  return INITIAL_CURRENT_API_TRYOUT_PLAN_MKOS;
};

export const PRICING_PLAN_TAB_MKOS = [
  {
    displayName: 'DYNAMIC_FORM.ADD_API.BASIC_PLAN_INFORMATION',
    activeItem: '0'
  },
  {
    displayName: 'DYNAMIC_FORM.PRICING_PLAN.PAYMENT_OPTIONS',
    activeItem: '1'
  },
  {
    displayName: 'DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN_OPTIONS',
    activeItem: '2'
  }
];

export const PRICING_PLAN_TAB_MKOS_INT = [
  {
    displayName: 'DYNAMIC_FORM.ADD_API.BASIC_PLAN_INFORMATION',
    activeItem: '0'
  },
  {
    displayName: 'DYNAMIC_FORM.PRICING_PLAN.PAYMENT_OPTIONS',
    activeItem: '1'
  }
];
export const getPricingPlanTab = () => {
  if (SELLER_TENANT_ID === TENANT.MKOSINT) {
    return PRICING_PLAN_TAB_MKOS_INT;
  }
  return PRICING_PLAN_TAB_MKOS;
};

export const getPricingPlanType = () => {
  if (SELLER_TENANT_ID === TENANT.MKOSINT) {
    return pricingPlanType[3].value;
  }
  return pricingPlanType[2].value;
};
