import _ from 'lodash';
import {
  initialPlanGroupNameAttribute,
  initialPrivatePlanConfigAttributes,
  initialPrivatePlanConfigPayload
} from '../../components/pricing/PricingPlanScreen/basic-plan/data';

const initialState = {
  pricingPlans: [],
  pricingPlan: {},
  updatePricingPlanError: '',
  deletePricingPlanError: '',

  // pricing plan additional configurations
  planGroupNameAttribute: _.cloneDeep(initialPlanGroupNameAttribute),
  privatePlanConfigAttributes: _.cloneDeep(initialPrivatePlanConfigAttributes),
  privatePlanConfigPayload: _.cloneDeep(initialPrivatePlanConfigPayload),
  deletedCompanyId: ''
};

const ProductPricingPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRICING_PLANS':
      return Object.assign({}, state, { pricingPlans: action.payload });
    case 'CREATE_PRICING_PLAN':
      return Object.assign({}, state, { pricingPlan: action.payload });
    case 'DELETE_PRICING_PLAN':
      return Object.assign({}, state, { pricingPlan: action.payload });
    case 'DELETE_PRICING_PLAN_ERROR':
      return Object.assign({}, state, { deletePricingPlanError: action.error });
    case 'RESET_PRICING_PLAN':
      return Object.assign({}, state, { pricingPlans: [] });
    case 'UPDATE_PLAN_GROUP_TYPE_ATTRIBUTE':
      return Object.assign({}, state, {
        planGroupNameAttribute: action.payload
      });
    case 'UPDATE_PRIVATE_PLAN_CONFIG_ATTRIBUTES':
      return Object.assign({}, state, {
        privatePlanConfigAttributes: action.payload
      });
    case 'UPDATE_PLAN_ADDITIONAL_CONFIG_PAYLOAD':
      return Object.assign({}, state, {
        privatePlanConfigPayload: action.payload
      });
    case 'UPDATE_DELETED_COMPANY_ID':
      return Object.assign({}, state, {
        deletedCompanyId: action.payload
      });
    default:
      return state;
  }
};

export default ProductPricingPlanReducer;
