import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';

// Components
import {
  Dropdown,
  TextInput,
  MultiSelect,
  TextArea,
  CheckBox,
  Attachment,
  GroupingCard,
  NumberInput
} from '../../../../msreact-kit/packages/html-components';
import { PrimaryButton } from '../../../../msreact-kit/html-components/button';
import PrivatePlanConfig from './private-plan-config';

// Actions
import {
  updatePlanGroupNameAttribute,
  updatePrivatePlanConfigAttributes,
  updatePrivatePlanConfigPayload
} from '../../../../actions/api/pricing-plan-action';

// Constants
import { DynamicFieldConstants } from '../../../../shared/constants';
import { sortByDisplayOrder } from '../../../../utils';
import { PRICING } from '../../../../data/idConstants';

// Data
import {
  initialPrivatePlanConfigAttributes,
  initialPrivatePlanConfigPayload
} from './data';

// CSS
import './index.scss';

const BasicPlan = ({
  item,
  handleFieldChange,
  handleGroupData,
  handleDeleteGroup,
  updatePricingPlanPayload,
  children
}) => {
  const data = { ...item };
  data.attributes = sortByDisplayOrder(data?.attributes);

  const { planGroupNameAttribute } = useSelector((state) => {
    return {
      planGroupNameAttribute: state.ProductPricingPlanReducer.planGroupNameAttribute
    };
  });

  const dispatch = useDispatch();

  const handleGetFields = (fieldItem, callback) => {
    const updatedPlanName = { ...fieldItem };
    if (fieldItem?.name === 'planName')
      updatedPlanName.value = fieldItem?.value?.toUpperCase();

    switch (fieldItem.attributeType) {
      case 'TEXT':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <TextInput
              handleChange={(event) => callback(event, fieldItem)}
              {...updatedPlanName}
            />
          </div>
        );

      case DynamicFieldConstants.NUMBER:
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <NumberInput
              handleChange={(event) => callback(event, fieldItem)}
              {...updatedPlanName}
            />
          </div>
        );

      case 'TEXTAREA':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <TextArea
              handleChange={(event) => callback(event, fieldItem)}
              {...fieldItem}
            />
          </div>
        );

      case 'SINGLESELECT':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <Dropdown
              {...fieldItem}
              handleChange={(event) => callback(event, fieldItem)}
            />
          </div>
        );
      case 'MULTISELECT':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <MultiSelect
              {...fieldItem}
              values={fieldItem.value}
              handleChange={(event) => callback(event, fieldItem)}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div className='home-wrapper__form__document' key={fieldItem.fieldId}>
            <CheckBox label={fieldItem.name} {...fieldItem} />
          </div>
        );
      case 'FILE':
        return (
          <div className='home-wrapper__form__document' key={fieldItem.fieldId}>
            <Attachment
              inputLabel={fieldItem.displayName}
              {...fieldItem}
              handleChange={(event) => callback(event, fieldItem)}
            />
          </div>
        );
      case 'PRIMARY_BUTTON':
        return (
          <PrimaryButton
            label={fieldItem.displayName}
            onClick={(event) => callback(event, fieldItem)}
            data-frok-action='button'
          />
        );
      case 'COMBINE':
        return (
          <div className={fieldItem.className}>
            {fieldItem.attributes.map((field) => {
              return handleGetFields(field, callback);
            })}
          </div>
        );

      default:
        return;
    }
  };

  const handlePlanGroupNameChange = (event) => {
    const field = _.cloneDeep(planGroupNameAttribute);
    field.value = event.value.label;
    dispatch(updatePlanGroupNameAttribute(field));
    updatePricingPlanPayload('planGroupName', event.value.value, 1);
    if (event.value.value === PRICING.PLAN_GROUP_TYPE.GENERIC.VALUE) {
      dispatch(
        updatePrivatePlanConfigAttributes(initialPrivatePlanConfigAttributes)
      );
      dispatch(
        updatePrivatePlanConfigPayload(_.cloneDeep(initialPrivatePlanConfigPayload))
      );
    }
  };

  return (
    <Fragment>
      <div className='basic-plan'>
        <div className='new-pricing-plan'>
          <div className='new-pricing-plan__list'>
            {data?.attributes?.map((itemField, k) =>
              handleGetFields(
                {
                  ...itemField,
                  isGrouping: true
                },
                handleFieldChange
              )
            )}
            {data?.groups && data?.groups?.length > 0 && (
              <GroupingCard
                group={data.groups}
                handleGetFieldInput={(groupingCardData) =>
                  handleGetFields(groupingCardData, handleFieldChange)
                }
                handleGroupData={handleGroupData}
                handleDeleteGroup={handleDeleteGroup}
              />
            )}
            {
              <div className='plan-config'>
                <h1 className='plan-config__title'>
                  {i18next.t(
                    'DYNAMIC_FORM.PRICING_PLAN.ADDITIONAL_CONFIGURATION.TITLE'
                  )}
                </h1>
                <div className='plan-config__fields'>
                  {handleGetFields(
                    planGroupNameAttribute,
                    handlePlanGroupNameChange
                  )}
                </div>
              </div>
            }
            {planGroupNameAttribute.value ===
              PRICING.PLAN_GROUP_TYPE.PRIVATE.LABEL && (
              <PrivatePlanConfig handleGetFields={handleGetFields} />
            )}
          </div>
          {children}
        </div>
      </div>
    </Fragment>
  );
};
BasicPlan.propTypes = {
  planNameDetails: PropTypes.object,
  textAreaData: PropTypes.object,
  middleTextForPlan: PropTypes.object,
  bottomInputFields: PropTypes.array
};
BasicPlan.defaultProps = {
  planNameDetails: {},
  textAreaData: {},
  middleTextForPlan: {},
  bottomInputFields: []
};
export default BasicPlan;
