
import axios from 'axios';
import { BASE_URL, AGGREGATOR, CAPABILITIES, VERSION_1 } from './configuration';
import { AGGREGATOR_SERVICE_ENDPOINT } from './configuration/aggregator-service';
import idConstants from '../data/idConstants';

export const AGGREGATOR_SERVICE_URL = `${BASE_URL}/${AGGREGATOR}/${CAPABILITIES}/${VERSION_1}`;
export const getRevenueCount = (dispatch, categoryId, tenantId, startDate, endDate) => {
  let productCategoryId;
  let selectedTenant;

  if (tenantId && tenantId !== idConstants.allTenants) {
    selectedTenant = tenantId;
  }
  if (categoryId && categoryId !== idConstants.all) {
    productCategoryId = categoryId;
  }

  const params = {
    productCategroyId: productCategoryId,
    tenantId: selectedTenant,
  };

  if (startDate && startDate !== idConstants.all) {
    params.startDate = startDate;
    params.endDate = endDate;
  }

  return axios({
    method: 'GET',
    url: AGGREGATOR_SERVICE_URL + AGGREGATOR_SERVICE_ENDPOINT.REVENUE_COUNT,
    data: {},
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};


export const getTopSellingProducts = (dispatch, tenantId) => {

  let selectedTenant;

  if (tenantId && tenantId !== idConstants.allTenants) {
    selectedTenant = tenantId;
  }

  return axios({
    method: 'GET',
    url: AGGREGATOR_SERVICE_URL + AGGREGATOR_SERVICE_ENDPOINT.TOP_SELLING_PRODUCTS,
    data: {},
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      tenantId: selectedTenant
    },
  }).then((response) => {
    return response.data;
  })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error
      });
    });
};
