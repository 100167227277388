import React from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  IconButton,
  SecondaryButton,
  ToggleButton
} from '../../../msreact-kit/packages/html-components';
import Images from '../../../assets/img';
import idConstants, { PRICING } from '../../../data/idConstants';
import i18next from 'i18next';
import CustomToolTip from '../../Shared/custom-tooltip';
import Tooltip from '../../../components/Shared/Tooltip';
import { getPricingPlanStatus } from '../../../utils';
import { getRateLimitTypeName } from '../../../utils/get-rate-limit-type';
import './index.scss';

const PricingCard = ({
  id,
  name,
  price,
  isoCode,
  benefits,
  chargeableUnit,
  handleDelete,
  isView,
  handleChange,
  billingType,
  pricingPlanCardData,
  productStatusId,
  changePlanStatus,
  editPricingPlan,
  rateLimitTypes
}) => {
  const { type, cardType } = getPricingPlanStatus(
    pricingPlanCardData?.status?.description,
    productStatusId
  );

  const getPriceRange = (priceData) => {
    let priceValues = [];
    let minPrice = '';
    let maxPrice = '';
    if (
      priceData?.rateLimit?.slabLimitDetails &&
      priceData?.rateLimit?.slabLimitDetails?.length !== 0
    ) {
      priceData?.rateLimit?.slabLimitDetails.map((tier) => {
        priceValues.push(tier.cost);
        return true;
      });

      if (priceValues.every((item) => item === priceValues[0])) {
        minPrice = priceValues[0];
      } else {
        priceValues.sort(function (firstElement, secondElement) {
          return firstElement - secondElement;
        });
        minPrice = priceValues[0];
        if (priceValues.length > 1) {
          maxPrice = priceValues[priceValues.length - 1];
        }
      }
    }

    return (
      <>
        {minPrice !== '' && (
          <span className='bold-style'>
            {parseFloat(minPrice).toFixed(2)} {priceData?.currency?.currencyCode}
          </span>
        )}
        {maxPrice !== '' && (
          <span className='bold-style'>
            {' '}
            - {parseFloat(maxPrice).toFixed(2)} {priceData?.currency?.currencyCode}
          </span>
        )}
      </>
    );
  };

  const getBillingType = () => {
    if (billingType === idConstants.recurringBillingType.billingTypeCode) {
      //Recurring Payment Type only will have SLAB and TIER
      switch (chargeableUnit) {
        case idConstants.pricing.perReqHitSlabType:
          return i18next.t('DYNAMIC_FORM.PRICING_CARD.PER_REQUEST_HIT');
        case idConstants.pricing.fullSlabType:
          return i18next.t('DYNAMIC_FORM.PRICING_CARD.FULL_SLAB');
        default:
          return '';
      }
    } else return '';
  };

  const getConsumptionUnit = (consumptionUnitOrg) => {
    if (
      consumptionUnitOrg.replace(' ', '_') === idConstants.pricing.reqCountLimitType
    ) {
      return i18next.t('DYNAMIC_FORM.PRICING_PLAN.REQUEST');
    }

    return getRateLimitTypeName(rateLimitTypes, consumptionUnitOrg);
  };

  const getChargeableUnit = () => {
    if (!pricingPlanCardData?.isSlabPricing && pricingPlanCardData?.planCost > 0) {
      return pricingPlanCardData?.rateLimit?.rateLimitType?.limitType !==
        idConstants.pricing.consumptionNoMeasurementType
        ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER') +
            ' ' +
            getConsumptionUnit(
              pricingPlanCardData?.rateLimit?.rateLimitType?.limitType
            )
        : '';
    }

    if (
      pricingPlanCardData?.rateLimit?.chargeableUnit?.chargeableUnitType ===
      idConstants?.pricing?.perReqHitSlabType
    ) {
      return `${i18next.t(
        'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER'
      )} ${getConsumptionUnit(
        pricingPlanCardData?.rateLimit?.rateLimitType?.limitType
      )}`;
    }

    return getBillingType();
  };

  return (
    <div
      className='pricing-card'
      style={{ opacity: pricingPlanCardData?.enabled ? 1 : 0.5 }}
      data-testid='pricingCardTestId'
      key={id}
    >
      <div className='pricing-card__top'>
        {pricingPlanCardData?.planGroupName ===
          PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE && (
          <img
            className='pricing-card__top-exclusive'
            src={Images.plan.exclusiveRibbon}
            alt=''
          />
        )}

        <CustomToolTip
          id={name}
          tooltipData={name}
          width='dynamic'
          className='pricing-card__top-customtooltip'
        >
          <p className='pricing-card__name  pricing-card__top-items' id={name}>
            {' '}
            {name}
          </p>
        </CustomToolTip>
        {pricingPlanCardData?.status?.description && (
          <Badge customClass={`pricing-card__status-badge ${cardType}`}>
            {i18next.t(type)}
          </Badge>
        )}
        <div>
          <p className='pricing-card__price pricing-card__top-items '>
            {(price.length > 0 && price > 0) ||
            pricingPlanCardData?.billingType?.billingTypeCode ===
              idConstants.freePlanBillingType.billingTypeCode ? (
              <>
                {parseFloat(price).toFixed(2)} {isoCode}
              </>
            ) : (
              getPriceRange(pricingPlanCardData)
            )}
          </p>
        </div>
        <p className='pricing-card__description pricing-card__top-items'>
          {getChargeableUnit()}
        </p>
      </div>
      <div className='pricing-card__list'>
        <div className='pricing-card__list'>
          {benefits.map((item) => {
            return (
              <CustomToolTip
                key={item?.planBenefitId}
                id={item?.planBenefitId}
                tooltipData={item?.benefitDescription}
                width='dynamic'
              >
                <div className='list-item'>
                  <img
                    className='list-item__checkmark'
                    src={Images.checkMarkBold}
                    alt=''
                    width={16}
                    height={16}
                  />
                  {item.benefitDescription}
                </div>
              </CustomToolTip>
            );
          })}
        </div>
      </div>

      <div className='pricing-card__bottom'>
        <SecondaryButton
          label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.VIEW_DETAILS')}
          onClick={handleChange}
        />
        {!isView && (
          <div className='pricing-card__display-flex'>
            <IconButton
              label=''
              className='pricing-card__bottom-button'
              iconCss='pricing-card__bottom-button__icon'
              iconTitle={i18next.t(
                'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.EDIT_PLAN'
              )}
              onClick={() => editPricingPlan(pricingPlanCardData)}
              src={Images.editIcon}
              isIcon={false}
            />
            <IconButton
              label=''
              className='pricing-card__bottom-button'
              iconCss='pricing-card__bottom-button__icon'
              iconTitle={i18next.t(
                'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DELETE_PLAN'
              )}
              onClick={handleDelete}
              src={Images.deleteIcon}
              isIcon={false}
            />
            <Tooltip
              title={
                pricingPlanCardData.enabled
                  ? i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DEACTIVATE_PLAN'
                    )
                  : i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.ACTIVATE_PLAN'
                    )
              }
            >
              <div className='toggle-button-div'>
                <ToggleButton
                  label=''
                  name={pricingPlanCardData?.planName}
                  id={pricingPlanCardData?.planId}
                  checked={pricingPlanCardData?.enabled}
                  handleChange={() => changePlanStatus(pricingPlanCardData)}
                  title={
                    pricingPlanCardData.enabled
                      ? i18next.t(
                          'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DEACTIVATE_PLAN'
                        )
                      : i18next.t(
                          'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.ACTIVATE_PLAN'
                        )
                  }
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  isoCode: PropTypes.string,
  benefits: PropTypes.array,
  chargeableUnit: PropTypes.string
};

PricingCard.defaultProps = {
  name: '',
  price: 0,
  isoCode: '',
  benefits: [],
  chargeableUnit: ''
};

export default PricingCard;
