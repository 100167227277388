import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApiStatistics from '../../components/api/api-statistics';
import {
  getApiStatistics,
  getUserDetails,
  getApiMetrics
} from '../../actions/api/ApiStatisticsAction';
import {
  getProductsByStatus,
  getProductsCount
} from '../../actions/MyProductsAction';
import { retrieveMSCompanyDetails } from '../../actions/ProfileAction';
import loading from '../../actions/LoaderAction';
import axios from 'axios';

class ApiStatisticsContainer extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    this.state = {
      companyDetails: [],
      countryName: ''
    };
    let count = 0;
    axios.interceptors.request.use(
      function (config) {
        count = count + 1;
        // spinning start to show
        self.props.loading(true);
        return config;
      },
      function (error) {
        count = count - 1;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        count = count - 1;
        // spinning hide
        if (count === 0) {
          self.props.loading(false);
        }
        return response;
      },
      function (error) {
        count = count - 1;
        if (count === 0) {
          self.props.loading(false);
        }
        return Promise.reject(error);
      }
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if(this.props.accessToken)
      this.props.getUserDetails(); // fetching user details with user id (sub refers to user id in the token)
    if (this.props.userCompanyStatus?.companyId) {
      this.props
        .retrieveMSCompanyDetails(
          this.props?.userCompanyStatus?.userId,
          this.props?.userCompanyStatus?.companyId
        )
        .then((response) => {
          this.setState({ companyDetails: response });
          if (response && response.billingAddress) {
            this.props?.countryList?.forEach((country) => {
              if (country?.countryCode === response.billingAddress.countryCode) {
                this.setState({ countryName: country.countryName });
              }
            });
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accessToken !== this.props?.accessToken) {
      this.props.getUserDetails();
    }
    if (
      prevProps.userCompanyStatus?.companyId !==
      this.props.userCompanyStatus?.companyId
    ) {
      this.props
        .retrieveMSCompanyDetails(
          this.props?.userCompanyStatus?.userId,
          this.props?.userCompanyStatus?.companyId
        )
        .then((response) => {
          this.setState({ companyDetails: response });
          if (response && response.billingAddress) {
            this.props?.countryList?.forEach((country) => {
              if (country?.countryCode === response.billingAddress.countryCode) {
                this.setState({ countryName: country.countryName });
              }
            });
          }
        });
    }
  }

  render() {
    return (
      <ApiStatistics
        {...this.props}
        companyDetails={this.state.companyDetails}
        countryName={this.state.countryName}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getApiStatistics,
      loading,
      getUserDetails,
      getApiMetrics,
      retrieveMSCompanyDetails,
      getProductsByStatus,
      getProductsCount
    },
    dispatch
  );
}

const mapStateToProps = (redux_state) => ({
  usageTracking: redux_state.ApiStatisticsReducer.usageTracking,
  responseHeaders: redux_state.ApiStatisticsReducer.responseHeaders,
  loader: redux_state.LoaderReducer,
  user: redux_state.loginReducer.user,
  userDetails: redux_state.loginReducer.userDetails,
  userCompanyStatus: redux_state.CompanyDetailsReducer.userCompanyStatus,
  usageMetrics: redux_state.ApiStatisticsReducer.usageMetrics,
  countryList: redux_state.CountryReducer.countryList,
  errorMsg: redux_state.ApiStatisticsReducer.errorMsg,
  accessToken: redux_state.loginReducer.accessToken
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiStatisticsContainer);
