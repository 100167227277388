// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Libs
import i18next from 'i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//Component
import { ChartCard } from '@mobilitystore/charts-interface';

// Utils
import { calculateDateRange, getOrderDetails } from '../utils';

//Data
import { getDonutChartOptions, dateOptions, orderHeaderOptions, orderFooterOptions, toMyOrders, defaultPlaceholderFields, richTextStyles } from '../data';
import idConstants from '../../../../data/idConstants';



const OrderWidget = React.memo((props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [categoryId, setCategoryId] = useState(idConstants.all);
    const [chartOptions, setChartOptions] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [dateOption, setDateOption] = useState(dateOptions[3].value);
    const [total, setTotal] = useState('');

    const { orderDetails, categoryList } = useSelector(
        (state) => ({
            orderDetails: state?.UnifiedSellerDashboardReducer?.orderDetails,
            categoryList: state?.UnifiedSellerDashboardReducer?.categoryList
        }),
        shallowEqual
    );

    useEffect(() => {
        if (categoryList?.length > 0 && startDate?.length > 0) {
            getOrderDetails(dispatch, categoryId, props?.selectedTenant, startDate, endDate);
        }
    }, [categoryList, categoryId, props?.selectedTenant, dateOption, startDate, endDate]);

    useEffect(() => {
        if (!orderDetails) {
            return;
        }
        const donutChartOptions = getDonutChartOptions(isSmallDevice, orderDetails);
        const total = orderDetails.reduce((acc, curr) => acc + curr.value, 0);
        setTotal(String(total));
        donutChartOptions.legend.formatter = function (name) {
            const item = orderDetails.find((d) => d.name === name);
            const value = item?.value || '';

            return isSmallDevice ? `${name}: ${item?.value}` : `{nameStyle|${name}}{valueStyle|${value}}`;
        };
        if (!isSmallDevice) {
            donutChartOptions.legend.textStyle = {
                rich: richTextStyles
            };
        }
        donutChartOptions.series[0].label.formatter = `{b|Total}\n{c|${total}}`;
        donutChartOptions.series[0] = {
            ...donutChartOptions.series[0],
            data: orderDetails,
        };

        setChartOptions(donutChartOptions);

    }, [orderDetails, isSmallDevice]);

    useEffect(() => {
        const initialDateRange = calculateDateRange(dateOption);
        if (initialDateRange) {
            setStartDate(initialDateRange.start);
            setEndDate(initialDateRange.end);
        }
    }, []);

    const orderDateHandler = (event) => {
        setDateOption(event.target.value);
        const dateRange = calculateDateRange(event.target.value);
        if (dateRange) {
            setStartDate(dateRange.start);
            setEndDate(dateRange.end);
        }
    };


    const orderCategoryChangeHandler = (event) => {
        setCategoryId(event.target.value);
    };
    const orderFooterLinkHandler = () => {
        window.location.href = toMyOrders;
    };
    const orderHeaderDetails = orderHeaderOptions(
        categoryList,
        dateOptions,
        categoryId,
        orderCategoryChangeHandler,
        orderDateHandler,
        dateOption
    );
    const orderFooterDetails = orderFooterOptions(orderFooterLinkHandler);

    return (
        <div
            className='usp-dashboard__chart-card'
        >
            <ChartCard
                header={{
                    label: {
                        displayValue: i18next.t('DASHBOARD.CHART.ORDER.TOTAL')
                    },
                    fields: orderHeaderDetails,
                    data: total
                }}
                chart={{
                    type: 'chart',
                    config: chartOptions,
                    data: orderDetails,

                }}
                footer={{
                    label: '',
                    fields: orderFooterDetails,
                }}
                placeholder={defaultPlaceholderFields}
            />
        </div>
    );
});
export default OrderWidget;