// React
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { InfoOutlined } from '@mui/icons-material';
import LightTooltip from '../../../../components/Shared/Tooltip';

// Libs
import i18next from 'i18next';

//Component
import { ChartCard } from '@mobilitystore/charts-interface';
import CustomCell from '../../../../msreact-kit/html-components/custom-cell';

// Utils
import { getTopSellingProductDetails, getProductThumbnailDetails } from '../utils';

// Data 
import { topSellingProductsColumnDefs, topSellingProductPlaceholderFields } from '../data';


const CustomHeader = ({ displayName, enableSorting, setSort, column }) => {
  const handleSort = () => {
    if (enableSorting) {
      const nextSort = column.getSort() === 'asc' ? 'desc' : 'asc';
      setSort(nextSort);
    }
  };

  return (
    <div className='top-selling__custom-header' onClick={handleSort}>
      <div className='top-selling__custom-header__content'>
        <span className='top-selling__custom-header__text'>{displayName}</span>
        <LightTooltip title={i18next.t('DASHBOARD.CHART.TOP_SELLING.GMV_INFO')}>
          <InfoOutlined className='top-selling__custom-header__icon' />
        </LightTooltip>
      </div>
    </div>
  );
};

const TopSellingProductWidget = React.memo((props) => {
  const dispatch = useDispatch();

  const { topSellingProductDetails, productThumbnailDetails, userCompanyStatus } = useSelector(
    (state) => ({
      topSellingProductDetails: state?.UnifiedSellerDashboardReducer?.topSellingProductDetails,
      productThumbnailDetails: state?.UnifiedSellerDashboardReducer?.productThumbnailDetails,
      userCompanyStatus: state?.CompanyDetailsReducer?.userCompanyStatus,

    }),
    shallowEqual
  );

  useEffect(() => {
    if (userCompanyStatus.companyRegisteredStatus === 'approved') {
      getTopSellingProductDetails(dispatch, props?.selectedTenant);
    }
  }, [props?.selectedTenant]);



    useEffect(() => {
        if (!topSellingProductDetails || topSellingProductDetails.length === 0) {
            return;
        }
        const productId = topSellingProductDetails.map(product => product.productId);
        getProductThumbnailDetails(dispatch, productId);
    }, [topSellingProductDetails]);

    const columnDefs = topSellingProductsColumnDefs(
        (cellProps) => <CustomCell {...cellProps} documentDetails={productThumbnailDetails} showThumbnail={true} />,
        CustomHeader
    );

  return (
    <div
      className='usp-dashboard__chart-card'
    >
      <ChartCard
        header={{
          label: {
            info: i18next.t('DASHBOARD.CHART.TOP_SELLING.INFO'),
            displayValue: i18next.t('DASHBOARD.CHART.TOP_SELLING.TOTAL')
          }
        }}
        chart={{
          type: 'grid',
          gridProps: {
            rowData: topSellingProductDetails,
            columnDefs: columnDefs,
            suppressMovableColumns: true
          }
        }}
        placeholder={topSellingProductPlaceholderFields}
      />
    </div>
  );
});
export default TopSellingProductWidget;
