const initialState = {
    orderDetails: null,
    productDetails: null,
    revenueDetails: null,
    topSellingProductDetails: null,
    categoryList: [],
    productThumbnailDetails: []
};

const UnifiedSellerDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CATEGORY_LIST':
            return {
                ...state,
                categoryList: action.payload,
                error: null,
            };
        case 'SET_ORDER_DETAILS':
            return {
                ...state,
                orderDetails: action.payload,
                error: null,
            };
        case 'SET_PRODUCT_DETAILS':
            return {
                ...state,
                productDetails: action.payload,
                error: null,
            };
        case 'SET_REVENUE_DETAILS':
            return {
                ...state,
                revenueDetails: action.payload,
                error: null,
            };
        case 'SET_TOP_SELLING_PRODUCT_DETAILS':
            return {
                ...state,
                topSellingProductDetails: action.payload,
                error: null,
            };
        case 'SET_PRODUCT_THUMBNAIL_DETAILS':
            return {
                ...state,
                productThumbnailDetails: action.payload,
                error: null,
            };
        default:
            return state;
    }
};

export default UnifiedSellerDashboardReducer;
