// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Libs
import i18next from 'i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//Component
import { ChartCard } from '@mobilitystore/charts-interface';

// Utils
import { calculateDateRange, getRevenueDetails, formatTotalRevenue } from '../utils';
import { getRegionCode } from '../../../../utils/get-region-details';

//Data
import { getColumnChartOptions, dateOptions, revenueHeaderOptions, revenuePlaceholderFields } from '../data';
import idConstants from '../../../../data/idConstants';

const RevenueWidget = React.memo((props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [categoryId, setCategoryId] = useState(idConstants.all);
    const [chartOptions, setChartOptions] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [dateOption, setDateOption] = useState(dateOptions[3].value);
    const [total, setTotal] = useState('');

    const regionCode = getRegionCode();

    const { revenueDetails, categoryList } = useSelector(
        (state) => ({
            revenueDetails: state?.UnifiedSellerDashboardReducer?.revenueDetails,
            categoryList: state?.UnifiedSellerDashboardReducer?.categoryList
        }),
        shallowEqual
    );

    useEffect(() => {
        if (categoryList?.length > 0 && startDate?.length > 0) {
            getRevenueDetails(dispatch, categoryId, props?.selectedTenant, startDate, endDate);
        }
    }, [categoryList, categoryId, props?.selectedTenant, dateOption, startDate, endDate]);

    useEffect(() => {
        if (!revenueDetails) {
            return;
        }
        const total = revenueDetails.reduce((acc, curr) => acc + curr.value, 0);
        const columnChartOptions = getColumnChartOptions(revenueDetails, total);
        const formateTotal = formatTotalRevenue(total, regionCode);
        setTotal(formateTotal);
        columnChartOptions.series[0] = {
            ...columnChartOptions.series[0],
            data: revenueDetails,
        };

        setChartOptions(columnChartOptions);
    }, [revenueDetails, isSmallDevice]);

    useEffect(() => {
        const initialDateRange = calculateDateRange(dateOption);
        if (initialDateRange) {
            setStartDate(initialDateRange.start);
            setEndDate(initialDateRange.end);
        }
    }, []);

    const revenueDateHandler = (event) => {
        setDateOption(event.target.value);
        const dateRange = calculateDateRange(event.target.value);
        if (dateRange) {
            setStartDate(dateRange.start);
            setEndDate(dateRange.end);
        }
    };

    const revenueCategoryChangeHandler = (event) => {
        setCategoryId(event.target.value);
    };
    const revenueHeaderDetails = revenueHeaderOptions(
        categoryList,
        dateOptions,
        categoryId,
        revenueCategoryChangeHandler,
        revenueDateHandler,
        dateOption
    );

    return (
        <div
            className='usp-dashboard__chart-card'
        >
            <ChartCard
                header={{
                    label: {
                        info: i18next.t('DASHBOARD.CHART.REVENUE.INFO'),
                        displayValue: i18next.t('DASHBOARD.CHART.REVENUE.TOTAL')
                    },
                    fields: revenueHeaderDetails,
                    data: total
                }}
                chart={{
                    type: 'chart',
                    config: chartOptions,
                    data: revenueDetails,

                }}
                placeholder={revenuePlaceholderFields}
            />
        </div>
    );
});
export default RevenueWidget;