import { regionDetails } from '../data/appConstants';
import isEmpty from '../validator/isEmpty';

export const getRegionObject = () => {
  const regionFromURL = window.location.hostname.split('-')[0];
  const region = regionDetails.find(
    (region) => region.regionDisplayName === regionFromURL
  );
  const regionObject = !isEmpty(region) ? region : regionDetails[0];
  return regionObject;
};

export const getRegionCode = () => {
  return getRegionObject().regionCode;
};

export const getRegionName = () => {
  return getRegionObject().name;
};

export const getLegalEntityName = () => {
  return getRegionObject().legalEntityName;
};

export const getLegalDocumentUrl = () => {
  return getRegionObject().documents;
};
